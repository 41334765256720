import { Box, Heading, Text } from "@chakra-ui/react";

export default function UnderConstruccion() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        This area is under construction
      </Heading>
      <Text color={"gray.500"}>Very soon it will be available to all our users</Text>
    </Box>
  );
}

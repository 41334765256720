import { useContext, useEffect, useState } from 'react';
import { useAddress, useContractRead } from '@thirdweb-dev/react';
import { Box, Button, Collapse, Grid, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { LotteryContext } from '../contextLottery';
import { numberFinalToArr } from '../../../utils/lottery';
import { getAllTicketByAddressAndLotteryId } from '../../../firebase/services/ticketLottery';
import { Skeleton } from 'antd';

const completeArr = (startId: number, amount: number) => {
  const arr = new Array(amount)
  return arr.fill('').map((_, idx) => startId + idx)
}

const TicketsLotteryId = (props: { lotteryId: number }) => {
  const { isOpen: isOpenCollapse, onToggle: onToggleCollapse } = useDisclosure()

  const { lotteryId } = props
  const [arrId, setArrId] = useState([0])
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const { lotteryContract } = useContext(LotteryContext)
  const address = useAddress()
  const { data: viewNumbersAndStatusesForTicketIds } = useContractRead(
    lotteryContract,
    "viewNumbersAndStatusesForTicketIds",
    [arrId]
  );

  const getTickets = async () => {

    try {
      const data = await getAllTicketByAddressAndLotteryId(address || '0', lotteryId)
      let result: number[] = []
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        const arrComplete = completeArr(el.firstTikectsId, el.numberTickets,)
        result = [...result, ...arrComplete]
      }
      setArrId(result)
    } catch (err: any) {
      toast({
        title: 'Tx Error.',
        description: err?.shortMessage ? err.shortMessage : err?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    getTickets()
    setLoading(false)
  }, [address, lotteryId])


  return (
    <div>

      <Grid justifyContent="center" alignItems="center">
        <Button
          onClick={onToggleCollapse}
          className="btn-buy-lotto"
          bgColor={"#A539FB"}
        >My Tickets</Button>
        <Collapse in={isOpenCollapse} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
          <Box
            p='40px'
            color='white'
            mt='4'
            rounded='md'
            shadow='md'
            overflowY={'scroll'}
            maxH={'400px'}
          >
            {loading ? (<Skeleton />)
              : (
                <>
                  <hr />
                  {Array.isArray(viewNumbersAndStatusesForTicketIds) && (
                    viewNumbersAndStatusesForTicketIds[0]
                      .map((loteria: any, idx: number) => (<p key={idx}>
                        <Grid templateColumns={{ base: 'repeat(3, 1fr)', sm: 'repeat(6, 1fr)', }} gap={5} >
                          {numberFinalToArr(loteria)
                            .map((num) => (
                              <Grid bg={'#e9e7edf3'} rounded={'full'} my={2} border={'2px solid #000'} w='3rem' h='3rem' justifyContent={'center'} alignItems={'center'}>
                                <Text textColor={'#000'}>{num}</Text>
                              </Grid>
                            ))
                          }
                        </Grid>
                        <hr />
                      </p>))
                  )}
                </>
              )
            }
          </Box>
        </Collapse>
      </Grid>
    </div>
  );
};

export default TicketsLotteryId;
import React, { PropsWithRef, useCallback, useEffect, useState } from "react";

import { Table, Thead, Tbody, Tooltip, Button, Tr, Th, Td, ModalOverlay, ModalCloseButton, useToast, Flex, Text } from "@chakra-ui/react";
import { GiCheckMark, GiCrossMark, } from "react-icons/gi";
import LinkBinance from "../../../../components/nft/link-binance";

import AbiDAPNFT from "../../../../abi/DapNft.abi.json";
import { deleteRequest } from "../../../../firebase/services/requestNFT";
import { getUser } from "../../../../firebase/services/users";

import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";
import { getAddress } from "ethers/lib/utils";
import { useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";


interface TUsers {
  [clave: string]: string | number;
  id: string;
  tokenId: string;
  txHash: string;
  telegram: string;
  email: string;
  phone: string;
  amount: number;
  address: string;
}

interface TTableRequest {
  id?: string,
  onUpdate: () => void,
  getItems: () => Promise<any[]>
}


const contractNFT = getAddress('0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b');

export default function TableRequest(props: PropsWithRef<TTableRequest>) {
  const { onUpdate, getItems } = props
  const [user, setUser] = useState<any>({
    email: '',
    phone: '',
    telegram: '',
  })
  const [modalInfo, setModalInfo] = useState<any>({})
  const [items, setItemsRequest] = useState<any[]>([]);
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [columns, setColumns] =
    useState<{ name: string, uid: string }[]>(
      [
        { name: "Address", uid: "address" },
        { name: "AMOUNT", uid: "amount" },
      ]
    )

  const address = useAddress()

  const { contract: contactDap } = useContract(contractNFT, AbiDAPNFT)

  const { data: owner } = useContractRead(contactDap, 'owner')


  const { mutateAsync: approveRequestNFT, } = useContractWrite(contactDap, 'approveRequestNFT')

  const { mutateAsync: refuseRequestNFT, } = useContractWrite(contactDap, 'refuseRequestNFT')

  const getRequestItems = useCallback(() => {
    getItems().then((data) => {
      console.log(data)
      setItemsRequest(data);
    });
  }, [getItems]);

  const handleClickApprove = useCallback(
    async (item: any) => {
      try {
        const res = await approveRequestNFT({
          args: [item.address, item.tokenId]
        })
        toast({
          title: 'Success tx.',
          description: (<LinkBinance
            className='text-blue-900'
            textPrev='Transaction complete'
            transaction={res.receipt.transactionHash}
          />),
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        await deleteRequest(item.id)
        onUpdate()
      } catch (error: any) {
        toast({
          title: 'Error tx.',
          description: error.reason,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } finally {
      }
    }, [approveRequestNFT, onUpdate])

  const handleClickRefuse = useCallback(
    async (item: any) => {
      try {
        const res = await refuseRequestNFT({
          args: [item.address, item.tokenId]
        })

        toast({
          title: 'Success tx.',
          description: (<LinkBinance
            className='text-blue-900'
            textPrev='Transaction complete'
            transaction={res.receipt.transactionHash}
          />),
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

        await deleteRequest(item.id)
        onUpdate()
      } catch (error: any) {
        toast({
          title: 'Success tx.',
          description: error.reason,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } finally {
      }
    }, [refuseRequestNFT, onUpdate])

  const handleModal = useCallback(async (address: string, txHash: string, tokenId: string) => {
    let fooUser = await getUser(address)
    if (fooUser) {
      setUser(fooUser)
      setModalInfo({ txHash, tokenId })
      onOpen()
    }
  }, [onOpen])

  const renderCell = React.useCallback((item: TUsers, columnKey: keyof TUsers | React.Key) => {
    let cellValue: any = '';
    if (columnKey in item) {
      cellValue = item[columnKey];
    }

    console.log({ columnKey, item })
    switch (columnKey) {
      case "address":
        return (
          <LinkBinance option="address" transaction={cellValue} />
        );
      case "more":
        return (
          <Button onClick={() => handleModal(item.address, item.txHash, item.tokenId)
          }> More</Button >
        )
      case "actions":
        return (
          <Flex position={'relative'} justifyContent={'space-between'} justifyItems={'center'} gap={2}>

            <Tooltip color="primary" label="Refuse">
              <Text
                size={'3xl'}
                textColor={'red.400'}
                cursor={'pointer'}
                _hover={{ colorScheme: 'blue' }}
                onClick={() => handleClickRefuse(item)}
              >
                <GiCrossMark />
              </Text>
            </Tooltip >
            <Tooltip color="primary" label="Approve">
              <Text
                size={'3xl'}
                textColor='green.400'
                cursor={'pointer'}
                _hover={{ colorScheme: 'blue' }}
                onClick={() => handleClickApprove(item)}
              >
                <GiCheckMark />
              </Text>
            </Tooltip>
          </Flex >
        );
      default:
        return (<>
          {cellValue}
        </>)
    }
  }, [handleClickApprove, handleClickRefuse, handleModal]);

  useEffect(() => {
    if (address && typeof owner == 'string') {
      const columns = [
        { name: "Address", uid: "address" },
      ];
      if (getAddress(owner) == getAddress(address)) {
        setColumns([...columns,
        { name: "MORE", uid: "more" },
        { name: "ACTIONS", uid: "actions" },
        ])
      } else {
        setColumns([...columns,
        { name: "AMOUNT", uid: "amount" },
        ])
      }
    }
  }, [address, owner])

  useEffect(() => {
    getRequestItems();
  }, [getRequestItems]);

  return (
    <>
      <Table aria-label="Example table with custom cells">
        <Thead >
          <Tr>

            {columns.map((column: {
              name: string;
              uid: string;
            }) => (
              <Th key={column.uid} textAlign={column.uid === "actions" ? "center" : "start"}>
                {column.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody >
          {items.map((item: TUsers) => (
            <Tr key={item.id}>
              {columns.map((columnKey: any) => <Td>{renderCell(item, columnKey.uid)}</Td>)}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">User Information - id {modalInfo.tokenId}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <span><b>Email: </b>{user.email}</span>
            <span><b>Phone: </b>{user.phone}</span>
            <span><b>Telegram: </b>{user.telegram}</span>
            <span><b>txHash: </b>
              <LinkBinance option="tx" transaction={modalInfo.txHash} />
            </span>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
import { Box, Stack } from "@chakra-ui/react";
import { Bitcoin } from "./bitcoin";
import { Bnb } from "./bnb";
import { Busd } from "./busd";
import { Cake } from "./cake";
import { Cardano } from "./cardano";
import { Doge } from "./doge";
import { Ethereum } from "./ethereum";
import { Gq } from "./gq";
import { Tether } from "./tether";
import { Usdc } from "./usdc";
import { Vsion } from "./vision";

export type CoinList = "BTC" | "ETH" | "USDT" | "BNB" | "USDC" | "ADA" | "DOGE" | "VSION" | "BUSD" | "GQ" | "CAKE";

const coinIcons: Record<CoinList, JSX.Element> = {
  BTC: <Bitcoin />,
  ETH: <Ethereum />,
  USDT: <Tether />,
  BNB: <Bnb />,
  USDC: <Usdc />,
  ADA: <Cardano />,
  DOGE: <Doge />,
  VSION: <Vsion />,
  BUSD: <Busd />,
  GQ: <Gq />,
  CAKE: <Cake />
};

interface CurrencySwapIconsProps {
  from: CoinList;
  to: CoinList;
  // type: string;
  // tags: any;
}

export default function CurrencySwapIcons({ from, to }: CurrencySwapIconsProps) {
  return (
    <Stack alignItems="center" flexDirection="row" gap={0}>
      <Stack alignItems="center" flexDirection="row">
        <Box position="relative">{coinIcons[from]}</Box>
        <Box ml={-4}>{coinIcons[to]}</Box>
      </Stack>
      <Box whiteSpace="nowrap" ml={1} fontSize="sm" fontWeight="medium" color="black">
        {from}-{to}{" "}
      </Box>
    </Stack>
  );
}

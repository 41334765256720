import { useAddress, useContractEvents, useContractWrite } from '@thirdweb-dev/react';
import { useContext, useEffect, useMemo, useState } from 'react';

import { BigNumber } from 'ethers';
import { LotteryContext } from '../contextLottery';
import { TTicketLottery, addTicket } from '../../../firebase/services/ticketLottery';

function calculateTotalPriceForBulkTickets(
  _discountDivisor: BigNumber,
  _priceTicket: BigNumber,
  _numberTickets: number
) {
  return _priceTicket
    .mul(_numberTickets)
    .mul(_discountDivisor.add(1).sub(_numberTickets))
    .div(_discountDivisor);
}


export function useBuyTicket() {

  const {
    currentLotteryId,
    viewLottery,
    tokenStorageVSN,
    tokenStorageUSDT,
    tokenStorageWBNB,
    VsnContract,
    UsdtContract,
    wbnbContract,
    lotteryAddress,
    lotteryContract,
    errorToast,
    sucessToast } = useContext(LotteryContext)

  const address = useAddress();
  const [amountTickets, setAmountTickets] = useState(0)
  const [idToken, setIdToken] = useState(0)
  const [tickets, setTickets] = useState<string[]>([''])



  const { mutateAsync: approveVsn } = useContractWrite(
    VsnContract,
    "approve"
  );

  const { mutateAsync: approveUsdt } = useContractWrite(
    UsdtContract,
    "approve"
  );

  const { mutateAsync: approveWbnb } = useContractWrite(
    wbnbContract,
    "approve"
  );

  const { mutateAsync: buyTickets } = useContractWrite(
    lotteryContract,
    "buyTickets"
  );

  const { data: TicketsPurchaseEvent } = useContractEvents(
    lotteryContract,
    "TicketsPurchase",
    {
      queryFilter: {
        filters: {
          buyer: address,
        },
        order: "asc", // Order of events ("asc" or "desc")
      },
      subscribe: true, // Subscribe to new events
    },
  );

  const tokens = useMemo(() => {
    return [
      tokenStorageVSN,
      tokenStorageUSDT,
      tokenStorageWBNB
    ]
  }, [lotteryAddress,
    lotteryContract])

  const handleClickBuyTicketsRandom = async () => {

    try {
      if (approveVsn && amountTickets > 0) {
        const totalPrice: BigNumber = tokens[idToken].ticketPrice.mul(amountTickets)
        let res1
        if (idToken == 0) {
          res1 = await approveVsn({ args: [lotteryAddress, totalPrice] },)
        } else if (idToken == 1) {
          res1 = await approveUsdt({ args: [lotteryAddress, totalPrice] },)
        } else {
          res1 = await approveWbnb({ args: [lotteryAddress, totalPrice] },)
        }
        sucessToast(res1.receipt.transactionHash, 'Approve token.')

        let ticketsRandom = new Array(amountTickets)
        ticketsRandom.fill(0, 0, amountTickets);
        ticketsRandom = ticketsRandom.map(() => Math.trunc(Math.random() * 1_000_000) + 1_000_000)
        const res2 = await buyTickets({ args: [idToken, currentLotteryId, ticketsRandom] })

        sucessToast(res2.receipt.transactionHash, 'Ticked buyed.')
      }
    } catch (err: any) {
      errorToast(err?.reason || '')
    } finally {
      // setLoading(false)
    }
  }
  const handleClickBuyTicketsSelected = async () => {
    try {
      if (approveVsn && !tickets.some((ticket) => ticket == "")) {
        const totalPrice: BigNumber = tokens[idToken].ticketPrice.mul(tickets.length)
        let res1
        if (idToken == 0) {
          res1 = await approveVsn({ args: [lotteryAddress, totalPrice] },)
        } else if (idToken == 1) {
          res1 = await approveUsdt({ args: [lotteryAddress, totalPrice] },)
        } else {
          res1 = await approveWbnb({ args: [lotteryAddress, totalPrice] },)
        }

        sucessToast(res1.receipt.transactionHash, 'Approve token.')

        const res2 = await buyTickets({ args: [idToken, currentLotteryId, tickets] })

        sucessToast(res2.receipt.transactionHash, 'Ticked buyed.')
      } else {
        errorToast("complete tickets")
      }
    } catch (err: any) {
      errorToast(err?.reason || err?.message)
    } finally {
      // setLoading(false)
    }
  }

  const payedTokenSelect = useMemo(() =>
    calculateTotalPriceForBulkTickets(viewLottery.discountDivisor, tokens[idToken].ticketPrice, tickets.length),
    [tickets, tokens[idToken]?.ticketPrice]
  )

  const payedTokenRandom = useMemo(() =>
    calculateTotalPriceForBulkTickets(viewLottery.discountDivisor, tokens[idToken].ticketPrice, amountTickets),
    [amountTickets, tokens[idToken]?.ticketPrice]
  )

  const handleClickAddTicket = () => {
    const fooTickets = tickets
    fooTickets.push('')
    setTickets([...fooTickets])
  }
  const handlerSelectTicketByIdx = ((ticket: string, idx: number) => {
    const fooTickets = tickets
    fooTickets[idx] = ticket
    setTickets([...fooTickets])
  })

  const handleRemoveInput = (idx: number) => {
    let fooTickets = tickets
    fooTickets.splice(idx, 1)
    setTickets([...fooTickets])
  }
  useEffect(() => {
    if (TicketsPurchaseEvent) {
      const ticketLottery = TicketsPurchaseEvent[0].data as unknown as any
      const request: TTicketLottery = {
        buyer: ticketLottery.buyer,
        tokenId: idToken,
        lotteryId: ticketLottery.lotteryId.toNumber(),
        firstTikectsId: ticketLottery.firstTikectsId.toNumber(),
        numberTickets: ticketLottery.numberTickets.toNumber(),
      }
      addTicket(request)
    }
  }, [TicketsPurchaseEvent])

  return {
    setAmountTickets,
    setIdToken,
    idToken,
    handleClickBuyTicketsRandom,
    handleClickBuyTicketsSelected,
    tokenStorageVSN,
    tickets,
    handleClickAddTicket,
    handlerSelectTicketByIdx,
    handleRemoveInput,
    lotteryAddress,
    payedTokenRandom,
    payedTokenSelect,
    tokens
  }
}
import { Box, SimpleGrid } from "@chakra-ui/react";
import bgClassRoom from "../../assets/images/bg/crypto-hero-bg-desktop.png";
import HeaderPage from "../../components/HeaderPage";
import CourseCard from "../../components/Card/Course";
import imgHeader from "../../assets/images/icons-headers/07.png";

const Header = () =>
  HeaderPage(
    "Vsion",
    " Academy",
    "Our Courses",
    "A hub for learning and education on all things crypto, blockchain, DeFi, and more.",
    "",
    imgHeader,
    ""
  );

const CoursesPage = () => {
  return (
    <Box
      p={{ base: 3, md: 6 }}
      bgImg={bgClassRoom}
      backgroundSize="cover"
      backgroundPosition="top left"
      className="text-center no-repeat"
      display="flex"
      flexDirection="column"
      gap={10}
    >
      <Header />
      <SimpleGrid borderRadius="10px" p={{ base: 5, md: 10 }} columns={{ md: 2, xl: 1 }} spacing="40px">
        {[1].map((item) => (
          <CourseCard key={item} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CoursesPage;

import { SVGProps } from "react";
const VsionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 128 38"
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
    <g>
      <g>
        <path
          className="st0"
          d="M38.5,10c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2 c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.3,0.4L44,20l3-10.7c0.1-0.2,0.2-0.3,0.3-0.4 c0.2-0.1,0.3-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0,0,0.1,0,0.2 l-3.8,12.5c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.1-0.7,0.2-1,0.2c-0.4,0-0.7-0.1-1-0.2s-0.5-0.4-0.6-0.7L38.5,10z"
        />
        <path
          className="st0"
          d="M54.7,21.1c0.6,0,1.1-0.1,1.6-0.4c0.4-0.3,0.7-0.7,0.7-1.3c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.6-1.2-0.8 c-0.5-0.2-1-0.5-1.5-0.7s-1.1-0.5-1.5-0.8c-0.5-0.3-0.9-0.8-1.2-1.3s-0.5-1.1-0.5-1.9c0-0.7,0.1-1.4,0.4-1.9 c0.3-0.5,0.7-0.9,1.1-1.2c0.5-0.3,1-0.5,1.5-0.7c0.6-0.1,1.1-0.2,1.7-0.2c0.3,0,0.6,0,1.1,0.1c0.4,0,0.8,0.1,1.2,0.2 c0.4,0.1,0.7,0.3,1,0.5S59,10,59,10.3c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4 c-0.1,0.1-0.3,0.1-0.5,0.1s-0.3,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.7-0.3s-0.6-0.1-1-0.1c-0.7,0-1.3,0.1-1.7,0.4 c-0.4,0.3-0.6,0.6-0.6,1.1c0,0.4,0.2,0.7,0.5,1s0.7,0.5,1.2,0.7s1,0.4,1.5,0.7c0.5,0.2,1.1,0.5,1.5,0.9c0.5,0.4,0.9,0.8,1.2,1.4 c0.3,0.5,0.5,1.3,0.5,2.1c0,0.7-0.1,1.3-0.4,1.9c-0.2,0.5-0.6,1-1,1.3s-0.9,0.6-1.5,0.8c-0.6,0.2-1.2,0.3-1.9,0.3 c-0.6,0-1.3-0.1-1.8-0.2c-0.6-0.1-1-0.3-1.5-0.6c-0.4-0.2-0.7-0.5-1-0.8c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.1,0-0.3,0.1-0.4 c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.3,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.4,0.3,0.7,0.5 c0.2,0.2,0.5,0.4,0.9,0.5C53.7,21,54.2,21.1,54.7,21.1z"
        />
        <path
          className="st0"
          d="M61.2,9.5c0-0.3,0.1-0.5,0.4-0.6c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.4,0.3,0.4,0.6 v12.9c0,0.3-0.1,0.5-0.4,0.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.4-0.3-0.4-0.6V9.5z"
        />
        <path
          className="st0"
          d="M70.6,23.3c-0.7,0-1.3-0.1-1.8-0.2c-0.6-0.2-1-0.4-1.5-0.8c-0.4-0.4-0.7-0.8-1-1.4c-0.2-0.6-0.4-1.3-0.4-2.1 v-5.5c0-0.8,0.1-1.5,0.4-2.1c0.2-0.6,0.6-1,1-1.4s0.9-0.6,1.5-0.8s1.2-0.2,1.8-0.2c0.6,0,1.3,0.1,1.8,0.2c0.6,0.2,1.1,0.4,1.5,0.8 c0.4,0.4,0.8,0.8,1,1.4c0.2,0.6,0.4,1.3,0.4,2.1v5.5c0,0.8-0.1,1.5-0.4,2.1c-0.2,0.6-0.6,1-1,1.4s-0.9,0.6-1.5,0.8 C71.9,23.2,71.3,23.3,70.6,23.3z M72.7,13.3c0-0.8-0.2-1.4-0.6-1.7c-0.4-0.4-0.9-0.5-1.5-0.5c-0.7,0-1.2,0.2-1.5,0.5 c-0.4,0.4-0.5,0.9-0.5,1.7v5.5c0,0.8,0.2,1.4,0.5,1.7c0.4,0.4,0.9,0.5,1.5,0.5s1.2-0.2,1.5-0.5c0.4-0.4,0.6-0.9,0.6-1.7V13.3z"
        />
        <path
          className="st0"
          d="M85.8,23.2c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.6-0.6l-4.3-8v8c0,0.3-0.1,0.5-0.4,0.6 s-0.6,0.2-0.9,0.2s-0.6-0.1-0.9-0.2s-0.4-0.3-0.4-0.6V9.5c0-0.3,0.1-0.5,0.4-0.6s0.6-0.2,0.9-0.2c0.2,0,0.4,0,0.6,0 s0.3,0.1,0.5,0.2C80,9,80.2,9.1,80.3,9.3s0.3,0.4,0.4,0.7l3.8,7.4v-8c0-0.3,0.1-0.5,0.4-0.6s0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2 c0.3,0.1,0.4,0.3,0.4,0.6v12.9c0,0.3-0.1,0.5-0.4,0.6C86.4,23.1,86.1,23.2,85.8,23.2z"
        />
        <path
          className="st0"
          d="M100.4,16.7H96v5.7c0,0.3-0.1,0.5-0.4,0.6s-0.6,0.2-0.9,0.2s-0.6-0.1-0.9-0.2s-0.4-0.3-0.4-0.6V9.5 c0-0.3,0.1-0.5,0.4-0.6s0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2C95.8,9,96,9.2,96,9.5v5.2h4.5V9.5c0-0.3,0.1-0.5,0.4-0.6s0.6-0.2,0.9-0.2 s0.6,0.1,0.9,0.2c0.3,0.1,0.4,0.3,0.4,0.6v12.9c0,0.3-0.1,0.5-0.4,0.6s-0.6,0.2-0.9,0.2s-0.6-0.1-0.9-0.2s-0.4-0.3-0.4-0.6 L100.4,16.7L100.4,16.7z"
        />
        <path
          className="st0"
          d="M112.3,9.5c0-0.3,0.1-0.5,0.4-0.6s0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2c0.3,0.1,0.4,0.3,0.4,0.6v9.3 c0,0.8-0.1,1.5-0.4,2.1c-0.2,0.6-0.6,1-1,1.4s-0.9,0.6-1.5,0.8c-0.6,0.2-1.2,0.2-1.9,0.2c-0.7,0-1.3-0.1-1.9-0.2s-1.1-0.4-1.5-0.8 s-0.8-0.8-1-1.4s-0.4-1.3-0.4-2.1V9.5c0-0.3,0.1-0.5,0.4-0.6s0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2c0.3,0.1,0.4,0.3,0.4,0.6v9.3 c0,0.8,0.2,1.4,0.6,1.7c0.4,0.4,0.9,0.6,1.6,0.6s1.2-0.2,1.6-0.6s0.6-0.9,0.6-1.7V9.5z"
        />
        <path
          className="st0"
          d="M117.1,9.5c0-0.3,0.1-0.5,0.3-0.6c0.2-0.1,0.5-0.2,0.8-0.2h3.8c0.5,0,1,0,1.5,0.1s0.9,0.3,1.3,0.5 c0.4,0.3,0.7,0.6,0.9,1.1c0.2,0.5,0.3,1,0.3,1.7c0,0.8-0.1,1.5-0.4,2s-0.7,0.9-1.2,1.1c0.3,0.1,0.6,0.3,0.8,0.4 c0.3,0.2,0.5,0.4,0.6,0.6c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.4,0.1,0.8,0.1,1.3V19c0,0.8-0.1,1.4-0.3,2c-0.2,0.5-0.5,1-0.8,1.3 c-0.4,0.3-0.8,0.6-1.3,0.7s-1,0.2-1.6,0.2h-4.2c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.2-0.3-0.3-0.3-0.6L117.1,9.5L117.1,9.5z  M119.7,14.4h2.2c0.5,0,0.9-0.2,1.2-0.5s0.4-0.7,0.4-1.2s-0.1-0.9-0.4-1.2s-0.7-0.4-1.2-0.4h-2.2V14.4z M121.9,20.9 c0.6,0,1.1-0.2,1.4-0.5s0.5-0.9,0.5-1.6v-0.3c0-0.8-0.2-1.3-0.5-1.6c-0.3-0.3-0.8-0.5-1.4-0.5h-2.2V21h2.2V20.9z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M42.9,27.5c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7 v1.7c0,0.3,0,0.5-0.1,0.7s-0.2,0.3-0.3,0.4s-0.3,0.2-0.5,0.2C43.3,32,43.1,32,42.9,32h-1.1c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2 v-4.1c0-0.1,0-0.1,0.1-0.2s0.2-0.1,0.3-0.1h1.1V27.5z M42.2,28.2v3.2h0.7c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.5V29 c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2L42.2,28.2L42.2,28.2z"
        />
        <path
          className="st0"
          d="M49.1,32.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1l-0.1-0.1c0,0,0,0,0-0.1l1.2-4 c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2l1.2,4v0.1c0,0.1,0,0.1-0.1,0.1L51.7,32 c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0L51,32l0-0.9h-1.4L49.3,32c0,0.1,0,0.1-0.1,0.1H49.1z M50.8,30.5 l-0.5-1.9l-0.5,1.9H50.8z"
        />
        <path
          className="st0"
          d="M56.3,27.8c0-0.1,0-0.1,0.1-0.2s0.1-0.1,0.2-0.1h1.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.2 c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7l0,0c0,0.3,0,0.5-0.1,0.7S59.1,29.9,59,30s-0.3,0.2-0.5,0.2 c-0.2,0.1-0.4,0.1-0.6,0.1h-0.7v1.5c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2v-4H56.3z  M57.1,28.2v1.5h0.7c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.5l0,0c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2L57.1,28.2 L57.1,28.2z"
        />
        <path
          className="st0"
          d="M69.9,27.8c0-0.1,0-0.1,0.1-0.2s0.1-0.1,0.2-0.1h1.3c0.2,0,0.4,0,0.6,0.1s0.3,0.1,0.5,0.2 c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7l0,0c0,0.3,0,0.5-0.1,0.7s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2 s-0.4,0.1-0.6,0.1h-0.7v1.5c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1C70,32,70,31.9,70,31.8L69.9,27.8 L69.9,27.8z M70.7,28.2v1.5h0.7c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.1-0.2,0.1-0.5l0,0c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2 L70.7,28.2L70.7,28.2z"
        />
        <path
          className="st0"
          d="M78.4,27.8c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1h1.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.2 c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.6-0.2,0.8c-0.2,0.2-0.4,0.3-0.6,0.4l0.8,1.5c0,0,0,0,0,0.1l0,0 c0,0.1,0,0.1,0,0.2s-0.1,0.1-0.1,0.1S81.2,32,81.1,32s-0.1,0-0.2,0s-0.1,0-0.2-0.1l-0.1-0.1l-0.9-1.7h-0.5v1.6 c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2C78.4,31.7,78.4,27.8,78.4,27.8z  M79.2,28.2v1.3h0.7c0.2,0,0.4,0,0.5-0.1s0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2C79.9,28.2,79.2,28.2,79.2,28.2z"
        />
        <path
          className="st0"
          d="M87.2,32.2c-0.2,0-0.4,0-0.6-0.1s-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V29 c0-0.3,0-0.5,0.1-0.7s0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1s0.3,0.1,0.5,0.2 c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7v1.8c0,0.3,0,0.5-0.1,0.7s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2 S87.4,32.2,87.2,32.2z M87.9,29c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v1.8 c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.6V29z"
        />
        <path
          className="st0"
          d="M95.8,27.5c0.1,0,0.2,0,0.2,0.1s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.1h-0.9v3.6 c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2v-3.6h-0.9c-0.1,0-0.1,0-0.2-0.1 c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1C93.2,27.5,95.8,27.5,95.8,27.5z"
        />
        <path
          className="st0"
          d="M101.7,32.2c-0.2,0-0.4,0-0.6-0.1s-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V29 c0-0.3,0-0.5,0.1-0.7s0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2s0.4-0.1,0.6-0.1s0.4,0,0.6,0.1s0.3,0.1,0.5,0.2 c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7v1.8c0,0.3,0,0.5-0.1,0.7s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2 S101.9,32.2,101.7,32.2z M102.4,29c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2S101,28.7,101,29v1.8 c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.6V29z"
        />
        <path
          className="st0"
          d="M109.7,28.9c0-0.1,0-0.1,0-0.2s0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.4-0.1 c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.5v1.8c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2 c0-0.1,0.1-0.1,0.1-0.2s0-0.2,0-0.2c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3 c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0.1-0.4,0.1-0.6,0.1s-0.4,0-0.6-0.1 s-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V29c0-0.3,0-0.5,0.1-0.7s0.2-0.3,0.3-0.4 c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1s0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.4 c0.1,0.1,0.1,0.3,0.1,0.5s0,0.3-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1S109.7,29,109.7,28.9z"
        />
        <path
          className="st0"
          d="M117.6,32.2c-0.2,0-0.4,0-0.6-0.1s-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V29 c0-0.3,0-0.5,0.1-0.7s0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1s0.3,0.1,0.5,0.2 c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.7v1.8c0,0.3,0,0.5-0.1,0.7s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2 S117.8,32.2,117.6,32.2z M118.2,29c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v1.8 c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.6V29z"
        />
        <path
          className="st0"
          d="M123.9,32.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2v-4.1c0-0.1,0-0.2,0.1-0.2s0.2-0.1,0.3-0.1 c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.1,0.2v3.6h1.5c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2 c0,0.1-0.1,0.1-0.2,0.1h-2V32.1z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M33.1,10.6c0,0.4-0.7,0.7-1.5,0.7H18.8c-0.8,0-1.8,0-2.2,0c-0.4,0-1-0.3-0.6-1l3.7-6.4c0.4-0.7,1.3-1,2-0.5 l10.1,5.8C32.5,9.6,33.1,10.2,33.1,10.6z"
        />
        <path
          className="st0"
          d="M17.9,1.1c0.3,0.2,0.2,0.9-0.2,1.6l-6.4,11.1c-0.4,0.7-0.7,1.3-0.7,1.3s-0.1,0.3-0.3,0.6 c-0.2,0.3-0.8,0.7-1.2,0L5.4,9.4C5,8.7,5.2,7.8,5.9,7.4L16,1.5C16.7,1.1,17.6,1,17.9,1.1z"
        />
        <path
          className="st0"
          d="M2.1,9.6c0.3-0.2,0.9,0.3,1.3,1l6.4,11.1c0.4,0.7,0.7,1.3,0.7,1.3s0.2,0.3,0.4,0.6c0.2,0.3,0.2,1.1-0.6,1.1 H3.1c-0.8,0-1.5-0.7-1.5-1.5V11.4C1.6,10.6,1.8,9.8,2.1,9.6z"
        />
        <path
          className="st0"
          d="M1.6,27.4c0-0.4,0.7-0.7,1.5-0.7h12.8c0.8,0,1.5,0,1.5,0l0,0c0.7,0,0.7,0,0.7,0c0.8-0.2,1,0.3,0.6,1L15,34.1 c-0.4,0.7-1.3,1-2,0.5L2.9,28.8C2.2,28.4,1.6,27.8,1.6,27.4z"
        />
        <path
          className="st0"
          d="M16.8,36.9c-0.3-0.2-0.2-0.9,0.2-1.6l6.4-11.1c0.4-0.7,0.7-1.3,0.7-1.3s0.1-0.3,0.3-0.6 c0.2-0.3,0.8-0.7,1.2,0l3.7,6.4c0.4,0.7,0.2,1.6-0.5,2l-10.1,5.8C17.9,36.9,17.1,37,16.8,36.9z"
        />
        <path
          className="st0"
          d="M32.5,28.4c-0.3,0.2-0.9-0.3-1.3-1l-6.4-11.1c-0.4-0.7-0.7-1.3-0.7-1.3s-0.2-0.3-0.4-0.6 c-0.2-0.3-0.2-1.1,0.6-1.1h7.3c0.8,0,1.5,0.7,1.5,1.5v11.7C33.1,27.4,32.8,28.2,32.5,28.4z"
        />
        <g>
          <path
            className="st0"
            d="M17.3,13.8c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6l0,0l0,0c0.9,0,1.7-0.2,2.4-0.6c-1.2-0.2-2.2-1.2-2.2-2.5 c0-1.4,1.1-2.5,2.5-2.5c1.2,0,2.2,0.8,2.5,2c0.2-0.6,0.4-1.3,0.4-2C22.9,16.3,20.4,13.8,17.3,13.8z M15.2,19.1 c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S15.9,19.1,15.2,19.1z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default VsionIcon;

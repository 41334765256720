import {
  Box,
  Heading,
  Text,
  Img,
  Flex,
  Center,
  useColorModeValue,
  HStack,
  useDisclosure,
  Link as LinkChakra
} from "@chakra-ui/react";
import { BsArrowUpRight, BsHeartFill, BsHeart } from "react-icons/bs";
import QRDapModal from "../../../../components/Modal/QRDap";
import { Link } from "react-router-dom";

export default function PostWithLike({ nftData, earnButton }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleClose() {
    onClose();
  }

  return (
    <>
      <Center>
        <Box
          rounded={"sm"}
          my={5}
          mx={[0, 5]}
          overflow={"hidden"}
          bg="white"
          border={"1px"}
          borderColor="black"
          boxShadow={useColorModeValue("6px 6px 0 black", "6px 6px 0 #850c74")}
        >
          <Box h={"280px"} borderBottom={"1px"} borderColor="black">
            <Img src={nftData?.image} roundedTop={"sm"} objectFit="cover" h="full" w="full" alt={"Blog Image"} />
          </Box>

          <Box p={4}>
            <Box bg="#850c74" display={"inline-block"} px={2} py={1} color="white" mb={2}>
              <Text fontSize={"xs"} fontWeight="medium">
                {nftData?.have ? "You have this NFT" : "Available to purchase"}
              </Text>
            </Box>
            {/* && earnButton */}
            {nftData?.have}
          </Box>
          <HStack borderTop={"1px"} color="black">
            {" "}
            <Link color="teal.500" to={`/dashboard/dap/${nftData?.identifier}`}>
              <Flex
                p={4}
                alignItems="center"
                justifyContent={"space-between"}
                roundedBottom={"sm"}
                cursor={"pointer"}
                w="full"
              >
                <Text fontSize={"md"} fontWeight={"semibold"}>
                  View more
                </Text>
                <BsArrowUpRight />
              </Flex>{" "}
            </Link>{" "}
            {nftData?.have && (
              <Flex
                p={4}
                alignItems="center"
                justifyContent={"space-between"}
                roundedBottom={"sm"}
                borderLeft={"1px"}
                cursor="pointer"
                onClick={onOpen}
              >
                GET CARD
              </Flex>
            )}
          </HStack>
        </Box>
      </Center>
      {isOpen && <QRDapModal dapSelected={nftData} isOpen={isOpen} onClose={handleClose} />}
    </>
  );
}

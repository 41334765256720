import domToImage from 'dom-to-image';

export function printDocument(id) {
    const input = document.getElementById(id);
    domToImage.toJpeg(input).then((imgData) => {
        const link = document.createElement("a");
        link.download = `${id}.jpeg`;
        link.href = imgData;
        link.click();
    });
}
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  SmartContract,
  ValidContractInstance,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite
} from "@thirdweb-dev/react";
import DapRewardAbi from "../../../abi/DapReward.abi.json";
import DapNFTAbi from "../../../abi/DapNft.abi.json";
import utilStyles from "../marketPlace/utils.module.css";

import infoNft from "../../../assets/data/NFTs/info.json";
import GenericERC20Abi from "../../../abi/GenericERC20.json";

import { formatUnits, getAddress, parseEther, parseUnits } from "ethers/lib/utils";
import { BaseContract, BigNumber } from "ethers";
import { ChangeEvent, FormEvent, useMemo, useRef, useState } from "react";
import { tokenValueTxt } from "../../../utils/formatters";
import CountDown from "../../../components/CountDown";
interface TPropsButtonRewardDapAll {}

const tokens = [
  { id: "0", symbol: "USDT" },
  { id: "1", symbol: "VSN" },
  { id: "2", symbol: "VSION" }
];

const unitFeeDapAmount = parseEther("0.006"); //1.5
const maxFeeDapAmount = parseEther("0.02"); //5
// const feeHolderAmount = parseEther("0.028")//7

const dapRewardAddr = "0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC";
const dapNFTAddr = "0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b";
const ButtonRewardDapAll: React.FC<TPropsButtonRewardDapAll> = () => {
  const address = useAddress();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
  const toast = useToast();
  const cancelRef = useRef<any>();
  const finalRef = useRef(null);
  const [selectedTokenId, setSelectedTokenId] = useState("");

  const { contract: DapRewardContract } = useContract(dapRewardAddr, DapRewardAbi);
  const { contract: DapNFTContract } = useContract(dapNFTAddr, DapNFTAbi);
  const { data: userInfo = { lastRewardTimeDAP: BigNumber.from(0) } } = useContractRead(DapRewardContract, "userInfo", [
    address
  ]);
  const { data: amountReward_0 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 0]);
  const { data: amountReward_1 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 1]);
  const { data: amountReward_2 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 2]);
  const { data: amountReward_3 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 3]);
  const { data: amountReward_4 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 4]);
  const { data: amountReward_5 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 5]);
  const { data: amountReward_6 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 6]);
  const { data: amountReward_7 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 7]);
  const { data: amountReward_8 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 8]);
  const { data: amountReward_9 = BigNumber.from(0) } = useContractRead(DapNFTContract, "balanceOf", [address, 9]);

  const { data: rewardToken = { token: getAddress("0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC") } } = useContractRead(
    DapRewardContract,
    "rewardTokens",
    [selectedTokenId]
  );
  const { contract: TokenRewardContract } = useContract(rewardToken.token, GenericERC20Abi);
  const { data: decimalRewardToken = 7 } = useContractRead(TokenRewardContract, "decimals");
  const { data: symbolRewardToken = "" } = useContractRead(TokenRewardContract, "symbol");

  const { data: balanceRewardToken = BigNumber.from(0) } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "balanceOf",
    string[],
    BigNumber
  >(TokenRewardContract, "balanceOf", [dapRewardAddr]);

  const {
    data: pool = {
      lastUpdatePool: BigNumber.from(0),
      initialAmount: BigNumber.from(0),
      remainingAmount: BigNumber.from(0)
    }
  } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "poolDAPByRewardToken",
    string[],
    {
      lastUpdatePool: BigNumber;
      initialAmount: BigNumber;
      remainingAmount: BigNumber;
    }
  >(DapRewardContract, "poolDAPByRewardToken", [selectedTokenId]);

  const { mutateAsync: withdrawDAP } = useContractWrite(DapRewardContract, "withdrawDAP");

  const amountReward = useMemo(() => {
    return [
      amountReward_0,
      amountReward_1,
      amountReward_2,
      amountReward_3,
      amountReward_4,
      amountReward_5,
      amountReward_6,
      amountReward_7,
      amountReward_8,
      amountReward_9
    ];
  }, [
    amountReward_0,
    amountReward_1,
    amountReward_2,
    amountReward_3,
    amountReward_4,
    amountReward_5,
    amountReward_6,
    amountReward_7,
    amountReward_8,
    amountReward_9
  ]);
  const totalAmountNft = useMemo(() => {
    return amountReward.reduce((acc, amount) => acc.add(amount), BigNumber.from(0));
  }, [amountReward]);
  const totalPriceNft = useMemo(() => {
    return amountReward.reduce((acc, amount, idx) => {
      return acc.add(amount.mul(infoNft[idx].price));
    }, BigNumber.from(0));
  }, [amountReward]);
  const withdrawDAPStates = useMemo(() => {
    return amountReward
      .map((amount, idx) => {
        return { amount, id: idx };
      })
      .reduce(
        (acc: { amounts: any[]; ids: any[] }, { amount, id }) => {
          if (amount.gt(0)) {
            acc.amounts.push(amount);
            acc.ids.push(id);
          }
          return acc;
        },
        { amounts: [], ids: [] }
      );
  }, [amountReward]);

  const feeWithdraw = useMemo(() => {
    if (unitFeeDapAmount.mul(totalAmountNft).lte(maxFeeDapAmount)) {
      return unitFeeDapAmount.mul(totalAmountNft);
    }
    return maxFeeDapAmount;
  }, [totalAmountNft]);

  const handlerSubmitWithdrawDap = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const formData = new FormData(ev.target as HTMLFormElement);

    const tokenID = formData.get(`currency`) as string;
    if (tokenID === "") {
      toast({
        title: "Error tx.",
        description: "Select currency reward ",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      return;
    }
    onOpenAlert();
  };

  const poolCurrent = useMemo(() => {
    if (pool.lastUpdatePool.toNumber() + 60 * 60 * 24 * 22 < Date.now() / 1000) {
      return balanceRewardToken.mul(70).div(100);
    }
    return pool.initialAmount;
  }, [pool]);

  const reward = useMemo(() => {
    if (totalPriceNft.gt(0)) {
      return poolCurrent.mul(parseUnits(totalPriceNft.toString() || "0", 8)).div("80000000000000000");
    }
    return BigNumber.from(0);
  }, [totalPriceNft, poolCurrent]);

  const hasReward = useMemo(() => {
    return userInfo.lastRewardTimeDAP.toNumber() + 60 * 60 * 24 * 22 < Date.now() / 1000;
  }, [userInfo?.lastRewardTimeDAP]);

  const handlerCurency = (ev: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTokenId(ev.target.value);
  };

  return (
    <div>
      <Button
        borderRadius="30px"
        textColor="white"
        minW={"200px"}
        w="auto"
        h="40px"
        mb={2}
        className={utilStyles.btn}
        background="linear-gradient(25deg, #af0f7d, #58086c)"
        _hover={{
          background: "linear-gradient(329deg, #9900d1 12%, #af117d 100%)",
          textColor: "white",
          border: "none",
          fontSize: "20px"
        }}
        onClick={onOpen}
      >
        Claim
      </Button>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={"bold"}>Withdraw DAP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!hasReward ? (
              <Box fontSize={"2xl"} textAlign={"center"}>
                <Text fontWeight={"semibold"}>Next Withdraw</Text>
                <Text>
                  <CountDown time={userInfo.lastRewardTimeDAP.toNumber() + 60 * 60 * 24 * 22} />
                </Text>
              </Box>
            ) : (
              <form onSubmit={(ev) => handlerSubmitWithdrawDap(ev)}>
                <Text fontWeight={"semibold"} mb={4}>
                  Select the currency with which you will receive the reward.
                </Text>
                <Select name="currency" placeholder="Select option" defaultValue={""} onChange={handlerCurency}>
                  {tokens &&
                    tokens.map(({ symbol, id }, idx) => (
                      <option key={idx} value={id}>
                        {symbol}
                      </option>
                    ))}
                </Select>
                <br />
                <b>Unit fee: </b>
                {tokenValueTxt(parseInt(formatUnits(unitFeeDapAmount.toString() || 0, 15)), 3, "BNB")}
                <br />
                <b>Fee: </b>
                {tokenValueTxt(parseInt(formatUnits(feeWithdraw.toString() || 0, 15)), 3, "BNB")}
                <br />

                <b>
                  Reward: ≈
                  {tokenValueTxt(
                    parseInt(formatUnits(reward.toString(), decimalRewardToken - 4)),
                    4,
                    symbolRewardToken
                  )}
                </b>
                <br />
                <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                  <Button isDisabled={!hasReward} type="submit">
                    WithdrawDap
                  </Button>
                </Grid>
              </form>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isOpen={isOpenAlert}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>You reward</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            The next reward is in 22 days.
            <br />
            <b>Fee: </b>
            {tokenValueTxt(parseInt(formatUnits(feeWithdraw.toString() || 0, 18 - 3)), 3, "BNB")}
            <br />
            <b>
              Reward: ≈
              {tokenValueTxt(parseInt(formatUnits(reward.toString(), decimalRewardToken - 4)), 4, symbolRewardToken)}
            </b>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="red" ref={cancelRef} onClick={onCloseAlert}>
              Not Withdraw
            </Button>
            <Button
              colorScheme="green"
              ml={3}
              onClick={() =>
                withdrawDAP({
                  args: [selectedTokenId, withdrawDAPStates.amounts, withdrawDAPStates.ids],
                  overrides: {
                    value: feeWithdraw
                  }
                })
              }
            >
              Withdraw
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ButtonRewardDapAll;

import { Box, Hide, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import FilterStake from "../../components/FilterStake";
import HeaderPage from "../../components/HeaderPage";
import TableHeader from "../../components/Header/Table";
import StakeImg from "../../assets/images/icons-headers/05.png";

const Header = () =>
  HeaderPage(
    "Vsion",
    " Staking",
    "",
    "Choose flexible o blocked stake and enjoy earning from great APR.Blocked stake gives you a boost to participate in other DeFi Panels in the Criptovision Ecosystem.",
    "",
    StakeImg,
    ""
  );
const headers = ["Pool", "Type", "Earned", "APR", "Total Staked", "Ends in"];

import { Production as stakeList } from "../../assets/data/StaticStake/Production";
import PoolCardStake from "../../components/Card/PoolStake";
import { SmartContract, ValidContractInstance } from "@thirdweb-dev/sdk";
import { BaseContract, BigNumber } from "ethers";
import { useContract, useContractRead } from "@thirdweb-dev/react";
import { TStake } from "../../components/Card/PoolStake/type";
import { useState } from "react";
import Staking from "./Staking";

const itemsStake: TStake[] = stakeList;

const AbiSwap = [
  {
    inputs: [],
    name: "GetPriceVsion",
    outputs: [
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
const swapAddr = "0xaF267f944087891E57d2e5A88f915483f898F8b1";

export default function Xstake() {
  const [itemsFiltered, setItemsFiltered] = useState(itemsStake.filter((item) => item.status != "finished"));

  const { contract: swapContract } = useContract(swapAddr, AbiSwap);
  const { data: vsionTokenPrice } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "GetPriceVsion",
    unknown[],
    BigNumber
  >(swapContract, "GetPriceVsion");

  const handleFilter = (filter: string, value: string) => {
    const fooItems = itemsStake.filter((item: any) => {
      return item[filter]?.includes(value);
    });
    setItemsFiltered(fooItems);
  };

  return (
    <>
      <Box
        p="5"
        display="flex"
        flexDirection="column"
        gap={10}
        background="linear-gradient(97.53deg, #0f071d 5.6%, #0f071d 59.16%, #af117d 200.34%)"
      >
        <Header />
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab _selected={{ color: "white", bg: "#af117d" }}>VSION</Tab>
            <Tab _selected={{ color: "white", bg: "#af117d" }}>
              VSN
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box p={{ base: "1", md: "5" }} display="flex" flexDirection="column" gap={10}>
                <FilterStake onFilter={handleFilter} />
                <Box>
                  <Hide below="sm">
                    <Box mb={3}>
                      <TableHeader
                        templateColumns={{
                          base: `repeat(${headers.length - 2}, minmax(0,1fr))`,
                          lg: `repeat(${headers.length}, minmax(0,1fr))`
                        }}
                        headers={headers}
                      />
                    </Box>
                  </Hide>
                  <>
                    {itemsFiltered.map((item, idx) => (
                      <PoolCardStake key={`stakeList-${idx}`} item={item} tokenPrice={vsionTokenPrice} />
                    ))}
                  </>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Staking/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}

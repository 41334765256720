export function Bnb(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F3BA2F"
      />
      <path
        d="M9.28254 10.8826L12.0016 8.16468L14.7218 10.8848L16.303 9.3025L12.0016 5L7.7002 9.3014L9.28254 10.8826Z"
        fill="white"
      />
      <path
        d="M5 12.0008L6.58123 10.4185L8.16357 12.0008L6.58123 13.582L5 12.0008Z"
        fill="white"
      />
      <path
        d="M9.28254 13.1173L12.0016 15.8364L14.7218 13.1162L16.3041 14.6963L12.0027 18.9988L7.7002 14.6997L9.28254 13.1173Z"
        fill="white"
      />
      <path
        d="M15.8364 12.0008L17.4177 10.4185L19 11.9997L17.4177 13.5831L15.8364 12.0008Z"
        fill="white"
      />
      <path
        d="M13.6059 11.9996L12.0014 10.394L10.8149 11.5805L10.6778 11.7165L10.397 11.9974L12.0014 13.6007L13.6059 12.0007V11.9996Z"
        fill="white"
      />
    </svg>
  );
}

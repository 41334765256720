import { useState, useEffect } from "react";

import { useContract, useContractRead } from "@thirdweb-dev/react";
import GenericERC20Abi from "../abi/GenericERC20.json";

const visionTokenAddress = "0x4bbd4fa12b2b874a13e9555f5c5d0f6ad035acc3"; //VSION
const burnWallet = "0x000000000000000000000000000000000000dEaD"; //VSION

export const useBurnData = () => {
  const { contract } = useContract(visionTokenAddress);
  const [balanceBurn, setBalanceBurn] = useState("0");
  const [suPlyVsion, setsuPlyVsion] = useState("0");
  const [percentajeBurn, setpercentajeBurn] = useState("0%");
  const [percentajeCirc, setpercentajeCirc] = useState("0%");

  const { data, isLoading, error } = useContractRead(contract, "balanceOf", [burnWallet]);

  const { data: totalSupply } = useContractRead(contract, "totalSupply");

  useEffect(() => {
    if (!isLoading) {
      const bal = data?.toString();
      const totalSupplyBlance = totalSupply?.toString();
      setBalanceBurn(bal / 1e8);
      setsuPlyVsion(totalSupplyBlance / 1e8);
      const porcentaje = ((balanceBurn / suPlyVsion) * 100).toFixed(2);

      setpercentajeBurn(porcentaje);
      setpercentajeCirc(100 - porcentaje);
    }
  }, [data, totalSupply, percentajeBurn]);
  return { balanceBurn, suPlyVsion, percentajeBurn, percentajeCirc };
};

import { Box, Button, Flex, Input, Text, useToast } from "@chakra-ui/react";
import Bet from "../components/Bet";
import CardPrincipal from "../components/CardPrincipal";
import PredictionAbi from "../../../abi/Prediction.abi.json";
import {
  useContractRead,
  useContract,
  ValidContractInstance,
  SmartContract,
  Web3Button,
  useContractWrite,
  useAddress
} from "@thirdweb-dev/react";
import { BaseContract, BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import { BiArrowBack } from "react-icons/bi";
import CountDown from "../../../components/CountDown";
import { FcBullish, FcBearish } from "react-icons/fc";

interface IRound {
  epoch: BigNumber; // uint256 epoch;
  startTimestamp: BigNumber; // uint256 startTimestamp;
  lockTimestamp: BigNumber; // uint256 lockTimestamp;
  closeTimestamp: BigNumber; // uint256 closeTimestamp;
  lockPrice: BigNumber; // int256 lockPrice;
  closePrice: BigNumber; // int256 closePrice;
  lockOracleId: BigNumber; // uint256 lockOracleId;
  closeOracleId: BigNumber; // uint256 closeOracleId;
  totalAmount: BigNumber; // uint256 totalAmount;
  bullAmount: BigNumber; // uint256 bullAmount;
  bearAmount: BigNumber; // uint256 bearAmount;
  rewardBaseCalAmount: BigNumber; // uint256 rewardBaseCalAmount;
  rewardAmount: BigNumber; // uint256 rewardAmount;
  oracleCalled: boolean; // bool oracleCalled;
}

const initRound: IRound = {
  epoch: BigNumber.from(0),
  startTimestamp: BigNumber.from(0),
  lockTimestamp: BigNumber.from(0),
  closeTimestamp: BigNumber.from(0),
  lockPrice: BigNumber.from(0),
  closePrice: BigNumber.from(0),
  lockOracleId: BigNumber.from(0),
  closeOracleId: BigNumber.from(0),
  totalAmount: BigNumber.from(0),
  bullAmount: BigNumber.from(0),
  bearAmount: BigNumber.from(0),
  rewardBaseCalAmount: BigNumber.from(0),
  rewardAmount: BigNumber.from(0),
  oracleCalled: false
};


interface ILedger {
  position: BigNumber; // uint256 epoch;
  amount: BigNumber; // uint256 startTimestamp;
  claimed: boolean; // uint256 lockTimestamp;
}

const initLedger: ILedger = {
  position: BigNumber.from(0),
  amount: BigNumber.from(0),
  claimed: false
};

interface TPropsCardPrediction {
  epoch: number | string;
}

// import InfoResult from './InfoResult';
const predictionAddr = "0xA0a19172c266E51Ddd0fF5db1ce2d0Bb704b9dbc";
const CardPrediccion: React.FC<TPropsCardPrediction> = (props) => {
  const { epoch } = props;
  const toast = useToast();
  const address = useAddress();

  const { contract: predictionContract } = useContract(predictionAddr, PredictionAbi);
  const [amount, setAmount] = useState("0");
  const [isUpper, setIsUpper] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data: round = initRound } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "rounds",
    (number | string)[],
    IRound
  >(predictionContract, "rounds", [epoch]);

  const { data: ledger = initLedger } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "ledger",
    (number | string)[],
    ILedger
  >(predictionContract, "ledger", [epoch, address || '0x0']);

  const { mutateAsync: betBear } = useContractWrite(predictionContract, "betBear");
  const { mutateAsync: betBull } = useContractWrite(predictionContract, "betBull");
  const { mutateAsync: claim } = useContractWrite(predictionContract, "claim");

  const isFinished = useMemo(() => {
    return round.lockTimestamp.lt(Math.trunc(Date.now() / 1000));
  }, [round?.lockTimestamp]);

  const handleClickInput = (_isUpper: boolean, _isOpen: boolean) => {
    setIsUpper(_isUpper);
    setIsOpen(_isOpen);
  };

  const multipicatorBull = useMemo(() => {
    if (round.totalAmount.gt(0) && round.bullAmount.gt(0)) {
      return round.totalAmount.mul(100).div(round.bullAmount).toNumber() / 100
    }
    return 0

  }, [round])

  const multipicatorBear = useMemo(() => {
    if (round.totalAmount.gt(0) && round.bearAmount.gt(0)) {
      return round.totalAmount.mul(100).div(round.bearAmount).toNumber() / 100
    }
    return 0
  }, [round])

  console.log({
    epoch: round.epoch.toString(),
    startTimestamp: round.startTimestamp.toString(),
    lockTimestamp: round.lockTimestamp.toString(),
    closeTimestamp: round.closeTimestamp.toString(),
    lockPrice: round.lockPrice.toString(),
    closePrice: round.closePrice.toString(),
    lockOracleId: round.lockOracleId.toString(),
    closeOracleId: round.closeOracleId.toString(),
    totalAmount: round.totalAmount.toString(),
    bullAmount: round.bullAmount.toString(),
    bearAmount: round.bearAmount.toString(),
    rewardBaseCalAmount: round.rewardBaseCalAmount.toString(),
    rewardAmount: round.rewardAmount.toString(),
    oracleCalled: round.oracleCalled
  });
  console.log({
    position: ledger.position.toString(),
    amount: ledger.amount.toString(),
    claimed: ledger.claimed
  });

  const sucessToast = (message: string) => {
    toast({
      title: "Success tx.",
      description: message,
      status: "success",
      duration: 9000,
      isClosable: true
    });
  };

  const errorToast = (message: string) => {
    toast({
      title: "Error tx.",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true
    });
  };

  const handlerBetBull = async () => {
    try {
      const res = await betBull({
        args: [round.epoch.toString()],
        overrides: {
          value: parseEther(amount)
        }
      });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };
  const handlerBetBear = async () => {
    try {
      const res = await betBear({
        args: [round.epoch.toString()],
        overrides: {
          value: parseEther(amount)
        }
      });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleClaim = async () => {
    try {
      const res = await claim({
        args: [[epoch]]
      });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };
  return (
    <Box border={"transparent"} bgColor={"transparent"} rounded={"2xl"} p={"4"} w="xs">
      <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
        <CountDown time={round.lockTimestamp.toNumber()} />
        {isOpen ? (
          <Box bgColor={"#000"} p={6} rounded={'md'}>
            <Box onClick={() => setIsOpen(false)}>
              <BiArrowBack />
            </Box>
            <br />

            <Text>Input</Text>
            <Input type="number" min={0} value={amount} onChange={(ev) => setAmount(ev.target.value)} />
            <br />
            {isUpper ? (
              <Web3Button
                contractAbi={PredictionAbi}
                contractAddress={predictionAddr} action={() => handlerBetBull()}>
                Bet Bull <FcBullish />{" "}
              </Web3Button>
            ) : (
              <Web3Button
                contractAbi={PredictionAbi}
                contractAddress={predictionAddr}
                action={() => handlerBetBear()}
                style={{ border: "2x solid red" }}
              >
                Bet Bear <FcBearish />
              </Web3Button>
            )}
          </Box>
        ) : (
          <>
            <Bet
              isUpper
              mutiplicator={multipicatorBull}
              isActive={round.closePrice.gt(0) && round.closePrice.gt(round.lockPrice)}
            />
            <CardPrincipal>
              {!isFinished ? (
                <Flex direction={"column"} gap={4}>
                  <Box>Pool Prize:{formatEther(round.totalAmount.toString()).toString()}BNB</Box>
                  {ledger.amount.eq(0) ? (<>
                    <Button onClick={() => handleClickInput(true, true)} color={"#006a01"}>
                      Bull
                    </Button>
                    <Button onClick={() => handleClickInput(false, true)} color={"#d41c00"}>
                      Bear
                    </Button>
                  </>
                  ) : (
                    <Box>Bet:{formatEther(ledger.amount.toString()).toString()}BNB</Box>
                  )
                  }
                </Flex>
              ) : (
                <>
                  <Text>Id: {round.epoch.toString()} </Text>
                  <Text>Last price</Text>
                  <Box>
                    <Text fontSize={"2xl"}>${formatUnits(round.closePrice.toString(), 8).toString()}</Text>
                  </Box>
                  <Box>Lock Price:${formatUnits(round.lockPrice.toString(), 8).toString()}</Box>
                  <Box>Pool Prize:{formatEther(round.totalAmount.toString()).toString()}BNB</Box>
                  {
                    round.closePrice.eq(0)
                      ? (<>
                        <Text>Active</Text>
                      </>
                      )
                      : ledger.amount.gt(0) && !ledger.claimed && (
                        <Web3Button contractAbi={PredictionAbi} contractAddress={predictionAddr} action={() => handleClaim()}>
                          Claim
                        </Web3Button>)

                  }

                </>
              )}
            </CardPrincipal>
            <Bet
              mutiplicator={multipicatorBear}
              isActive={round.closePrice.gt(0) && round.closePrice.lt(round.lockPrice)}
            />
          </>
        )}
      </Flex>
    </Box>
  );
};

export default CardPrediccion;

import { SmartContract, ValidContractInstance, useContract, useContractRead } from '@thirdweb-dev/react';
import { getAddress } from "ethers/lib/utils";
import { BaseContract, BigNumber } from "ethers";
import { TLottery, TLotteryContract, TLotteryCurrenly, TTokenStorage } from "./type";

// ABI
import AbiLottery from '../../abi/Lottery.abi.json';
import AbiERC20 from '../../abi/GenericERC20.json';
import { useMemo } from "react";
import { useToast } from '@chakra-ui/react';


const lotteryAddress = getAddress('0xEF5775e9fD01594bA4cC3DD1E0CA4CD7db361b98');
// const lotteryAddress = getAddress('0x5bD962D44432D11CeC7693Aa6304afa0e21166Ae');
const lotteryOldAddress = getAddress('0x5bD962D44432D11CeC7693Aa6304afa0e21166Ae');
const vsnAddress = getAddress('0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3');
const usdtAddress = getAddress('0x55d398326f99059fF775485246999027B3197955');
const wbnbAddress = getAddress('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c');

export const initLottery: TLottery = {
  status: 0,
  startTime: BigNumber.from(0),
  endTime: BigNumber.from(0),
  discountDivisor: BigNumber.from(0),
  rewardsBreakdown: [BigNumber.from(0)], // 0: 1 matching number // 5: 6 matching numbers
  treasuryFee: BigNumber.from(0), // 500: 5% // 200: 2% // 50: 0.5%
  countWinnersPerBracket: [BigNumber.from(0)],
  firstTicketId: BigNumber.from(0),
  firstTicketIdNextLottery: BigNumber.from(0),
  finalNumber: 0
}

export const initToken: TTokenStorage = {
  symbol: '',
  allow: false,
  ticketPrice: BigNumber.from(0),
  tokenAddress: '',
  decimal: 18
}

export function useLotteryContract(): TLotteryContract {

  const { contract: lotteryContract } = useContract(lotteryAddress, AbiLottery);
  const { contract: lotteryOldContract } = useContract(lotteryOldAddress, AbiLottery);
  const { contract: VsnContract } = useContract(vsnAddress, AbiERC20);
  const { contract: UsdtContract } = useContract(usdtAddress, AbiERC20);
  const { contract: wbnbContract } = useContract(wbnbAddress, AbiERC20);

  return {
    lotteryAddress,
    lotteryOldAddress,
    lotteryContract,
    lotteryOldContract,
    vsnAddress,
    VsnContract,
    usdtAddress,
    UsdtContract,
    wbnbAddress,
    wbnbContract,
  }

}

export function useLoteryCurrenly(): TLotteryCurrenly {

  const toast = useToast()

  const { lotteryContract } = useLotteryContract()
  const { data: currentLotteryIdFoo } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "viewCurrentLotteryId", any[], BigNumber>(
    lotteryContract, 'viewCurrentLotteryId')
  const { data: operatorAddress } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "operatorAddress", any[], string>(
    lotteryContract, 'operatorAddress')

  const { data: viewLotteryFoo, } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "viewLottery", any[], TLottery>(
    lotteryContract, 'viewLottery', [currentLotteryIdFoo]
  )

  const { data: tokenStorageVSNFoo } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "tokenStorage", number[], TTokenStorage>(
    lotteryContract, 'tokenStorage', [0]
  )

  const { data: tokenStorageUSDTFoo } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "tokenStorage", number[], TTokenStorage>(
    lotteryContract, 'tokenStorage', [1]
  )

  const { data: tokenStorageWBNBFoo } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "tokenStorage", any[], TTokenStorage>(
    lotteryContract, 'tokenStorage', [2]
  )

  const tokenStorageVSN = useMemo(() => {
    return tokenStorageVSNFoo ? { ...tokenStorageVSNFoo, symbol: "VSION", decimal: 8 } : initToken
  }, [tokenStorageVSNFoo])

  const tokenStorageUSDT = useMemo(() => {
    return tokenStorageUSDTFoo ? { ...tokenStorageUSDTFoo, symbol: "USDT", decimal: 18 } : initToken
  }, [tokenStorageUSDTFoo])

  const tokenStorageWBNB = useMemo(() => {
    return tokenStorageWBNBFoo ? { ...tokenStorageWBNBFoo, symbol: "BNB", decimal: 18 } : initToken
  }, [tokenStorageWBNBFoo])

  const viewLottery = useMemo(() => {
    return viewLotteryFoo ? viewLotteryFoo : initLottery
  }, [viewLotteryFoo])

  const currentLotteryId = useMemo(() => {
    return currentLotteryIdFoo ? currentLotteryIdFoo : BigNumber.from(0)
  }, [currentLotteryIdFoo])

  const lotteryWinnerId = useMemo(() => viewLottery.finalNumber === 0 ? currentLotteryId.sub(1) : currentLotteryId, [viewLottery?.finalNumber, currentLotteryId])

  const { data: viewLotteryWinnerFoo } = useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "viewLottery", any[], TLottery>(
    lotteryContract, 'viewLottery', [lotteryWinnerId]
  )

  const viewLotteryWinner = useMemo(() => {
    return viewLotteryWinnerFoo ? viewLotteryWinnerFoo : initLottery
  }, [viewLotteryWinnerFoo])


  const sucessToast = (message: string, title?: string) => {
    toast({
      title: title || 'Success tx.',
      description: message,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const errorToast = (message: string, title?: string) => {
    toast({
      title: title || 'Error tx.',
      description: message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }
  return {
    operatorAddress,
    currentLotteryId,
    viewLottery,
    tokenStorageVSN,
    tokenStorageUSDT,
    tokenStorageWBNB,
    viewLotteryWinner,
    lotteryWinnerId,
    sucessToast,
    errorToast
  }
}

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  IconButton,
  Text,
  Image
} from "@chakra-ui/react";
import { useAddress } from "@thirdweb-dev/react";
import QRCode from "react-qr-code";
import React, { useMemo, useState } from "react";
import { ArrowBackIcon, DownloadIcon } from "@chakra-ui/icons";
import { printDocument } from "../../utils/helper";
import gradient from "../../assets/card/gradient.svg";
import textura from "../../assets/card/textura.svg";
import logo from "../../assets/card/logo.svg";
import icon1 from "../../assets/card/icon1.svg";
import icon2 from "../../assets/card/icon2.svg";
import icon3 from "../../assets/card/icon3.svg";
import icon4 from "../../assets/card/icon4.svg";
import icon6 from "../../assets/card/icon6.svg";

const detailsCard = {
  0: {
    title: "NFT DAP Bronze ",
    color: " #cd7f32"
  },
  1: {
    title: "NFT DAP Silver ",
    color: " #ffffff"
  },
  2: { title: "NFT DAP Gold ", color: " #f4cf12" },
  3: { title: "NFT DAP Amethys ", color: "#770fd3" },
  4: { title: "NFT DAP Sapphire ", color: "#1024ff" },
  5: { title: "NFT DAP Ruby", color: "#972509" },
  6: { title: "NFT DAP Emerald", color: "#1fc11f" },
  7: { title: "NFT DAP Diamond ", color: " #f1f8ff" },
  8: { title: "NFT DAP Black Diamond ", color: "#000000" },
  9: { title: "NFT DAP Red Diamond ", color: "red" }
};

const widthCard = 408;
const heightCard = 232;
const heightFeaturesImages = 45;

const FeaturesImages = () => {
  return (
    <Stack spacing={2} direction="row">
      <Image src={icon1} objectFit="cover" w="100%" h={heightFeaturesImages} />
      <Image src={icon2} objectFit="cover" w="100%" h={heightFeaturesImages} />
      <Image src={icon3} objectFit="cover" w="100%" h={heightFeaturesImages} />
      <Image src={icon4} objectFit="cover" w="100%" h={heightFeaturesImages} />
      <Image src={icon6} objectFit="cover" w="100%" h={heightFeaturesImages} />
    </Stack>
  );
};

const Card = ({ children, bg }) => {
  return (
    <Stack
      rounded="lg"
      bg={bg}
      mb={3}
      overflow="hidden"
      transition="all"
      _last={{ mb: 0 }}
      shadow="lg"
      h={heightCard}
      w={widthCard}
      position="relative"
    >
      <Stack
        h="100%"
        spacing={1}
        alignItems="center"
        justifyContent="center"
        sx={{
          background: `url(${textura}) center no-repeat , url(${gradient}) center no-repeat`,
          backgroundSize: "cover"
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

const ListCard = ({ name, data }) => {
  const address = useAddress();

  const detailCard = useMemo(() => {
    return detailsCard[data.identifier] || {};
  }, [data]);

  return (
    <Stack spacing={2} p={2} id={`card-${name}`} bgColor="#000b25">
      <Card bg={detailCard.color}>
        <Box>
          <Image
            src={logo}
            objectFit="cover"
            w="100%"
            h={89}
            filter={[1, 7].includes(data.identifier) ? "brightness(0.5)" : "none"}
          />
        </Box>
        <Box position="absolute" bottom="10px">
          <FeaturesImages />
        </Box>
      </Card>
      <Card bg={detailCard.color}>
        <Box sx={{ height: "auto", margin: "0 auto", maxWidth: 125, width: "100%", bg: "white", p: 2 }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`ethereum:${address}`}
            viewBox={`0 0 256 256`}
          />
        </Box>

        <Box py={3} px={7} position="absolute" bottom={0} left={0}>
          <Text
            color={[1, 7].includes(data.identifier) ? "#8b8b8b" : "white"}
            fontVariant="small-caps"
            textShadow="2xl"
            fontSize="xl"
            textTransform="uppercase"
          >
            {name}
          </Text>
        </Box>
        <Box position="absolute" top={0} left={0} width="100%" textAlign="center">
          <Text
            color={[1, 7].includes(data.identifier) ? "#8b8b8b" : "white"}
            textShadow="2xl"
            fontSize="2xl"
            textTransform="uppercase"
            fontVariant="small-caps"
          >
            {detailCard.title}
          </Text>
        </Box>
      </Card>
    </Stack>
  );
};

const QRDapModal = ({ isOpen, onClose, dapSelected }) => {
  const [inputValue, setInputValue] = useState();
  const [showCard, setShowCard] = useState(false);

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  function handleBack() {
    setInputValue(null);
    setShowCard(false);
  }

  const handleDownload = () => {
    printDocument(`card-${inputValue}`);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent bgColor="#000b25">
          <ModalHeader>GET CARD</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            <Stack spacing={3}>
              {showCard ? (
                <Stack spacing={3}>
                  <IconButton
                    onClick={handleBack}
                    isRound={true}
                    variant="solid"
                    colorScheme="teal"
                    aria-label="back"
                    fontSize="20px"
                    icon={<ArrowBackIcon />}
                  />
                  <ListCard name={inputValue} data={dapSelected} />
                </Stack>
              ) : (
                <Stack spacing={5}>
                  <FormControl variant="floating" id="name" isRequired isInvalid>
                    <FormLabel htmlFor="inputValue">Entre name</FormLabel>
                    <Input
                      id="inputValue"
                      placeholder="Name..."
                      size="md"
                      name="inputValue"
                      value={inputValue}
                      onChange={handleChange}
                    />
                    <FormHelperText>Enter your name!</FormHelperText>
                  </FormControl>
                  <Button className="btn-mtks" onClick={() => setShowCard((prev) => !prev)} isDisabled={!inputValue}>
                    Generate
                  </Button>
                </Stack>
              )}
            </Stack>
          </ModalBody>
          {showCard && (
            <ModalFooter>
              <Button className="btn-mtks" mr={3} onClick={handleDownload} leftIcon={<DownloadIcon />}>
                Download
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default QRDapModal;

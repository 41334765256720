import { Hide, Stack } from "@chakra-ui/react";
import SortList from "./SortList";
import StackedSwitch from "./StackedSwitch";
import Status from "./Status";
import Search from "./Search";

const FilterStake = ({ onFilter }: { onFilter: (filter: string, value: string) => void }) => {
  const onChangeStatus = (value: string) => onFilter("status", value)
  return (
    <Stack
      flexDirection={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ md: "center" }}
      gap={{ base: 4, md: 6 }}
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" gap={4}>
        <Status onChange={onChangeStatus} />
        <Hide above="md">
          <StackedSwitch />
        </Hide>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={{ base: 8, lg: 4 }}>
        <Hide below="md">
          <StackedSwitch />
        </Hide>
        <Search />
        <SortList />
      </Stack>
    </Stack>
  );
};

export default FilterStake;

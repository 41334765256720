import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Web3Button } from "@thirdweb-dev/react";
import { BigNumber } from "ethers";
import { PropsWithRef, useRef, useState } from "react";
import CountDown from "../CountDown";
import { formatUnits } from "ethers/lib/utils";
import { tokenValueTxt } from "../../utils/formatters";
//import { Tooltip } from "antd";

export enum EnableStatus {
  NoStake,
  Aproved,
  Finished
}

interface TPropsEnableBox {
  allowance?: BigNumber;
  fromToken: string;
  toToken: string;
  isUnstake: boolean;
  timeLeft?: BigNumber;
  pendingReward?: BigNumber;
  Staked?: BigNumber;
  Balance?: BigNumber;
  RemainingBlock: number;
  statusBox?: EnableStatus;
  contractAddress: string;
  AbiContract: any;
  maxDeposit?: number;
  onAppove: () => void;
  onWithDraw?: () => void;
  onClaimRewards: () => void;
  onStakeToken: (amount: number) => void;
  onUnstakeToken: (amount: number) => void;
}

const EnableBox: React.FC<PropsWithRef<TPropsEnableBox>> = (props) => {
  const {
    // allowance = BigNumber.from(0),
    fromToken,
    toToken,
    //  isUnstake = false,
    timeLeft = BigNumber.from(0),
    pendingReward = BigNumber.from(0),
    Staked = BigNumber.from(0),
    Balance,
    RemainingBlock = 0,
    statusBox = EnableStatus.NoStake,
    contractAddress,
    AbiContract,
    // maxDeposit = 0,
    onAppove,
    onWithDraw,
    onClaimRewards,
    onStakeToken,
    onUnstakeToken
  } = props;

  const [stakeAmount, setStakeAmount] = useState(0);
  const [isStakeModal, setIsStakeModal] = useState(true);

  // const Aprove = useMemo(() => allowance && allowance.gt(0), [allowance])
  const finalRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const EnableButton = (
    <>
      {RemainingBlock > 0 && (
        <Box minHeight="124.5px" p="16px" border="2px solid #eeeaf4" borderRadius={16}>
          <Text color="black" fontSize="12px" mb={2}>
            Enable Stake
          </Text>
          <Web3Button
            contractAddress={contractAddress}
            contractAbi={AbiContract}
            action={() => onAppove()}
            className="btn-mtks color-white"
          >
            Enable Stake
          </Web3Button>
        </Box>
      )}
    </>
  );

  const BuyButton = (
    <>
   
      {RemainingBlock > 0 && (
        <Box>
          <Button
            rounded={"3xl"}
            minWidth={"30px"}
            onClick={() => {
              setIsStakeModal(true);
              onOpen();
            }}
          >
            Add {fromToken} / Remove {fromToken}
          </Button>
        </Box>
      )}
    </>
  );

  const FinishedButton = (
    <>
      <Box minHeight="124.5px" p="16px" border="2px solid transparent" borderRadius={16}>
        <Web3Button
          contractAddress={contractAddress}
          contractAbi={AbiContract}
          action={() => onWithDraw && onWithDraw()}
          className="btn-mtks"
          isDisabled={Staked.eq(0)}
          style={{ color: "white" }}
        >
          Withdraw
        </Web3Button>
      </Box>
    </>
  );

  return (
    <>
      <Box key={1} minHeight="124.5px" p="16px" border="2px solid #eeeaf4" borderRadius={16}>
        <Stack direction="row">
          <Text color="black" fontSize="12px" mb={2}>
            VSION
          </Text>
          <Text color="black" fontSize="12px">
            Earned
          </Text>
        </Stack>
        <Flex gap={12} alignItems={"center"}>
          <Heading color="black" fontSize="20px" as="h2">
            {tokenValueTxt(parseInt(formatUnits(pendingReward.toString(), 5)), 3, toToken)}
          </Heading>
          {Staked.gt(0) && (
            <Web3Button contractAddress={contractAddress} contractAbi={AbiContract} action={() => onClaimRewards()}>
              HARVEST
            </Web3Button>
          )}
        </Flex>
      </Box>
      {statusBox == EnableStatus.NoStake ? (
        EnableButton
      ) : (
        <>
          <Flex
            justifyContent={"space-between"}
            p="16px"
            minHeight="124.5px"
            border="2px solid #eeeaf4"
            borderRadius={16}
          >
            <Flex flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Text color="black" fontSize="12px" mb={2}>
                {fromToken} STAKED
              </Text>
              {timeLeft.gt(0) && (
                <>
                  <Text color="black" fontSize="12px" mb={2}>
                    Time Left
                  </Text>
                  <Text color="black" fontSize="12px" mb={2}>
                    <CountDown time={timeLeft.toNumber()} />
                  </Text>
                </>
              )}
              <Heading color="black" fontSize="20px" as="h2">
                {tokenValueTxt(parseInt(formatUnits(Staked.toString(), 5)), 3, fromToken)}
              </Heading>
            </Flex>
            {statusBox == EnableStatus.Aproved && BuyButton}
            {statusBox == EnableStatus.Finished && FinishedButton}
          </Flex>
        </>
      )}

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="none" backdropFilter="blur(20px) hue-rotate(90deg)" />
        <ModalContent>
          <ModalHeader>
            {isStakeModal ? "Stake" : "UnStake"} {fromToken} TOKEN
          </ModalHeader>
          <ModalBody>
            <div className="sc-4a3f83f4-0 kaqJq">
              <div className="sc-de7e8801-1 sc-1080dffc-0 WBFBh bQofKN">
                <Text fontSize="14px" color="text" className="sc-118b6623-0 bJcTpr text-black dark:text-white">
                  Stake
                </Text>
                <br />
                <Text fontSize="14px" color="text" className="sc-118b6623-0 bJcTpr text-black dark:text-white">
                  Balance: {formatUnits(Balance?.toNumber() || 0, 8)}
                </Text>
              </div>
              <Flex>
                <Input
                  type="number"
                  max={Balance?.toNumber()}
                  value={stakeAmount}
                  onChange={(e) => setStakeAmount(parseInt(e.target.value, 10))}
                  inputMode="decimal"
                  step="1"
                  min="0"
                  placeholder="0"
                  scale="md"
                />

                <Button onClick={() => setStakeAmount(parseInt(formatUnits(Balance?.toNumber() || 0, 8)))}>Max</Button>
              </Flex>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
            <Web3Button
              contractAddress={contractAddress}
              contractAbi={AbiContract}
              className="btn-green"
              action={() => {
                onStakeToken(stakeAmount);
              }}
            >
              {"Stake"}
            </Web3Button>
            -
            <Web3Button
              contractAddress={contractAddress}
              contractAbi={AbiContract}
              className="btn-green"
              action={() => {
                onUnstakeToken(stakeAmount);
              }}
            >
              {"UnStake"}
            </Web3Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnableBox;

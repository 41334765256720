/* eslint-disable no-console */
import { memo, useMemo } from "react";

import type { ButtonProps } from "@chakra-ui/react";
import { Box, Button, forwardRef, Tooltip, useMediaQuery, Badge } from "@chakra-ui/react";
import type { NavLinkProps } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { breakpoints } from "../../theme/theme";

type SidebarLinkProps = {
  href?: string;
  label: string;
  children?: React.ReactNode;
  to?: NavLinkProps["to"];
  isCompact?: boolean;
  soon?: boolean;
} & ButtonProps;

export const MainNavLink = memo(
  forwardRef<SidebarLinkProps, "div">(({ isCompact, ...rest }: SidebarLinkProps, ref) => {
    const { label, to, soon } = rest;
    const [isLargerThan2xl] = useMediaQuery(`(min-width: ${breakpoints["2xl"]})`, { ssr: false });

    const location = useLocation();

    const isActive = useMemo(() => {
      return location.pathname === to;
    }, [to, location.pathname]);
    return (
      <Tooltip label={label} placement="right">
        <Button
          width="full"
          justifyContent={{ base: isCompact ? "center" : "flex-start", "2xl": "flex-start" }}
          variant="nav-link"
          className="nav-btn-vsion"
          isActive={isActive}
          minWidth={isCompact ? "auto" : 10}
          iconSpacing={isLargerThan2xl ? 4 : isCompact ? 0 : 4}
          ref={ref}
          isDisabled={soon}
          {...rest}
        >
          <Box display={{ base: isCompact ? "none" : "flex", "2xl": "block" }}>
            {label} {soon && <Badge colorScheme="purple"> Soon</Badge>}
          </Box>
        </Button>
      </Tooltip>
    );
  })
);

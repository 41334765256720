export function Bitcoin(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_482_323)">
        <path
          d="M23.6395 14.9029C22.0368 21.3315 15.5257 25.2438 9.09643 23.6407C2.66976 22.038 -1.24259 15.5266 0.360877 9.09839C1.96285 2.66908 8.47392 -1.24363 14.9014 0.359089C21.3303 1.96184 25.2423 8.47401 23.6395 14.9029Z"
          fill="#F7931A"
        />
        <path
          d="M8.05281 14.0208C7.98757 14.1828 7.8222 14.4258 7.44944 14.3335C7.46259 14.3526 6.49247 14.0946 6.49247 14.0946L5.83887 15.6017L7.55219 16.0289C7.87094 16.1087 8.18333 16.1924 8.49082 16.2711L7.94593 18.4589L9.26105 18.787L9.80067 16.6225C10.1599 16.72 10.5087 16.81 10.8499 16.8947L10.3121 19.0491L11.6288 19.3772L12.1736 17.1936C14.4188 17.6185 16.107 17.4471 16.8176 15.4165C17.3902 13.7815 16.7891 12.8384 15.6079 12.2234C16.4681 12.025 17.1161 11.4592 17.289 10.2903C17.5278 8.69355 16.3121 7.83518 14.6497 7.26255L15.189 5.09955L13.8723 4.77143L13.3474 6.87741C13.0012 6.79116 12.6457 6.70978 12.2925 6.62915L12.8212 4.50927L11.5054 4.18115L10.9658 6.34338C10.6792 6.27813 10.398 6.2136 10.125 6.14575L10.1265 6.13899L8.31076 5.68562L7.96049 7.09186C7.96049 7.09186 8.93737 7.31574 8.91674 7.3296C9.45 7.46273 9.54636 7.81558 9.53026 8.09536L8.05281 14.0208ZM14.2807 14.5086C13.8739 16.1436 11.121 15.2597 10.2285 15.0381L10.9515 12.1398C11.844 12.3625 14.706 12.8035 14.2807 14.5086ZM14.688 10.2667C14.3167 11.7539 12.0255 10.9983 11.2823 10.8131L11.9378 8.18431C12.681 8.36956 15.0746 8.7153 14.688 10.2667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_482_323">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { HStack, useRadioGroup } from "@chakra-ui/react";
import RadioCard from "./RadioCard";

const Status = ({ onChange }: { onChange: (filter: string) => void }) => {
  const options = ["live", "finished"];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "status",
    defaultValue: "live",
    onChange
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};

export default Status;

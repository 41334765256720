export function Cardano(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill="#3468D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.662 8.319a1.186 1.186 0 1 1 0 2.372 1.186 1.186 0 0 1 0-2.372Zm2.864 0a1.186 1.186 0 1 1-.001 2.372 1.186 1.186 0 0 1 0-2.372Zm0 4.99a1.186 1.186 0 1 1-.001 2.372 1.186 1.186 0 0 1 0-2.372Zm-2.864 0a1.187 1.187 0 1 1-.001 2.374 1.187 1.187 0 0 1 0-2.374Zm-1.473-2.454a1.187 1.187 0 1 1 0 2.374 1.187 1.187 0 0 1 0-2.374Zm5.81 0a1.186 1.186 0 1 1-.001 2.372 1.186 1.186 0 0 1 0-2.372Zm1.35-2.045a.736.736 0 1 1-.001 1.473.736.736 0 0 1 0-1.473Zm0 4.908a.736.736 0 1 1 0 1.472.736.736 0 0 1 0-1.472Zm-8.51 0a.736.736 0 1 1 0 1.472.736.736 0 0 1 0-1.472Zm0-4.908a.736.736 0 1 1 0 1.472.736.736 0 0 1 0-1.472Zm4.255-2.454a.736.736 0 1 1 0 1.472.736.736 0 0 1 0-1.472Zm0 9.816a.736.736 0 1 1 0 1.471.736.736 0 0 1 0-1.471Zm3.15.736a.612.612 0 1 1 0 1.225.612.612 0 0 1 0-1.225Zm-6.3 0a.613.613 0 1 1 0 1.225.613.613 0 0 1 0-1.225Zm0-11.043a.614.614 0 1 1 0 1.227.614.614 0 0 1 0-1.227Zm6.3 0a.613.613 0 1 1 0 1.226.613.613 0 0 1 0-1.226Zm3.19 5.48a.612.612 0 1 1 0 1.225.612.612 0 0 1 0-1.224Zm-12.681 0a.613.613 0 1 1 0 1.226.613.613 0 0 1 0-1.225Zm-.45-3.762a.49.49 0 1 1 0 .982.49.49 0 0 1 0-.982Zm0 7.853a.49.49 0 1 1 0 .98.49.49 0 0 1 0-.98Zm13.582 0a.49.49 0 1 1 0 .98.49.49 0 0 1 0-.98Zm0-7.853a.49.49 0 1 1 0 .981.49.49 0 0 1 0-.981Zm-6.791-3.927a.49.49 0 1 1 0 .982.49.49 0 0 1 0-.982Zm0 15.706a.49.49 0 1 1 0 .98.49.49 0 0 1 0-.98Zm4.336-.327a.41.41 0 1 1 0 .818.41.41 0 0 1 0-.818Zm-8.673 0a.41.41 0 1 1 0 .819.41.41 0 0 1 0-.82Zm0-14.888a.41.41 0 1 1 0 .82.41.41 0 0 1 0-.82Zm8.673 0a.409.409 0 1 1 0 .82.409.409 0 0 1 0-.82Zm4.255 7.444a.41.41 0 1 1 0 .82.41.41 0 0 1 0-.82Zm-17.182 0a.41.41 0 1 1 0 .82.41.41 0 0 1 0-.82Z"
        fill="#fff"
      />
    </svg>
  );
}

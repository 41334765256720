import { SearchIcon } from "@chakra-ui/icons";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

const Search = () => {
  return (
    <Box w={{ base: "100%", md: "auto", lg: 64, xl: 80 }} role="search">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input type="text" placeholder="Search farms" />
      </InputGroup>
    </Box>
  );
};

export default Search;

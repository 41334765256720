export function numberFinalToArr(value: number | string) {
  if (typeof value === 'number') {
    value = value.toString();
  }
  if (value.length != 7) {
    return ['0', '0', '0', '0', '0', '0']
  }
  return [...value].filter((_, idx) => idx > 0).reverse()

}

export function WinnerNumbersToNumberFinal(arr: string[] | number[]) {
  if (arr.length != 6) {
    return undefined
  }
  return [1, ...arr.reverse()].join('')

}

import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { SmartContract, ValidContractInstance, useContract, useContractRead, useContractWrite } from '@thirdweb-dev/react';
import DapRewardAbi from '../../../abi/DapReward.abi.json'
import utilStyles from "../marketPlace/utils.module.css";

import infoNft from '../../../assets/data/NFTs/info.json'
import GenericERC20Abi from '../../../abi/GenericERC20.json'

import { formatUnits, getAddress, parseEther, parseUnits } from 'ethers/lib/utils';
import { BaseContract, BigNumber } from 'ethers';
import { ChangeEvent, FormEvent, useMemo, useRef, useState } from 'react';
import ImageCheckbox from './ImageCheckbox';
import { tokenValueTxt } from '../../../utils/formatters';
interface TPropsButtonReward {
}

const tokens = [
  { id: "0", symbol: "USDT" },
  { id: "1", symbol: "VSN" },
  { id: "2", symbol: "VSION" },
]

const unitFeeDapAmount = parseEther("0.006") //1.5
const maxFeeDapAmount = parseEther("0.02") //5
// const feeHolderAmount = parseEther("0.028")//7

const dapRewardAddr = "0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC"
const ButtonReward: React.FC<TPropsButtonReward> = () => {
  // const { idToken } = props
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
  const toast = useToast()
  const cancelRef = useRef<any>();
  const finalRef = useRef(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const [amount, setAmount] = useState(0)
  const [selectedTokenId, setSelectedTokenId] = useState('')
  const [stateWithdrawDAP, setStateWithdrawDAP] = useState<{
    reward: BigNumber,
    idToken: string,
    amounts: number[],
    ids: string[]
  }>({
    reward: BigNumber.from(0),
    idToken: "0",
    amounts: [],
    ids: []
  })

  const { contract: DapRewardContract } = useContract(dapRewardAddr, DapRewardAbi)
  const { data: userInfo = { lastRewardTimeDAP: BigNumber.from(0) } } =
    useContractRead(DapRewardContract, "userInfo", [dapRewardAddr])

  const { data: rewardToken = { token: getAddress("0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC"), } } = useContractRead(DapRewardContract, "rewardTokens", [selectedTokenId])
  const { contract: TokenRewardContract } = useContract(rewardToken.token, GenericERC20Abi)
  const { data: decimalRewardToken = 3 } = useContractRead(TokenRewardContract, "decimals")
  const { data: symbolRewardToken = '' } = useContractRead(TokenRewardContract, "symbol")

  const { data: balanceRewardToken = BigNumber.from(0) } =
    useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "balanceOf", string[], BigNumber>(TokenRewardContract, "balanceOf", [dapRewardAddr])


  const { data: pool = {
    lastUpdatePool: BigNumber.from(0),
    initialAmount: BigNumber.from(0),
    remainingAmount: BigNumber.from(0)
  } } =
    useContractRead<string, ValidContractInstance, SmartContract<BaseContract>, "poolDAPByRewardToken", string[], {
      lastUpdatePool: BigNumber
      initialAmount: BigNumber
      remainingAmount: BigNumber
    }>(DapRewardContract, "poolDAPByRewardToken", [selectedTokenId])


  const { mutateAsync: withdrawDAP } = useContractWrite(DapRewardContract, "withdrawDAP")

  const feeWithdraw = useMemo(() => {
    if (unitFeeDapAmount.mul(amount).lte(maxFeeDapAmount)) {

      return unitFeeDapAmount.mul(amount)
    }
    return maxFeeDapAmount
  }, [amount])

  const handlerSubmitWithdrawDap = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const formData = new FormData(ev.target as HTMLFormElement)
    const arrIdSelected = infoNft
      .map(({ id, price }) => {
        const amount = formData.get(`checkBalance-${id}`) as string
        return { id, amount: parseInt(amount), price }
      })
      .filter(({ amount }) => typeof amount == 'number' && amount > 0)
      .reduce((acc: { ids: string[], amounts: number[], total: BigNumber }, nft: { id: string, amount: number, price: number }) => {
        acc.ids.push(nft.id)
        acc.amounts.push(nft.amount)
        acc.total = acc.total.add(parseUnits(nft.price.toString(), 8).mul(nft.amount))
        return acc
      }, { ids: [], amounts: [], total: BigNumber.from(0) })
    if (arrIdSelected.ids.length === 0) {
      toast({
        title: 'Error tx.',
        description: "Select a nft DAP ",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return
    }
    const tokenID = formData.get(`currency`) as string
    if (tokenID === "") {
      toast({
        title: 'Error tx.',
        description: "Select currency reward ",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return
    }
    onOpenAlert()

    let poolCurrent
    if (pool.lastUpdatePool.toNumber() + 60 * 60 * 24 * 22 < Date.now() / 1000) {
      poolCurrent = balanceRewardToken.mul(70).div(100)
    } else {
      poolCurrent = pool.initialAmount
    }
    const reward = poolCurrent.mul(arrIdSelected.total).div("80000000000000000")

    setStateWithdrawDAP({
      reward,
      idToken: tokenID,
      amounts: arrIdSelected.amounts,
      ids: arrIdSelected.ids
    })
  }
  const handleAddAmount = (_amount: number) => {
    setAmount(amount + _amount)
  }
  const handleSubAmount = (_amount: number) => {
    setAmount(amount - _amount)
  }

  const hasReward = useMemo(() => {
    return userInfo.lastRewardTimeDAP.toString() == 0 ||
      userInfo.lastRewardTimeDAP.toNumber() + 60 * 60 * 24 * 22 > Date.now() / 1000
  }, [userInfo?.lastRewardTimeDAP])


  const handlerCurency = (ev: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTokenId(ev.target.value)
  }


  return (
    <div>
      <Button
        borderRadius="12px"
        textColor="white"
        w="275px"
        h="40px"
        mb={2}
        className={utilStyles.btn}
        background="linear-gradient(25deg, #af0f7d, #58086c)"
        _hover={{
          background: "linear-gradient(329deg, #9900d1 12%, #af117d 100%)",
          textColor: "white",
          border: "none",
          fontSize: "20px"
        }}
        onClick={onOpen}
      >
        Earn
      </Button>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={"bold"}>Withdraw</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {!hasReward && (
              <Text>
                Next Withdraw:{userInfo.lastRewardTimeDAP.toString()}
              </Text>)}
            <form onSubmit={(ev) => handlerSubmitWithdrawDap(ev)}>
              <Text fontWeight={'semibold'} mb={4}>Select the currency with which you will receive the reward.</Text>
              <Select
                name='currency'
                placeholder="Select option"
                defaultValue={""}
                onChange={handlerCurency}
              >
                {tokens &&
                  tokens.map(({ symbol, id }, idx) => (
                    <option key={idx} value={id}>
                      {symbol}
                    </option>
                  ))}
              </Select>
              <br />
              <Text fontWeight={'semibold'} mb={4}>Select which nft you will claim.</Text>
              <Grid ref={gridRef} templateColumns="repeat(3, 1fr)" gap={6}>
                {infoNft.map(({ id, image }, idx) => (
                  <ImageCheckbox
                    key={idx}
                    idToken={id}
                    image={image}
                    name={'checkBalance-' + idx}
                    onAddamount={handleAddAmount}
                    onSubamount={handleSubAmount}
                  />
                ))}
              </Grid>
              <br />
              <b>Unit fee: </b>{tokenValueTxt(parseInt(formatUnits(unitFeeDapAmount.toString() || 0, 15)), 3, "BNB")}
              <br />
              <b>Fee: </b>{tokenValueTxt(parseInt(formatUnits(feeWithdraw.toString() || 0, 15)), 3, "BNB")}
              <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                <Button isDisabled={!hasReward} type='submit'>WithdrawDap</Button>
              </Grid>
            </form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isOpen={isOpenAlert}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>You reward</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <b>Fee: </b>{tokenValueTxt(parseInt(formatUnits(feeWithdraw.toString() || 0, 15)), 3, "BNB")}
            <br />
            <b>Reward: ≈{tokenValueTxt(
              parseInt(
                formatUnits(stateWithdrawDAP.reward.toString() || 0, decimalRewardToken - 3)), 3, symbolRewardToken)}</b>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="red" ref={cancelRef} onClick={onCloseAlert}>
              Not Withdraw
            </Button>
            <Button colorScheme="green" ml={3} onClick={() => withdrawDAP({
              args: [
                stateWithdrawDAP.idToken,
                stateWithdrawDAP.amounts,
                stateWithdrawDAP.ids
              ],
              overrides: {
                value: feeWithdraw
              }
            })}>
              Withdraw
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ButtonReward;
import { LiFiWidget } from "@lifi/widget";

const XswapWidget = (variante: string, subvariante: string) => {
  const widgetConfig: any = {
    fromChain: 56,
    toChain: 56,
    fromToken: "0x0000000000000000000000000000000000000000",
    toToken: "0x4bbd4fa12b2b874a13e9555f5c5d0f6ad035acc3",
    disableLanguageDetector: true,
    tokens: {
      featured: [
        {
          address: "0x4bbd4fa12b2b874a13e9555f5c5d0f6ad035acc3",
          symbol: "VSION",
          decimals: 18,
          chainId: 56,
          name: "VSION COIN",
          logoURI: "https://bscscan.com/token/images/criptovision_32.png?v=2"
        }
      ]
    },
    variant: variante,
    subvariant: subvariante,
    hiddenUI: ["poweredBy", "appearance", "language", "walletMenu"],
    routePriority: "FASTEST",
    appearance: "dark",

    containerStyle: {
      border: "1px solid #363c4e",
      borderRadius: "8px",
      background: "red"
    },
    insurance: true,
    languages: {
      default: "es",
      allow: ["es", "pt", "th", "tr", "id", "fr", "en", "bn", "de", "it", "ko", "uk", "vi", "zh"]
    },
    theme: {
      palette: {
        primary: { main: "#af0f7d" },
        secondary: { main: "#58086c" }
      },
      shape: {
        borderRadius: 0,
        borderRadiusSecondary: 0
      },
      MuiAppBar: {
        styleOverrides: { background: "red" }
      }
    }
  };
  return (
    <>
      <LiFiWidget integrator="Vsion Hub" config={widgetConfig} />
    </>
  );
};

export default XswapWidget;

import { Web3Button, useContractWrite } from '@thirdweb-dev/react';
import { Input, Text } from '@chakra-ui/react';
import { ChangeEvent, useContext, useReducer, useState } from 'react';
import { TLotteryStart } from '../type';
import { useToast } from '@chakra-ui/react';
import { LotteryContext } from '../contextLottery';

// ABI
import AbiLottery from '../../../abi/Lottery.abi.json';

type LotteryAction = {
  type: 'UPDATE_LOTTERY' | 'ADD_REWARD'
  value: any; // Parámetro adicional
};

const lotteryReducer = (state: TLotteryStart, action: LotteryAction): TLotteryStart => {
  switch (action.type) {
    case 'UPDATE_LOTTERY':
      return { ...state, ...action.value };
    case 'ADD_REWARD':
      const { id, value } = action.value;
      const fooRewardsBreakdown = state.rewardsBreakdown
      fooRewardsBreakdown[id] = value
      const total = fooRewardsBreakdown
        .reduce((acc, reward) => acc + reward, 0)
      return { ...state, rewardsBreakdown: fooRewardsBreakdown, totalRewardsBreakdown: total };
    default:
      return state;
  }
};

const initStateLottery: TLotteryStart = {
  endTime: 0,
  discountDivisor: 3300,
  rewardsBreakdown: [
    1000,
    1000,
    1000,
    2000,
    2000,
    3000
  ],
  treasuryFee: 3000,
  totalRewardsBreakdown: 10000
}

const StartLotteryButton = () => {
  const toast = useToast()

  const [startLotteryState, dispatch] = useReducer(lotteryReducer, initStateLottery);
  const [idReward, setIdReward] = useState<number>(0);
  const { lotteryContract, lotteryAddress, sucessToast, errorToast, viewLottery } = useContext(LotteryContext)
  const { mutateAsync: startLottery } = useContractWrite(
    lotteryContract,
    "startLottery",
  );

  const changeEndTime = (newValue: string) => {
    const newTime = new Date(newValue).getTime() / 1000
    dispatch({ type: 'UPDATE_LOTTERY', value: { endTime: newTime } });
  };

  const changeDiscountDivisor = (ev: ChangeEvent<HTMLInputElement>) => {

    let value: number = parseInt(ev.target.value)
    if (value < 300) {
      ev.target.value = '300'
      value = 300
    }
    dispatch({ type: 'UPDATE_LOTTERY', value: { discountDivisor: value } });
  };

  const changeTreasuryFee = (newValue: string) => {
    const value: number = parseInt(newValue)
    try {
      dispatch({ type: 'UPDATE_LOTTERY', value: { treasuryFee: value } });
    } catch (err: any) {
      console.log(err.shortMessage)
      toast({
        title: 'Error Transaccion.',
        description: "error 1",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const addRewardsBreakdown = (newValue: string) => {
    const value: number = parseInt(newValue)
    dispatch({ type: 'ADD_REWARD', value: { id: idReward, value } });
  }

  const handleAction = async () => {
    try {
      const res = await startLottery({
        args: [
          startLotteryState.endTime,
          startLotteryState.discountDivisor,
          startLotteryState.rewardsBreakdown,
          startLotteryState.treasuryFee,
        ]
      })
      sucessToast(res.receipt.transactionHash)
    } catch (err: any) {
      errorToast(err.reason || "error")
    }
  }

  return (
    <div>

      <Text mb='8px'>1% = 100 </Text>
      <Text mb='8px' fontWeight='semibold'>EndTime: </Text>
      <Input type='datetime-local' onChange={(ev) => changeEndTime(ev.target.value)} />
      <Text mb='8px' fontWeight='semibold'>DiscountDivisor: (MIN(300)) </Text>
      <Input type='number' value={startLotteryState.discountDivisor.toString()} onChange={changeDiscountDivisor} />
      <Text fontWeight={'bold'}> Dicount Max: {100 - (startLotteryState.discountDivisor + 1 - 100) / startLotteryState.discountDivisor * 100}%</Text>
      <Text mb='8px' fontWeight='semibold'>RewardsBreakdown: (Total debe ser 100%) </Text>
      <Input
        type='number'
        value={idReward}
        onChange={(ev) => setIdReward(parseInt(ev.target.value))} />

      <Input type='number' onChange={(ev) => addRewardsBreakdown(ev.target.value)} />
      {startLotteryState && (startLotteryState.rewardsBreakdown.map((reward, idx) => (
        <Text key={idx} mb='8px'>RewardsBreakdown - {idx} : {reward.toString()}</Text>
      )))}
      <Text mb='8px'>Total Reward: {startLotteryState.totalRewardsBreakdown.toString()}</Text>

      <Text mb='8px' fontWeight='semibold'>TreasuryFee: (max(30%)) </Text>
      <Input type='number' value={startLotteryState.treasuryFee.toString()} onChange={(ev) => changeTreasuryFee(ev.target.value)} />

      <Web3Button
        contractAddress={lotteryAddress}
        contractAbi={AbiLottery}
        action={handleAction}
        // isDisabled={viewLottery.status != 3}
        style={{ backgroundColor: viewLottery.status == 3 ? 'greenyellow' : 'gray' }}
      >
        Start Next Lottery
      </Web3Button>
    </div>
  );
};

export default StartLotteryButton;
import React from "react";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import { createRoot } from "react-dom/client";



import "./i18n";
//import IndexWeb from "./pages";
//import XHome from "./pages/Home";

import "./index.css";

import { theme } from "./theme/theme";
import ProviderWeb3 from "./providerWeb3";
const root = createRoot(document.getElementById("root") as HTMLElement);


root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
     <ProviderWeb3 />
    </ChakraProvider>{" "}
  </React.StrictMode>
);

import { Grid, Box, GridItem, TableContainer, Table, Thead, Tr, Button, Tbody, Th, Td } from "@chakra-ui/react";
import imgHeader from "./icon.svg";
import HeaderPage from "../../components/HeaderPage";
import { useAddress, useContractRead, useContract, useContractWrite } from "@thirdweb-dev/react";
import { useToast } from "@chakra-ui/react";
import LinkBinance from "../../components/nft/link-binance";
const ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function",
    constant: true
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function",
    constant: true
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "wallets",
    outputs: [
      {
        internalType: "address",
        name: "wallet",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "monthlyAmount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "remainingAmount",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "isComplete",
        type: "bool"
      },
      {
        internalType: "uint256",
        name: "lastPaymentDate",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "monthsPaid",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function",
    constant: true
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "wallet",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256"
      }
    ],
    name: "addWallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "sendPayment",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "withdrawAllTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "getTokenBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function",
    constant: true
  }
];

const UnixToDate = ({ unixTimestamp }) => {
  // Convertir Unix timestamp a milisegundos
  const date = new Date(unixTimestamp * 1000);

  // Formatear la fecha
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();

  return (
    <div>
      <p>Fecha: {formattedDate}</p>
      <p>Hora: {formattedTime}</p>
    </div>
  );
};

const Header = () =>
  HeaderPage(
    "Vesting",
    " Token VSN",

    "",
    `Our approach ensures a token distribution that promotes the stability and growth of the VSN ecosystem. Join us on this journey towards a more stable future in the cryptocurrency space with the Vesting Token VSN.
`,
    "",
    imgHeader,
    ""
  );
const Vesting = () => {
  const address = useAddress();
  const toast = useToast();
  const { contract } = useContract("0xa1e19660e6e1d92704bd70cba96818592b8f2a30", ABI);

  const { data: wallets } = useContractRead(contract, "wallets", [address]);

  console.log("wallets", wallets?.wallet);

  const { mutateAsync: sendPayment, error: errorSC } = useContractWrite(contract, "sendPayment");

  const { mutateAsync: addWallet, error: errorSCw } = useContractWrite(contract, "addWallet");

  const add30DaysToUnix = (unixTimestamp) => {
    const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000; // 30 días en milisegundos
    const newUnixTimestamp = unixTimestamp * 1000 + millisecondsIn30Days; // Sumar 30 días en milisegundos
    return Math.floor(newUnixTimestamp / 1000); // Convertir de nuevo a Unix
  };
  const inv = async () => {
    try {
      const res = await sendPayment({
        args: []
      });
      if (errorSC) {
        toast({
          title: "error tx.",
          description: errorSC.reason,
          status: "error",
          duration: 9000,
          isClosable: true
        });
      } else {
        toast({
          title: "Success tx.",
          description: (
            <LinkBinance
              className="text-blue-900"
              textPrev="Transaction complete"
              transaction={res.receipt.transactionHash}
            />
          ),
          status: "success",
          duration: 9000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: "error tx.",
        description: error.reason,
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };
  const invAndi = async () => {
    try {
      const res = await addWallet({
        args: ["0x1538Ace32F74E44f7cAaC3DD5469feb13A1C23CC", "23000000000000000000000"]
      });
      if (errorSCw) {
        toast({
          title: "error tx.",
          description: errorSCw.reason,
          status: "error",
          duration: 9000,
          isClosable: true
        });
      } else {
        toast({
          title: "Success tx.",
          description: (
            <LinkBinance
              className="text-blue-900"
              textPrev="Transaction complete"
              transaction={res.receipt.transactionHash}
            />
          ),
          status: "success",
          duration: 9000,
          isClosable: true
        });
      }
    } catch (error) {
      console.log("error", error);
      toast({
        title: "error tx.",
        description: error.reason,
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  };

  return (
    <Box p="5">
      <Header />
      <Grid
        templateColumns={{
          base: "repeat(1,minmax(0,1fr))",
          sm: "repeat(1,minmax(0,1fr))",
          md: "repeat(1,minmax(0,1fr))"
        }}
        gap={{ base: 1, md: 12 }}
        p={{ base: 1, md: 12 }}
      >
        <GridItem>
          {wallets?.wallet === address ? (
            <>
              {" "}
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Next Date</Th>
                      <Th isNumeric>Value</Th>
                      <Th isNumeric></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <UnixToDate unixTimestamp={wallets?.lastPaymentDate?.toString()} />{" "}
                      </Td>
                      <Td>
                        <UnixToDate unixTimestamp={add30DaysToUnix(wallets?.lastPaymentDate?.toString())} />{" "}
                      </Td>
                      <Td isNumeric>{wallets?.monthlyAmount?.toString() / 1e18} VSN </Td>
                      <Td isNumeric>
                        <Button onClick={() => inv()} colorScheme="blue">
                          Get VSN
                        </Button>
                      </Td>

                      {address == "0x3AE361Cb5975C03FCfa4054003e8988A3FD23376" && (
                        <Td isNumeric>
                          <Button onClick={() => invAndi()} colorScheme="blue">
                            Get VSN Admin
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <div className="center">
                <p>You do not have pre-sale VSN, you can buy here</p>
                <Button colorScheme="blue" size="lg">
                  <a
                    href="https://pancakeswap.finance/swap?outputCurrency=0x5A0AB3c4668c7b0a5A6c86a115c2f0dd320b60Fc"
                    target="_blank"
                  >
                    Buy VSN
                  </a>
                </Button>
              </div>

              {address == "0x3AE361Cb5975C03FCfa4054003e8988A3FD23376" && (
                <div>
                  <Button onClick={() => invAndi()} colorScheme="blue">
                    Get VSN Admin
                  </Button>
                </div>
              )}
            </>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Vesting;

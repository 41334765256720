import {
  Box,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Stack,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  Text,
  Icon,
  Heading,
  Divider,
  OrderedList,
  UnorderedList,
  ListItem,
  Textarea,
  Button,
  Avatar,
  Flex,
  SimpleGrid,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader
} from "@chakra-ui/react";
import { useParams } from "react-router";
import bgClassRoom from "../../assets/images/bg/crypto-hero-bg-desktop.png";
import { MdWatchLater } from "react-icons/md";
import TutorCard from "../../components/Card/Tutor";
import { AiFillStar } from "react-icons/ai";

export default function ClassRoom(props: any) {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  console.log("props", props);
  console.log("id", id);

  return (
    <>
      <Box
        p={{ base: "1", md: "5" }}
        bgImg={bgClassRoom}
        backgroundSize="cover"
        backgroundPosition="top left"
        className="text-center no-repeat"
        display="flex"
        flexDirection="column"
        shadow="lg"
        width="100%"
      >
        <Box mt={20}>
          <HStack alignItems="flex-start" gap={0} flexDirection="row">
            <Box width="100%">
              <iframe
                width="100%"
                height="600px"
                src={"https://www.youtube.com/embed/QhBnZ6NPOY0" + "?autoplay=1"}
                title="Academy"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <Tabs isFitted variant="enclosed" mb="25px">
                <TabList>
                  <Tab _selected={{ color: "white", bg: "#af117d" }} _disabled={{ color: "#D6BCFA" }}>
                    Description
                  </Tab>
                  <Tab _selected={{ color: "white", bg: "#af117d" }} _disabled={{ color: "#D6BCFA" }}>
                    Question
                  </Tab>

                  <Tab _selected={{ color: "white", bg: "#af117d" }} _disabled={{ color: "#D6BCFA" }}>
                    Review
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Heading as="h5" mb="5px" fontSize="24px" fontWeight="bold">
                      About this course
                    </Heading>
                    <Text color="#929292" mb="20px" fontSize="16px">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quam nisi rerum, illum provident
                      aspernatur! Possimus sed fugit dignissimos, animi dolore maiores minima voluptatibus molestiae
                      explicabo hic! Non, soluta nam.
                    </Text>
                    <Divider />
                    <Heading as="h5" mb="5px" mt="15px" fontSize="22px" fontWeight="bold">
                      Description
                    </Heading>
                    <Text color="#929292" mb="20px" fontSize="16px">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quam nisi rerum, illum provident
                      aspernatur!
                    </Text>
                    <OrderedList>
                      <ListItem>Lorem ipsum dolor sit amet</ListItem>
                      <ListItem>Consectetur adipiscing elit</ListItem>
                      <ListItem>Integer molestie lorem at massa</ListItem>
                      <ListItem>Facilisis in pretium nisl aliquet</ListItem>
                    </OrderedList>
                    <Heading as="h5" mb="5px" mt="15px" fontSize="22px" fontWeight="bold">
                      Important
                    </Heading>
                    <Text color="#929292" mb="20px" fontSize="16px">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quam nisi rerum, illum provident
                      aspernatur!
                    </Text>
                    <Stack flexDirection="row" gap={4}>
                      <Box>
                        <Heading as="h5" mb="5px" mt="15px" fontSize="22px" fontWeight="bold">
                          What you will learn
                        </Heading>
                        <UnorderedList>
                          <ListItem>Lorem ipsum dolor sit amet</ListItem>
                          <ListItem>Consectetur adipiscing elit</ListItem>
                          <ListItem>Integer molestie lorem at massa</ListItem>
                          <ListItem>Facilisis in pretium nisl aliquet</ListItem>
                        </UnorderedList>
                      </Box>
                      <Box>
                        <Heading as="h5" mb="5px" mt="15px" fontSize="22px" fontWeight="bold">
                          Lorem ipsum
                        </Heading>
                        <UnorderedList>
                          <ListItem>Lorem ipsum dolor sit amet</ListItem>
                          <ListItem>Consectetur adipiscing elit</ListItem>
                          <ListItem>Integer molestie lorem at massa</ListItem>
                          <ListItem>Facilisis in pretium nisl aliquet</ListItem>
                        </UnorderedList>
                      </Box>
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <Stack flexDirection={{ base: "column-reverse", md: "row" }} gap={4}>
                      <Stack width={{ base: "100%", md: "70%" }}>
                        <Heading as="h5" mb="20px" mt="15px" fontSize="22px" fontWeight="bold">
                          All questions in this course (9)
                        </Heading>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                          <Stack key={item} gap={3} mb={5}>
                            <Divider />
                            <Flex>
                              <Avatar bg="teal.500" name="Ask questions" src="https://bit.ly/sage-adebay" />
                              <Box ml="3">
                                <Text fontWeight="bold">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</Text>
                                <Text fontSize="sm">
                                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto rem consequuntur
                                  autem itaque nihil atque blanditiis nostrum eos debitis adipisci culpa exercitationem
                                  deleniti.
                                </Text>
                              </Box>
                            </Flex>
                            <Text fontSize="12px" color="#929292">
                              23 August 2020 - 20:30
                            </Text>
                          </Stack>
                        ))}
                      </Stack>
                      <Stack
                        position="sticky"
                        top={2}
                        background="#000b25"
                        zIndex={6}
                        width={{ base: "100%", md: "30%" }}
                        rounded="lg"
                        py={6}
                        px={5}
                        height="max-content"
                        transition="all"
                        border="2px solid rgba(193,193,193,.1)"
                        _hover={{ border: "2px solid #af117d", shadow: "lg" }}
                      >
                        <Heading as="h5" mb="10px" fontSize="20px">
                          Ask questions
                        </Heading>
                        <Textarea placeholder="Here is a sample placeholder" size="sm" rounded="lg" />
                        <Button className="btn-mtks">Send</Button>
                      </Stack>
                    </Stack>
                  </TabPanel>

                  <TabPanel>
                    <Heading as="h5" mb="20px" fontSize="24px" fontWeight="bold">
                      Student comments
                    </Heading>
                    <Stack gap={0.1} rounded="lg" py={6} px={5} alignItems="center" justifyContent="center">
                      <Heading as="h5" fontSize="4xl" color="#f6b800" fontWeight="bold">
                        5.0
                      </Heading>
                      <Stack py={2} px={1} rounded="lg" flexDirection="row" alignItems="center" gap="0.2rem">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <AiFillStar fontSize="6xl" key={star} color="#f6b800" />
                        ))}
                      </Stack>
                      <Heading as="h5" fontSize="3xl" color="#f6b800" fontWeight="bold">
                        Course evaluation
                      </Heading>
                    </Stack>
                    <Heading as="h5" mb="25px" mt="15px" fontSize="22px" fontWeight="bold">
                      Reviews
                    </Heading>
                    <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                        <Stack key={item} mb="20px">
                          <Flex>
                            <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adeyo" />
                            <Box ml="3">
                              <Text fontWeight="bold">Segun Adebayo</Text>
                              <Text fontSize="sm">Lorem ipsum dolor sit amet consectetur adipisicing elit. </Text>
                            </Box>
                          </Flex>
                          <Flex alignItems="center" gap={3}>
                            <Stack py={2} px={1} rounded="lg" flexDirection="row" alignItems="center" gap="0.2rem">
                              {[1, 2, 3, 4, 5].map((star) => (
                                <AiFillStar fontSize="16px" key={star} color="#f6b800" />
                              ))}
                            </Stack>
                            <Text fontSize="xs" color="#929292">
                              23 August 2020 - 20:30
                            </Text>
                          </Flex>
                        </Stack>
                      ))}
                    </SimpleGrid>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
            <Box width={{ base: "100%", md: 414 }} display={{ base: "none", md: "block" }}>
              <Accordion allowMultiple defaultIndex={[0]} mb="20px">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                  <AccordionItem key={item}>
                    <h2>
                      <AccordionButton _expanded={{ bg: "#af117d", color: "white" }}>
                        <Box as="span" flex="1" textAlign="left">
                          Class {item}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Stack gap={4}>
                        {[1, 2, 3, 4, 5, 6].map((item) => (
                          <Box key={item} position="relative">
                            <Checkbox iconColor="#af117d">
                              <Text fontSize="md">Lesson {item} </Text>
                            </Checkbox>
                            <Stack
                              gap={0.2}
                              alignItems="flex-start"
                              flexDirection="row"
                              position="absolute"
                              right={0}
                              bottom={0}
                            >
                              <Text fontSize="xs" color="#929292">
                                15 min
                              </Text>
                              <Icon w={3} h={3} color="#929292" as={MdWatchLater} />
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
              <Box>
                <TutorCard />
              </Box>
            </Box>
          </HStack>
          <Box display={{ base: "block", md: "none" }}>
            <TutorCard />
          </Box>
        </Box>
      </Box>
      <Box
        display={{ base: "block", md: "none" }}
        textAlign="right"
        mr={3}
        zIndex={6}
        position="sticky"
        right={5}
        bottom={8}
      >
        <Button className="btn-mtks" onClick={onOpen}>
          View classes
        </Button>
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent background="#0f0e2b">
          <DrawerCloseButton />
          <DrawerHeader>List Class</DrawerHeader>

          <DrawerBody>
            <Accordion allowMultiple defaultIndex={[0]} mb="20px">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <AccordionItem key={item}>
                  <h2>
                    <AccordionButton _expanded={{ bg: "#af117d", color: "white" }}>
                      <Box as="span" flex="1" textAlign="left">
                        Class {item}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Stack gap={4}>
                      {[1, 2, 3, 4, 5, 6].map((item) => (
                        <Box key={item} position="relative">
                          <Checkbox iconColor="#af117d">
                            <Text fontSize="md">Lesson {item} </Text>
                          </Checkbox>
                          <Stack
                            gap={0.2}
                            alignItems="flex-start"
                            flexDirection="row"
                            position="absolute"
                            right={0}
                            bottom={0}
                          >
                            <Text fontSize="xs" color="#929292">
                              15 min
                            </Text>
                            <Icon w={3} h={3} color="#929292" as={MdWatchLater} />
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

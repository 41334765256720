import { useEffect, useState } from "react";
//import axios from 'axios';
//import DapNftABi from "../abi/DapNft.abi.json";
import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
// DapNftABi
function useNFT({ id }) {
  const address = useAddress();
  const [nftData, setNftData] = useState(null);

  const { data: contract } = useContract("0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b");

  const { data: getNFT, isLoading } = useContractRead(contract, "balanceOf", [address, id]);

  useEffect(() => {
    // if (getNFT == null || Number(getNFT?.toString()) === 0) return; // prevent loading before the NFT has been loaded
    const nftrInfo = {
      identifier: id,
      external_url: "https://dap.vsion.io/nft-sad/" + id,
      image: "https://dap.vsion.io/token/token_0" + id + ".gif",
      animation_url: "https://dap.vsion.io/token/token_0" + id + ".mp4",
      have: getNFT == null || Number(getNFT?.toString()) === 0 ? false : true
    };

    setNftData(nftrInfo);
  }, [getNFT?.toString()]);

  return { isLoading, nftData };
}

export default useNFT;

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import CurrencySwapIcons from "../../Icons/currency-swap-icons";
import { PropsWithRef, useEffect, useRef, useState } from "react";
import PoolCard from "../Pool";
import { TFarm } from "./type";
import AbiFarm from "../../../abi/AbiFarm.json";
import GenericERC20 from "../../../abi/GenericERC20.json";
import AbiPairLp from "../../../abi/PairLP.json";
import {
  SmartContract,
  ValidContractInstance,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite
} from "@thirdweb-dev/react";
import { BaseContract, BigNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { tokenValueTxt } from "../../../utils/formatters";
import Bignumber from "bignumber.js";
import EnableBox, { EnableStatus } from "../EnableBox";

interface TPropsPoolCardFarm {
  item: TFarm;
}

const calculateAPYFarm = (tokenPrice: string, totalLpDepositedInStaking: string) => {
  try {
    const BLOCKS_PER_YEAR = 28800 * 365;
    const REWARD_PER_BLOCK = 0.0072;
    const totalRewardPricePerYear = new Bignumber(tokenPrice).times(REWARD_PER_BLOCK).times(BLOCKS_PER_YEAR);
    const totalPriceOfLpTokensInFarmingContract = new Bignumber(tokenPrice).times(totalLpDepositedInStaking);
    // Calculate APY
    const apy = totalRewardPricePerYear.div(totalPriceOfLpTokensInFarmingContract).times(100);
    return apy.isNaN() || !apy.isFinite() ? 0 : apy.toNumber();
  } catch (e) {
    console.log(e);
    return 0;
  }
};

const AbiSwap = [
  {
    inputs: [],
    name: "GetPriceVsion",
    outputs: [
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

//farm
const swapAddr = "0xaF267f944087891E57d2e5A88f915483f898F8b1";
const farmAddr = "0x986064fF6644861E8e0BA5F81221511cF8Fb4D8B";

const PoolCardFarm: React.FC<PropsWithRef<TPropsPoolCardFarm>> = (props) => {
  const { item } = props;

  const { pid, from, to, address: LPAddr } = item;

  const [APY, setAPY] = useState(27.98);
  const [statusEnable, setStatusEnable] = useState(EnableStatus.NoStake);

  const toast = useToast();
  const { isOpen, onOpen: onOpenAlert, onClose } = useDisclosure();
  const cancelRef = useRef<any>();

  const address = useAddress();

  const { contract: swapContract } = useContract(swapAddr, AbiSwap);
  const { contract: farmContract } = useContract(farmAddr, AbiFarm);
  const { contract: LPPairContract } = useContract(LPAddr, [...AbiPairLp, ...GenericERC20]);

  const { data: tokenPrice, refetch: gettokenPrice } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "GetPriceVsion",
    unknown[],
    BigNumber
  >(swapContract, "GetPriceVsion");

  const { data: allowance, refetch: getallowance } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "allowance",
    any[],
    BigNumber
  >(LPPairContract, "allowance", [address, farmAddr]);

  const { data: balanceFarmInLp, refetch: getbalanceFarmInLp } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "balanceOf",
    any[],
    BigNumber
  >(LPPairContract, "balanceOf", [farmAddr]);

  const { data: pendingReward, refetch: getPendingReward } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "pendingReward",
    any[],
    BigNumber
  >(farmContract, "pendingReward", [pid, address]);

  const { data: timeLeft, refetch: gettimeLeft } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "getTimeLeft",
    any[],
    BigNumber
  >(farmContract, "getTimeLeft", [address, pid]);

  const { data: LPAmount, refetch: getLPAmount } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "getLpAmount",
    any[],
    BigNumber
  >(farmContract, "getLpAmount", [pid, address]);

  const {
    data: liquidity = {
      _reserve0: BigNumber.from(0),
      _reserve1: BigNumber.from(0)
    },
    refetch: getliquidity
  } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "getReserves",
    unknown[],
    {
      _reserve0: BigNumber;
      _reserve1: BigNumber;
    }
  >(LPPairContract, "getReserves");

  const { data: Balance, refetch: getBalance } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "balanceOf",
    any[],
    BigNumber
  >(LPPairContract, "balanceOf", [address]);

  //WRITE

  const { mutateAsync: approveLP } = useContractWrite(LPPairContract, "approve");

  const { mutateAsync: deposit } = useContractWrite(farmContract, "deposit");

  const { mutateAsync: withdrawStake } = useContractWrite(farmContract, "withdraw");
  const { mutateAsync: claimStake } = useContractWrite(farmContract, "claim");

  const { mutateAsync: emergencyWithdraw } = useContractWrite(farmContract, "emergencyWithdraw");

  useEffect(() => {
    if (!allowance || allowance.eq(0)) {
      setStatusEnable(EnableStatus.NoStake);
    } else {
      setStatusEnable(EnableStatus.Aproved);
    }
  }, [allowance]);

  const sucessToast = (message: string) => {
    toast({
      title: "Success tx.",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true
    });
    updateData();
  };

  const errorToast = (message: string) => {
    toast({
      title: "Error tx.",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true
    });
  };

  const handleApprove = async () => {
    try {
      const res = await approveLP({ args: [farmAddr, "999999999000000000000000000"] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };
  const handleWithDraw = async () => {
    try {
      const res = await withdrawStake({ args: [LPAmount] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };
  const handleClaimRewards = async () => {
    try {
      const res = await claimStake({ args: [] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleStakeToken = async (amount: number) => {
    try {
      const res = await deposit({
        args: [pid, parseUnits(amount.toString(), 8), 7]
      });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleUnStakeToken = async (amount: number) => {
    try {
      const res = await withdrawStake({ args: [parseUnits(amount.toString(), 8)] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleClickEmergencyWithdraw = async () => {
    try {
      const res = await emergencyWithdraw({ args: [pid] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const updateData = () => {
    getBalance();
    gettokenPrice();
    getallowance();
    getbalanceFarmInLp();
    gettimeLeft();
    getLPAmount();
    getliquidity();
  };

  useEffect(() => {
    getPendingReward();
    getliquidity();
    const intervalId = setInterval(() => {
      getPendingReward();
      getliquidity();
    }, 8000);

    return () => clearInterval(intervalId);
  }, [getPendingReward, getliquidity]);

  useEffect(() => {
    if (tokenPrice && balanceFarmInLp) {
      // DATA STATICA DESDE EL ANTERIOR FRONT
      calculateAPYFarm(tokenPrice.toString(), balanceFarmInLp.toString());
      setAPY(27.98);
    }
  }, [tokenPrice, balanceFarmInLp]);

  const components = [
    <CurrencySwapIcons from={from} to={to} />,
    <Box color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        Earned
      </Text>
      {tokenValueTxt(parseInt(formatUnits(pendingReward?.toString() || 0, 5)), 3, "")}
    </Box>,
    <Box color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        APR
      </Text>
      {APY.toFixed(3)}%<span className="hidden font-normal text-gray-600 dark:text-gray-400 sm:block"></span>
    </Box>,
    <Box
      display={{ base: "none", lg: "block" }}
      fontSize={{ base: "xs", sm: "sm" }}
      color="black"
      textTransform="uppercase"
    >
      {tokenValueTxt(parseInt(formatUnits(liquidity._reserve0.toString(), 5)), 3, from)}
      <br />
      {tokenValueTxt(parseInt(formatUnits(liquidity?._reserve1.toString(), 15)), 3, to)}
    </Box>,
    <Box
      display={{ base: "none", lg: "block" }}
      fontSize={{ base: "xs", sm: "sm" }}
      color="black"
      textTransform="uppercase"
    >
      x1.5
    </Box>
  ];

  const extraCollapse = [
    <EnableBox
      key={2}
      fromToken={"LP"}
      toToken={from}
      allowance={allowance}
      timeLeft={timeLeft}
      pendingReward={pendingReward}
      RemainingBlock={1000}
      isUnstake
      Balance={Balance}
      Staked={LPAmount}
      contractAddress={farmAddr}
      AbiContract={AbiFarm}
      maxDeposit={0}
      statusBox={statusEnable}
      onAppove={handleApprove}
      onWithDraw={handleWithDraw}
      onClaimRewards={handleClaimRewards}
      onStakeToken={handleStakeToken}
      onUnstakeToken={handleUnStakeToken}
    />,

    <>
      <Button bgColor={"gray.400"} onClick={() => onOpenAlert()}>
        Emergency Withdraw
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Emergency withdraw</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            You can only withdraw your 'capital' now. Current profits will be lost as you are using the emergency
            withdrawal function. Do you wish to proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="red" ref={cancelRef} onClick={onClose}>
              Not Withdraw
            </Button>
            <Button colorScheme="green" ml={3} onClick={handleClickEmergencyWithdraw}>
              Withdraw
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  ];

  return <PoolCard typePool="farm" components={components} extraCollapse={extraCollapse} />;
};

export default PoolCardFarm;

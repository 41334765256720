import React from "react";
import useNFT from "../../hooks/useNFT";
import { Button, Card, CardFooter, Image, Link, Grid, Box, GridItem, Flex } from "@chakra-ui/react";
import { BiChat, BiLike, BiShare } from "react-icons/bi";
const nftID = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const NFTView = ({ id }) => {
  const { nftData, isLoading } = useNFT({ id });
  if (isLoading) return <></>;

  if (!nftData?.have) {
    return <></>;
  }

  return (
    <GridItem key={nftData?.identifier}>
      <Box mx="auto" spacing="10px" w="100%" align="stretch">
        <Card maxW="md">
          {" "}
          <Link color="teal.500" href={"https://app.vsion.io/nft-sad/" + nftData?.identifier}>
            <Image w={"100%"} h="auto" objectFit="cover" src={nftData?.image} alt={"NFT DAP"} />{" "}
          </Link>
        </Card>{" "}
      </Box>
    </GridItem>
  );
};

function NFTComponent(props) {
  const { nftId } = props; // Obtén el valor de nftId de las props

  return (
    <>
      <Grid
        templateColumns={{
          base: "repeat(1,minmax(0,1fr))",

          md: "repeat(2,minmax(0,1fr))",
          lg: "repeat(3,minmax(0,1fr))"
        }}
        mt={4}
        gap={5}
      >
        {nftID?.map((item, key) => (
          <NFTView id={item} key={key} />
        ))}
      </Grid>
    </>
  );
}

export default NFTComponent;

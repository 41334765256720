import { Badge, Tooltip } from "@chakra-ui/react";
import { BiQuestionMark } from "react-icons/bi";
import { PlacementWithLogical } from '@chakra-ui/popper/dist/popper.placement.js';

export default function Question(props: { body: string, placement?: PlacementWithLogical }) {
  const { body, placement } = props
  return (<Tooltip
    placement={placement || 'bottom'}
    label={body}
    color="secondary"
  >
    <Badge variant="flat" color="secondary" className="capitalize">
      <BiQuestionMark />
    </Badge>
  </Tooltip>)
}
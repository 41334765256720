import { Box, Button, HStack, Heading, Image, List, ListIcon, ListItem, Stack, Text } from "@chakra-ui/react";
import bgClassRoom from "../../assets/images/bg/crypto-hero-bg-desktop.png";
import { AiFillStar, AiOutlineClockCircle, AiOutlineDesktop } from "react-icons/ai";
import { FaSuitcase, FaUserTie } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import SyllabusCard from "../../components/Card/Syllabus";
import TutorCard from "../../components/Card/Tutor";

const CourseDetailPage = () => {
  return (
    <Box
      p={{ base: "2", md: "5" }}
      bgImg={bgClassRoom}
      backgroundSize="cover"
      backgroundPosition="top left"
      className="text-center no-repeat"
      display="flex"
      flexDirection="column"
    >
      <Box mt={20}>
        <Box mb={10} borderRadius="20px" width="100%" overflow="hidden" position="relative">
          <Image
            maxWidth="100%"
            width="100%"
            transition="all 2s ease"
            borderRadius="20px"
            src="https://html.vecurosoft.com/educino/demo/assets/img/course/course-details-2-1.jpg"
            alt="girl"
            _hover={{
              transform: "scale(1.2)"
            }}
          />
        </Box>
        <HStack
          bg="white"
          p={{ md: "35px", base: "20px" }}
          borderRadius="20px"
          alignItems="flex-start"
          gap={5}
          flexDirection={{ base: "column-reverse", md: "row" }}
        >
          <Box width="100%">
            <Box mb="20px">
              <Text
                p="0 13px"
                fontSize="14px"
                fontWeight="700"
                letterSpacing=".6px"
                textAlign="center"
                borderRadius="3px"
                textTransform="uppercase"
                transition="all ease .4s"
                backgroundColor="#FFCC33"
                color="#000"
                display="inline-block"
              >
                Busniess Learning
              </Text>
            </Box>
            <Heading as="h3" color="#000" fontSize="34px" mb="15px">
              Advance Begineer's Goal &amp; Managing Quality Course
            </Heading>
            <Stack flexDirection="row" alignItems="center" gap="5px" mb="30px">
              <Stack flexDirection="row" alignItems="center" gap="0.2rem">
                {[1, 2, 3, 4, 5].map((star) => (
                  <AiFillStar fontSize="16px" key={star} color="#f6b800" />
                ))}
              </Stack>

              <Text color="#444444" fontSize="14px" fontWeight="bold">
                (5.0)
              </Text>
            </Stack>
            <Heading
              as="h5"
              color="#000"
              fontSize="24px"
              mb="25px"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: 0,
                bottom: -1,
                width: "80px",
                height: "1px",
                backgroundColor: "#af117d"
              }}
            >
              Overview
            </Heading>
            <Text color="#444444" mb="18px">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
              laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem. Maxime sit nam
              necessitatibus accusamus, reprehenderit doloremque.{" "}
            </Text>
            <Heading as="h5" color="#000" fontSize="24px" mb="25px" position="relative">
              Lorem ipsum dolor sit amet
            </Heading>
            <Text color="#444444" mb="18px">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
              laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem. Maxime sit nam
              necessitatibus accusamus, reprehenderit doloremque.{" "}
            </Text>
            <Heading as="h5" color="#000" fontSize="24px" mb="25px" position="relative">
              Benefit your learners
            </Heading>
            <Text color="#444444" mb="18px">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
              laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem. Maxime sit nam
              necessitatibus accusamus, reprehenderit doloremque.{" "}
            </Text>
            <List spacing={3}>
              <ListItem color="#444444">
                <ListIcon as={MdCheckCircle} color="green.500" />
                Lorem ipsum dolor sit amet, consectetur adipisicing elit
              </ListItem>
              <ListItem color="#444444">
                <ListIcon as={MdCheckCircle} color="green.500" />
                Assumenda, quia temporibus eveniet a libero incidunt suscipit
              </ListItem>
              <ListItem color="#444444">
                <ListIcon as={MdCheckCircle} color="green.500" />
                Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
              </ListItem>

              <ListItem color="#444444">
                <ListIcon as={MdSettings} color="green.500" />
                Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
              </ListItem>
            </List>
            <Box mb="25px" mt="25px">
              <Image
                maxWidth="100%"
                height="auto"
                src="https://html.vecurosoft.com/educino/demo/assets/img/course/course-details-2.jpg"
              />
            </Box>
            <Heading
              as="h5"
              color="#000"
              fontSize="24px"
              mb="25px"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: 0,
                bottom: -1,
                width: "80px",
                height: "1px",
                backgroundColor: "#af117d"
              }}
            >
              Syllabus
            </Heading>

            <Stack mb="25px">
              {[1, 2, 3, 4].map((item) => (
                <SyllabusCard key={item} />
              ))}
            </Stack>

            <Heading
              as="h5"
              color="#000"
              fontSize="24px"
              mb="25px"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: 0,
                bottom: -1,
                width: "80px",
                height: "1px",
                backgroundColor: "#af117d"
              }}
            >
              When would you like to start?
            </Heading>
            <Text color="#444444" mb="20px">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
              laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem.
            </Text>
            <Heading
              as="h5"
              color="#000"
              fontSize="24px"
              mb="25px"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: 0,
                bottom: -1,
                width: "80px",
                height: "1px",
                backgroundColor: "#af117d"
              }}
            >
              What will you achieve?
            </Heading>
            <Text color="#444444" mb="20px">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </Text>
            <List mb="20px" spacing={3}>
              <ListItem color="#444444">
                <ListIcon as={MdCheckCircle} color="green.500" />
                Lorem ipsum dolor sit amet, consectetur adipisicing elit
              </ListItem>
              <ListItem color="#444444">
                <ListIcon as={MdCheckCircle} color="green.500" />
                Assumenda, quia temporibus eveniet a libero incidunt suscipit
              </ListItem>
              <ListItem color="#444444">
                <ListIcon as={MdCheckCircle} color="green.500" />
                Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
              </ListItem>

              <ListItem color="#444444">
                <ListIcon as={MdSettings} color="green.500" />
                Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
              </ListItem>
            </List>
            <Heading
              as="h5"
              color="#000"
              fontSize="24px"
              mb="25px"
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                left: 0,
                bottom: -1,
                width: "80px",
                height: "1px",
                backgroundColor: "#af117d"
              }}
            >
              Who will you learn with?
            </Heading>
            <Stack direction="row" mb="25px">
              {[1, 2].map((item) => (
                <TutorCard key={item} />
              ))}
            </Stack>
          </Box>
          <Box
            p="30px 25px 30px 25px"
            position="sticky"
            top={2}
            zIndex={6}
            mb={4}
            border="2px solid #af117d"
            borderRadius="20px"
            width={{ base: "100%", md: 414 }}
            bg="white"
            shadow="lg"
          >
            <Stack gap="15px" mb="15px">
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Stack color="black" flexDirection="row" alignItems="center">
                  <AiOutlineDesktop />
                  <Text as="span" fontSize="15px" color="black" fontWeight="bold">
                    Duration:
                  </Text>
                </Stack>
                <Box>
                  <Text as="span" fontSize="15px" color="black">
                    4 Week
                  </Text>
                </Box>
              </Stack>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Stack color="black" flexDirection="row" alignItems="center">
                  <AiOutlineClockCircle />
                  <Text as="span" fontSize="15px" fontWeight="bold">
                    Weekly Study:
                  </Text>
                </Stack>
                <Box>
                  <Text as="span" fontSize="15px" color="black">
                    11 Hours
                  </Text>
                </Box>
              </Stack>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Stack color="black" flexDirection="row" alignItems="center">
                  <FaUserTie />
                  <Text as="span" fontSize="15px" fontWeight="bold">
                    Student:
                  </Text>
                </Stack>
                <Box>
                  <Text as="span" fontSize="15px" color="black">
                    204 Students
                  </Text>
                </Box>
              </Stack>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Stack color="black" flexDirection="row" alignItems="center">
                  <FaSuitcase />
                  <Text as="span" fontSize="15px" fontWeight="bold">
                    Course Type:
                  </Text>
                </Stack>
                <Box>
                  <Text as="span" fontSize="15px" color="black">
                    100% online
                  </Text>
                </Box>
              </Stack>
            </Stack>

            <Button as={Link} to="/dashboard/academy/1/Lorem ipsum dolor" className="btn-mtks">
              Join Course
            </Button>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default CourseDetailPage;

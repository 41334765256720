import { Box, Image } from "@chakra-ui/react";

import Book from "../../components/ChartDEX/Book";
import himg from "../../assets/images/banners/banner09.jpg";
export default function XInfo() {
  return (
    <>
      {" "}
      <div>
        <Image
          loading="lazy"
          src={himg}
          h={{ base: 90, sm: 110, md: 120, lg: 180 }}
          w="100%"
          alt="Vsion Token"
          objectFit="cover"
        />
      </div>
      <Box p="5" className="text-center">
        <Book />
      </Box>
    </>
  );
}

import React from "react";
import { Box, Button, VStack, Text, Heading, Image, HStack, IconButton } from "@chakra-ui/react";
import { FaQuestionCircle } from "react-icons/fa";
import { useContract, useContractRead } from "@thirdweb-dev/react";
import ABI from "./ABI.json";
const StakeCakeCard = ({
  cakeAmount,
  duracion,
  onEnableClick,
  isEditing,
  onSaveChanges,
  getStakeInfo,
  getPoolID,
  onwithdrawRewardClick,
  onwitUnstakeClick
}) => {
  const SC = "0x0ceEd856609f2d0ffF739f1131ed5Bb45E64524c";
  const { contract } = useContract(SC, ABI);

  const { data: getCurrentReward } = useContractRead(contract, "getCurrentReward", [1, getPoolID]);

  const convertirUnixAFecha = (unixTimestamp) => {
    // Convertir a milisegundos (JavaScript usa milisegundos)
    let date = new Date(unixTimestamp * 1000);

    // Formatear la fecha
    let day = date.getDate();
    let month = date.getMonth() + 1; // Los meses empiezan en 0
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Asegurarse de que los valores tengan dos dígitos
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    // Crear una cadena con la fecha y hora formateada
    let formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  return (
    <Box bg="gray.800" borderRadius="lg" p={4} mt={30} boxShadow="md" color="white" w="100%" mx="auto">
      <HStack justify="space-between" mb={4}>
        <Heading as="h4" size="md">
          Earn VSN
        </Heading>
        <Image
          borderRadius="full"
          boxSize="30px"
          src="https://vsion.io/assets/img/others/coin.png" // Replace with the appropriate image URL
          alt="Cake Logo"
        />
      </HStack>

      <Text fontSize="sm" color="gray.400">
        Stake VSN
      </Text>

      <Box bg="gray.700" p={4} borderRadius="md" mt={4} mb={4}>
        <Text fontSize="sm" color="gray.400">
          STAKE VSN:
        </Text>
        <Text fontSize="lg">{cakeAmount}</Text>
        <hr />{" "}
        <Text fontSize="sm" color="gray.400">
          DURATION:
        </Text>
        <Text fontSize="xs">
          {Number(getStakeInfo?.startTime?.toString()) + Number(getStakeInfo?.lockTime?.toString()) >
          Math.floor(Date.now() / 1000)
            ? convertirUnixAFecha(
                Number(getStakeInfo?.startTime?.toString()) + Number(getStakeInfo?.lockTime?.toString())
              )
            : "FLEXIBLE"}{" "}
          APR : {getStakeInfo.rewardRate.toString() / 1000} %{" "}
        </Text>
      </Box>

      <VStack spacing={4} mb={4}>
        <Text fontSize="sm" color="gray.400">
          VSN EARNED{" "}
        </Text>
        <Text fontSize="xl">{getCurrentReward?.toString() / 1e18} VSN</Text>
      </VStack>
      <Button mb={4} colorScheme="teal" size="lg" w="100%" onClick={onwithdrawRewardClick}>
        Harvest Rewards
      </Button>

      {Number(getStakeInfo?.startTime?.toString()) + Number(getStakeInfo?.lockTime?.toString()) <
        Math.floor(Date.now() / 1000) && (
        <Button colorScheme="green" size="md" w="100%" onClick={onwitUnstakeClick}>
          UnStake
        </Button>
      )}

      {isEditing && (
        <Button mt={4} w="100%" colorScheme="blue" onClick={onSaveChanges}>
          Save Changes
        </Button>
      )}
    </Box>
  );
};

export default StakeCakeCard;

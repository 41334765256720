import { PropsWithRef, useContext } from 'react';
import { Web3Button, useContractWrite } from '@thirdweb-dev/react';
import { BigNumber } from 'ethers';
import { LotteryContext } from '../contextLottery';

// ABI
import AbiLottery from '../../../abi/Lottery.abi.json';

interface TPropsCloseLotteryButton {
  currentLotteryId: BigNumber
}

const CloseLotteryButton = (props: PropsWithRef<TPropsCloseLotteryButton>) => {
  const { currentLotteryId } = props
  const {
    lotteryContract,
    lotteryAddress,
    viewLottery,
    sucessToast,
    errorToast } = useContext(LotteryContext)
  const { mutateAsync: closeLottery } = useContractWrite(
    lotteryContract,
    "closeLottery",
  );
  const handleAction = async () => {
    try {
      const res = await closeLottery({ args: [currentLotteryId] })
      sucessToast(res.receipt.transactionHash)
    } catch (err: any) {
      errorToast(err.reason || "error")
    }
  }
  return (
    <div>
      <Web3Button
        contractAddress={lotteryAddress}
        contractAbi={AbiLottery}
        action={() => handleAction()}
        isDisabled={viewLottery.status != 1}
        style={{ backgroundColor: viewLottery.status == 1 ? 'greenyellow' : 'gray' }}
      >
        Close Lottery
      </Web3Button>
    </div >
  );
};

export default CloseLotteryButton;
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from "react";

import { useContract, useContractRead } from "@thirdweb-dev/react";
//https://bscscan.com/token/images/criptovision_32.png?v=2
//import { ethers } from "ethers";

const pancakeSwapContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase();
const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; //BNB
const VisionTokenAddress = "0x4bbd4fa12b2b874a13e9555f5c5d0f6ad035acc3"; //VSION
const USDTTokenAddress = "0x55d398326f99059fF775485246999027B3197955"; //USDT
const pancakeSwapAbi = [
  {
    inputs: [
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" }
    ],
    name: "getAmountsOut",
    outputs: [{ internalType: "uint256[]", name: "amounts", type: "uint256[]" }],
    stateMutability: "view",
    type: "function"
  }
];

export const useGetPriceTokenToBNB = (token: string = VisionTokenAddress, symbol = "VSION") => {
  const [balance, setBalance] = useState("Price " + symbol + ": " + 0 + " USD");
  const scpancakeSwapContract = useContract(pancakeSwapContract, pancakeSwapAbi);
  const bnbToSell = "1000000000000000000";

  const tokenXimbiaToBNB = [token, BNBTokenAddress];
  const tokenBNBToUSDT = [BNBTokenAddress, USDTTokenAddress];

  const calcVSIONPrice = useContractRead(scpancakeSwapContract.contract, "getAmountsOut", [
    bnbToSell,
    tokenXimbiaToBNB
  ]);

  const calcBNBPrice = useContractRead(scpancakeSwapContract.contract, "getAmountsOut", [bnbToSell, tokenBNBToUSDT]);

  useEffect(() => {
    if (calcBNBPrice && calcVSIONPrice?.data?.length > 1 && calcBNBPrice?.data?.length > 1) {
      const g = calcVSIONPrice?.data[0] / calcBNBPrice?.data[1];
      const convertedNumber = (g / 10 / 2).toFixed(7);
      setBalance("Price " + symbol + ": " + convertedNumber + " USD");
    }
  }, [calcVSIONPrice, calcBNBPrice]);
  return balance;
};

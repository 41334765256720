import { Box, Hide, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
// import FilterStake from "../../components/FilterStake";
import HeaderPage from "../../components/HeaderPage";
import TableHeader from "../../components/Header/Table";
import PoolCardFarm from "../../components/Card/PoolFarm";
import imgHeader from "../../assets/images/icons-headers/06.png";

const Header = () =>
  HeaderPage(
    "Vsion ",
    "Farming",
    "",
    "Start making USDT profit when farming Token from 1 to 5 years!",
    "",
    imgHeader,
    ""
  );
const headers = ["Pool", "Earned", "APR", "Liquidity", "Multiplier"];

import { Production as farmList } from "../../assets/data/StaticFarm/Production";
import { TFarm } from "../../components/Card/PoolFarm/type";

const itemsFarm: TFarm[] = farmList;

const Farm = () => {
  return (
    <Box
      p="5"
      display="flex"
      flexDirection="column"
      gap={10}
      background="linear-gradient(97.53deg, #0f071d 5.6%, #0f071d 59.16%, #af117d 200.34%)"
    >
      <Header />
      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab _selected={{ color: "white", bg: "#af117d" }}>VSION</Tab>
          <Tab _selected={{ color: "white", bg: "#af117d" }} _disabled={{ color: "#D6BCFA" }} isDisabled>
            VSN
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box p={{ base: "1", md: "5" }} display="flex" flexDirection="column" gap={10}>
              {/* <FilterStake /> */}
              <Box>
                <Hide below="sm">
                  <Box mb={3}>
                    <TableHeader
                      templateColumns={{
                        base: `repeat(${headers.length - 2}, minmax(0,1fr))`,
                        lg: `repeat(${headers.length}, minmax(0,1fr))`
                      }}
                      headers={headers}
                    />
                  </Box>
                </Hide>
                {itemsFarm.map((item) => (
                  <PoolCardFarm key={item.id} item={item} />
                ))}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel>
            <p>VSN!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Farm;

import {
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  DarkMode,
  Flex,
  Grid,
  Image,
} from "@chakra-ui/react";
import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { useParams } from "react-router";

import { getAddress } from "ethers/lib/utils";
import { getGroupRequestById } from "../../../firebase/services/requestNFT";

// ABI
import AbiDAPNFT from "../../../abi/DapNft.abi.json";
import infoNFT from "../../../assets/data/NFTs/info.json";

import CardNFT from "./containers/CardNFT";
import TableOffer from "./containers/TableOffer";
import TableRequest from "./containers/TableRequest";
import FormRequestNFT from "./containers/FormRequestNFT";
import useTokenAux from "./containers/useTokenAux";

// const contractUSDT = getAddress(process.env.NEXT_PUBLIC_ADDRESS_USDT || "0");
const dapAddress = getAddress('0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b');
// const contractVSION = getAddress(process.env.NEXT_PUBLIC_ADDRESS_VSION || "0");

interface TPropsBuyNFT {
}

interface TNFT {
  id: string;
  description: string;
  title: string;
  name: string;
  image: string;
  animation_url: string;
  price: number;
}

const initNFT: TNFT = {
  id: "0",
  description: "",
  title: "Vsion NFT DAP",
  name: "NFT DAP",
  image: "/",
  price: 0,
  animation_url: "/token/token_00.mp4",
};

export default function BuyNFT(props: TPropsBuyNFT) {
  const { } = props;
  const { id = "0" } = useParams();
  const { auxContracts } = useTokenAux()

  const [nftInfo, setNftInfo] = useState(initNFT);

  const [keyFormRequestNFT, setKeyFormRequestNFT] = useState(0);
  const [keyTableRequestNFT, setKeyTableRequestNFT] = useState(0);
  const [keyTableOfferNFT, setKeyTableOfferNFT] = useState(0);

  const address = useAddress();

  const { contract: contactDap } = useContract(dapAddress, AbiDAPNFT)
  const { data: balanceNFT, refetch: GetBalanceNFT } = useContractRead
    (contactDap, "balanceOf", [address, id]);

  const { data: owner } = useContractRead(contactDap, 'owner')

  const isOwner = useMemo(() => {
    if (address && typeof owner == 'string') {
      return getAddress(owner) == getAddress(address)
    }
    return false
  }, [address, owner])


  const handleFormRequest = async () => {
    GetBalanceNFT();
    setKeyFormRequestNFT(keyFormRequestNFT + 1)
    setKeyTableRequestNFT(keyTableRequestNFT + 1)
    setKeyTableOfferNFT(keyTableOfferNFT + 1)
  };

  const handleRequestNFT = () => {
    GetBalanceNFT();
    setKeyFormRequestNFT(keyFormRequestNFT + 1)
    setKeyTableRequestNFT(keyTableRequestNFT + 1)
  };

  const handleOfferNFT = () => {
    GetBalanceNFT();
    setKeyFormRequestNFT(keyFormRequestNFT + 1)
    setKeyTableOfferNFT(keyTableOfferNFT + 1)
  };

  const getItems = (id: string) => {
    return getGroupRequestById(id)
  }

  useEffect(() => {

    if (typeof id == "string" && parseInt(id) >= 0) {
      const info = infoNFT.find((nft) => nft.id == id);
      if (info) {
        setNftInfo(info);
      }
    }
  }, [id]);
  if (parseInt(id) > 9) {
    return (<>
      <h3 className="text-center text-8xl mt-8 font-semibold text-secondary">404</h3>
      <h3 className="text-center text-4xl ">Don&apos;t  exist this page</h3>
    </>
    )
  }
  return (
    <>
      <DarkMode>

        <Flex
          p={6}
          gap={8}
          flexDirection={'column'}
        >
          <Grid
            w={'full'}
            gap={8}
            gridTemplateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", }}
          >
            <Box
              mx={'auto'}
            >
              <Box
                position={'relative'}
              >

                {/*  <video
                className="w-full object-cover h-auto absolute top-0 left-0 align-center z-0"
                autoPlay
                poster="https://i.seadn.io/s/raw/files/857d15222e327ee2e827c667baab7277.png?w=500&amp;auto=format"
                loop
                style={{ width: "500px", height: "500px" }}
              >
                <source src={nftInfo?.animation_url} type="video/mp4" />
              </video>*/}

                <Image
                  // isBlurred
                  // isZoomed
                  src={`https://dap.vsion.io/${nftInfo.image}`}
                  alt={nftInfo?.name}
                  className="m-5"
                />
                <Box
                  rounded={'md'}
                  border={'2px solid #444'}
                  display={'grid'}
                  alignItems={'center'}
                  textAlign={'center'}
                  fontSize={'4xl'}
                  textColor={'white'}
                  fontWeight={'semibold'}
                  position={'absolute'}
                  top={8}
                  left={8}
                  w={20}
                  h={20}
                  zIndex={20}
                >
                  {`${balanceNFT || 0}`}
                </Box>
              </Box>
            </Box>
            <Grid gap={2}>

              <CardNFT
                title={
                  <span>
                    {nftInfo.name}
                    {/* <Question body="Tendras que hacer un peticion para obtener la cantidad de NFT SAD que desees." /> */}
                  </span>
                }
              >
                <FormRequestNFT
                  key={keyFormRequestNFT}
                  id={id}
                  nftInfo={nftInfo}
                  onUpdate={handleFormRequest}
                  auxContracts={auxContracts}
                />
              </CardNFT>
              <CardNFT title="Description">{nftInfo.description}</CardNFT>
            </Grid>
          </Grid>
          <Grid
            w={'full'}
            gap={8}
            gridTemplateColumns={{ base: "repeat(1, 1fr)", lg: (isOwner ? "repeat(2, 1fr)" : "repeat(1, 1fr)") }}
          >

            <CardNFT title="On Sale">
              <TableOffer
                key={keyTableOfferNFT}
                id={id}
                onUpdate={handleOfferNFT}
                auxContracts={auxContracts}
              />
            </CardNFT>
            {isOwner && <CardNFT title="List Request">
              <TableRequest
                key={keyTableRequestNFT}
                id={id}
                onUpdate={handleRequestNFT}
                getItems={() => getItems(id)}
              />
            </CardNFT>}
          </Grid>
        </Flex >
      </DarkMode>

    </>
  );
}

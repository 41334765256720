/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Heading, Center, Image } from "@chakra-ui/react";
import himg from "../../assets/images/banners/banner04.jpg";

import { PageContainer } from "../../components/PageContainer";
import XswapWidget from "../../components/Xswap";
//bgImg={p2pbg}

export default function Xswap() {
  return (
    <>
      <div>
        <Image
          loading="lazy"
          src={himg}
          h={{ base: 90, sm: 110, md: 120, lg: 180 }}
          w="100%"
          alt="Vsion Token"
          objectFit="cover"
        />
      </div>
      <Box
        h="100vh"
        p="5"
        backgroundSize="cover"
        background="linear-gradient(97.53deg, #0f071d 5.6%, #0f071d 59.16%, #af117d 200.34%)"
        backgroundPosition="top left"
        className="text-center"
      >
        <PageContainer pt={{ base: 8, md: "1rem" }} pb={{ base: 0, md: "7.5rem" }}>
          <Center>
            <Heading
              fontSize={{ base: "4xl", xl: "6xl" }}
              lineHeight="1em"
              letterSpacing="-0.05em"
              color="whiteAlpha.900"
              className="text-center center"
              mb={5}
            >
              Vsion Trade instantly
              <br />
            </Heading>
          </Center>

          <XswapWidget variante="expandable" subvariante="split" />
        </PageContainer>
      </Box>
    </>
  );
}

import { PropsWithRef, useContext } from 'react';
import { Web3Button, useContractWrite } from '@thirdweb-dev/react';
import { BigNumber } from 'ethers';
import { LotteryContext } from '../contextLottery';

// ABI
import AbiLottery from '../../../abi/Lottery.abi.json';
interface TPropsCloseLotteryButton {
  lotteryId: BigNumber
  autoInjection?: boolean
}

const MakeLotteryClaimableButton = (props: PropsWithRef<TPropsCloseLotteryButton>) => {
  const { lotteryId, autoInjection } = props
  const { lotteryContract, lotteryAddress, viewLottery, sucessToast, errorToast } = useContext(LotteryContext)
  const { mutateAsync: drawFinalNumberAndMakeLotteryClaimable } = useContractWrite(
    lotteryContract,
    "drawFinalNumberAndMakeLotteryClaimable",
  );


  async function handleMakeLotteryClaimable() {
    try {
      const res = await drawFinalNumberAndMakeLotteryClaimable(
        {
          args: [
            lotteryId,
            autoInjection || false
          ],
          overrides: {
            gasLimit: 600000, // override default gas limit
          },
        }
      )
      sucessToast(res.receipt.transactionHash)
    } catch (err: any) {
      errorToast(err.reason || "error")
    }
  }
  return (
    <div>
      <Web3Button
        contractAddress={lotteryAddress}
        contractAbi={AbiLottery}
        action={() => handleMakeLotteryClaimable()}
        isDisabled={viewLottery.status != 2}
        style={{ backgroundColor: viewLottery.status == 2 ? 'greenyellow' : 'gray' }}
      >
        Make Claimable
      </Web3Button>
    </div>
  );
};

export default MakeLotteryClaimableButton;

import { collection, addDoc, query, where, getDocs, Timestamp, doc, deleteDoc } from "firebase/firestore";
import { db } from "../client";

const nameCollection = "ticketLottery"

export interface TTicketLottery {
  buyer: string,
  lotteryId: number,
  tokenId: number,
  firstTikectsId: number,
  numberTickets: number
}


export const addTicket = async (request: TTicketLottery) => {
  const q = query(
    collection(db, nameCollection),
    where("buyer", "==", request.buyer),
    where("lotteryId", "==", request.lotteryId),
    where("firstTikectsId", "==", request.firstTikectsId),
  );

  const querySnapshot = await getDocs(q)
  if (querySnapshot.empty) {
    addDoc(collection(db, nameCollection), {
      ...request,
      updateAt: Timestamp.fromDate(new Date()),
      createAt: Timestamp.fromDate(new Date())
    });
  }


}

export const getAllTicketByAddress = async (buyer: string) => {

  const q = query(
    collection(db, nameCollection),
    where("buyer", "==", buyer),
  );

  const querySnapshot = await getDocs(q);
  let res: any[] = []
  querySnapshot.forEach((doc) => {
    res.push({ id: doc.id, ...doc.data() })
  });
  return res
}
export const getLotteryIdByAddress = async (buyer: string) => {

  const q = query(
    collection(db, nameCollection),
    where("buyer", "==", buyer),
  );

  const querySnapshot = await getDocs(q);
  let res: any[] = []
  querySnapshot.forEach((doc) => {
    res.push({ id: doc.id, ...doc.data() })
  });
  return res
}

export const getAllTicketByAddressAndLotteryId = async (buyer: string, lotteryId: number) => {

  const q = query(
    collection(db, nameCollection),
    where("buyer", "==", buyer),
    where("lotteryId", "==", lotteryId),
  );

  const querySnapshot = await getDocs(q);
  let res: any[] = []
  querySnapshot.forEach((doc) => {
    res.push({ id: doc.id, ...doc.data() })
  });
  return res
}

export const deleteTicket = async (uid: string) => {
  await deleteDoc(doc(db, nameCollection, uid));
}

import "./chart.css";
const Book = () => {
  // Obtén el elemento del iframe por su ID
  const iframe: any = document.getElementById("idbook");

  // Verifica si se encontró el iframe
  if (iframe) {
    // Accede al contenido del iframe
    const iframeDocument = iframe?.contentDocument || iframe?.contentWindow.document;

    // Obtén el elemento con el enlace dentro del iframe
    const linkElement = iframeDocument.querySelector('a[href="/bsc/0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3"]');

    // Verifica si el elemento contiene el texto "Tracked by" dentro del iframe
    if (linkElement && linkElement.innerText.includes("Tracked by")) {
      // Elimina el nodo completo dentro del iframe
      linkElement.remove();
    }
  }

  return (
    <>
      <div id="dexscreener-embed">
        <iframe
          id="idbook"
          src="https://dexscreener.com/bsc/0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3?embed=1&theme=light&info=1"
        ></iframe>
      </div>
    </>
  );
};

export default Book;

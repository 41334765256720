import React, { PropsWithoutRef, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tooltip,
  Button,
  Tr,
  Th,
  Td,
  Modal,
  DarkMode,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  InputRightAddon,
  InputGroup,
  Input,
  useDisclosure,
  Text,
  ModalFooter,
  Grid,
  useMediaQuery,
  Flex
} from "@chakra-ui/react";

import AbiERC20 from "../../../../abi/GenericERC20.json";
import AbiDapMarket from "../../../../abi/DapMarket.abi.json";

import { getAllOnSale, addOnSale } from "../../../../firebase/services/onSaleNFT";
import {
  SmartContract,
  ValidContractInstance,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite,
  useSDK
} from "@thirdweb-dev/react";
import { parseUnits } from "ethers/lib/utils";
import LinkBinance from "../../../../components/nft/link-binance";
import { useToast } from "@chakra-ui/react";
import { BaseContract, BigNumber } from "ethers";
import { TAuxContracts } from "./useTokenAux";

import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BiEdit, BiLogoTelegram } from "react-icons/bi";
import { breakpoints } from "../../../../theme/theme";
import ModalCustom from "./ModalCustom";
import FormOffer from "./FormRequestNFT/FormOffer";

const schema = yup.object().shape({
  amount: yup.number().integer().min(1).required()
});

interface TUsers {
  [clave: string]: string | number;
  address: string;
  tokenId: string;
  amount: number;
  unitPrice: string;
  tokenAuxId: string;
}

interface TTableRequest {
  id: string;
  onUpdate: () => void;
  auxContracts: TAuxContracts[]
}

const columnsMobile = [
  { name: "ADDRESS", uid: "address" },
  { name: "AMOUNT", uid: "amount" },
  { name: "ACTIONS", uid: "actions" }
];

const columnsDesk = [
  { name: "ADDRESS", uid: "address" },
  { name: "AMOUNT", uid: "amount" },
  { name: "UNIT PRICE", uid: "unitPrice" },
  { name: "ACTIONS", uid: "actions" }
];
const dapMarketAddress = "0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792";

export default function TableOffer(props: PropsWithoutRef<TTableRequest>) {
  const [isLargerThanmd] = useMediaQuery(`(min-width: ${breakpoints["md"]})`, { ssr: false });
  const { id, onUpdate, auxContracts } = props;

  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo(() => {
    return isLargerThanmd ? columnsDesk : columnsMobile;
  }, [isLargerThanmd]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [maxBuy, setMaxBuy] = useState<number>(0);
  const [items, setOfferRequest] = useState<any[]>([]);
  const [itemsSelected, setOfferRequestSelected] = useState<TUsers>({
    address: "",
    amount: 0,
    tokenAuxId: "",
    tokenId: "",
    unitPrice: "0"
  });

  const address = useAddress();

  const toast = useToast();
  const sdk = useSDK();

  const { contract: contractDapMarket } = useContract(dapMarketAddress, AbiDapMarket);

  const { mutateAsync: buyNFTOnOffer } = useContractWrite(contractDapMarket, "buyNFTOnOffer");
  const { mutateAsync: buyNFTOnOfferBNB } = useContractWrite(contractDapMarket, "buyNFTOnOfferBNB");

  const { data: Fee = BigNumber.from(0) } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "feePriceOfOffer",
    unknown[],
    BigNumber
  >(contractDapMarket, "feePriceOfOffer");

  const getOfferItems = useCallback(() => {
    getAllOnSale(id).then((data: any[]) => {
      setOfferRequest(data);
    });
  }, [id]);

  const handleClickApprove = useCallback(
    (item: TUsers) => {
      onOpen();
      setOfferRequestSelected(item);
      setMaxBuy(item.amount)
    },
    [buyNFTOnOffer, Fee, onUpdate]
  );

  const renderCell = React.useCallback(
    (item: TUsers, columnKey: keyof TUsers | React.Key) => {
      let cellValue: any = "";
      if (columnKey in item) {
        cellValue = item[columnKey];
      }

      switch (columnKey) {
        case "address":
          return <LinkBinance
            option="address"
            transaction={cellValue}
            text={cellValue == address ? "Me" : undefined}
          />;
        case "unitPrice":
          const itemToken = auxContracts.find((aux) => aux.idAux == item.tokenAuxId);
          return `${cellValue} ${itemToken ? itemToken.symbol : "---"}`;

        case "actions":
          return (
            <Flex gap={2} justifyContent={'space-evenly'}>
              {item?.address && address
                && item.address != address
                && (<>
                  <Tooltip color="primary" label="Buy">
                    <Button
                      onClick={() => handleClickApprove(item)}
                      cursor={'pointer'}
                    >
                      Buy
                    </Button>
                  </Tooltip>
                </>)}

              {item?.address && address
                && item.address == address
                && (<>
                  <ModalCustom
                    childrenButton={(<BiEdit />)}
                    tooltipLabel="Editar"
                    titleModal="Update offer"
                  >
                    <FormOffer
                      auxContracts={auxContracts}
                      onUpdate={onUpdate}
                      tokenId={id}
                      amountInit={item.amount}
                      tokenAuxIdInit={item.tokenAuxId}
                      unitPriceInit={item.unitPrice}
                    />
                  </ModalCustom>
                </>
                )
              }
            </Flex>
          );
        default:
          return <>{cellValue}</>;
      }
    },
    [handleClickApprove, auxContracts]
  );

  const onSubmitHandler = async (data: any) => {
    try {
      // Realizar acciones de envío del formulario aquí
      try {
        setIsLoading(true);
        const amountCurrency = data.amount;
        if (itemsSelected.tokenAuxId == "0") {
          const totalPrice = parseUnits(itemsSelected.unitPrice.toString(), 18).mul(amountCurrency);

          await buyNFTOnOfferBNB({
            args: [itemsSelected.address, itemsSelected.tokenId, amountCurrency],
            overrides: {
              value: Fee.mul(amountCurrency).add(totalPrice)
            }
          });
        } else if (sdk && itemsSelected) {
          const addrAux = await contractDapMarket?.call("auxTokens", [itemsSelected.tokenAuxId]);
          const contractCurrency = await sdk.getContract(addrAux, AbiERC20);
          const decimals = await contractCurrency.call("decimals");

          const allowance = await contractCurrency.call("allowance", [address, dapMarketAddress]);
          const totalPrice = parseUnits(itemsSelected.unitPrice.toString(), decimals).mul(amountCurrency);

          if (totalPrice.gt(allowance)) {
            const res = await contractCurrency.call("approve", [dapMarketAddress, totalPrice]);

            toast({
              title: "Success tx.",
              description: (
                <LinkBinance
                  className="text-blue-900"
                  textPrev="Transaction complete"
                  transaction={res.receipt.transactionHash}
                />
              ),
              status: "success",
              duration: 9000,
              isClosable: true
            });
          }

          if (Fee.gt(0)) {
            const res = await buyNFTOnOffer({
              args: [itemsSelected.address, itemsSelected.tokenId, amountCurrency],
              overrides: {
                value: Fee.mul(amountCurrency)
              }
            });
            toast({
              title: "Success tx.",
              description: (
                <LinkBinance
                  className="text-blue-900"
                  textPrev="Transaction complete"
                  transaction={res.receipt.transactionHash}
                />
              ),
              status: "success",
              duration: 9000,
              isClosable: true
            });
          }
          const reqBuy = {
            address: itemsSelected.address,
            tokenId: itemsSelected.tokenId,
            amount: itemsSelected.amount - amountCurrency,
            unitPrice: itemsSelected.unitPrice,
            tokenAuxId: itemsSelected.tokenAuxId
          };
          await addOnSale(reqBuy);
          onUpdate();
          onClose();
        }
      } catch (error: any) {
        toast({
          title: "Error tx.",
          description: error.reason,
          status: "error",
          duration: 9000,
          isClosable: true
        });
      } finally {
        setIsLoading(false);
        onClose();
      }
    } catch (error: any) {
      toast({
        title: "Success tx.",
        description: error.reason,
        status: "success",
        duration: 9000,
        isClosable: true
      });
    }
    reset();
  };

  const formRef = useRef<HTMLFormElement>(null);

  const enviarFormularioDesdeFuera = () => {
    if (formRef?.current) {
      formRef.current.requestSubmit();
    }
  };

  async function handleInputBuy(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(ev.target.value || '1').toFixed(0);
    const newValue =
      maxBuy < Number(value)
        ? maxBuy.toString()
        : Number(value) > 0
          ? value
          : "0";
    ev.target.value = newValue;
    setValue("amount", Number(newValue));
  }

  useEffect(() => {
    getOfferItems();
  }, [getOfferItems]);

  return (
    <>
      <Table colorScheme="purple" variant="striped" aria-label="Example table with custom cells">
        <Thead bgColor="purple.900">
          <Tr>
            {columns.map((column: { name: string; uid: string }) => (
              <Th key={column.uid} textAlign={column.uid === "actions" ? "center" : "start"}>
                {column.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {items.length > 0 &&
            items.map((item: TUsers) => (
              <Tr key={item.id}>
                {columns.map((columnKey: { name: string; uid: string }) => (
                  <Td>{renderCell(item, columnKey.uid)}</Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} colorScheme="gray">
        <DarkMode>
          <ModalOverlay />
          <ModalContent bgColor={"gray.850"}>
            <ModalHeader></ModalHeader>
            <ModalBody>
              <form ref={formRef} className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid>
                  <Text textColor={"white"}>
                    <b>Max DAP on sale:</b> {itemsSelected.amount}
                  </Text>
                  <Text textColor={"white"}>
                    <b>Unit price:</b> {itemsSelected.unitPrice}
                  </Text>
                  <Text textColor={"white"}>
                    <b>Fee:</b> {itemsSelected.unitPrice}
                  </Text>
                </Grid>
                <br />
                <Text textColor={"white"} fontWeight={"semibold"}>
                  Amount
                </Text>
                <InputGroup size="sm">
                  <Input
                    {...register("amount")}
                    placeContent="outside"
                    onChange={handleInputBuy}
                    placeholder="Enter amount than buy"
                    type="phone"
                    variant="bordered"
                  />
                  <InputRightAddon>
                    <BiLogoTelegram className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  </InputRightAddon>
                </InputGroup>
                {typeof errors.amount?.message == "string" && <Text color={"red.400"}>{errors.amount.message} </Text>}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={isLoading} colorScheme="blue" onClick={enviarFormularioDesdeFuera}>
                Buy
              </Button>
            </ModalFooter>
          </ModalContent>
        </DarkMode>
      </Modal>
    </>
  );
}
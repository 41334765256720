import {
  Button,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, {
  PropsWithoutRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SmartContract,
  ValidContractInstance,
  useAddress, useContract, useContractRead, useContractWrite,
} from "@thirdweb-dev/react";

// ABI
import AbiDapNft from '../../../../../abi/DapNft.abi.json';
// import AbiVSION from '../../../../../abi/GenericERC20.json';
import AbiDapMarket from '../../../../../abi/DapMarket.abi.json';

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { TOnSaleNft, addOnSale } from "../../../../../firebase/services/onSaleNFT";
import { getAddress, parseUnits } from "ethers/lib/utils";
import { BaseContract, BigNumber } from "ethers";
import { TAuxContracts } from "../useTokenAux";

interface TFormOffer {
  tokenId: string;
  auxContracts: TAuxContracts[];
  onUpdate?: () => void;
  amountInit?: number;
  unitPriceInit?: string;
  tokenAuxIdInit?: string;
}

const schema = yup.object().shape({
  amount: yup.number().required(),
  unitPrice: yup.string().required().matches(/^\d*\.?\d+$/, 'Should be a number'),
  tokenAuxId: yup.string().required().matches(/^[0-9]+$/, 'Should be a correct option'),
});

const dapMarketAddress = getAddress('0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792');
const dapAddress = getAddress('0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b');

const FormOffer = (props: PropsWithoutRef<TFormOffer>) => {
  const {
    tokenId,
    onUpdate,
    amountInit,
    unitPriceInit,
    tokenAuxIdInit,
    auxContracts
  } = props;

  const { contract: contractDap } = useContract(dapAddress, AbiDapNft)
  const { contract: contractDapMarket } = useContract(dapMarketAddress, AbiDapMarket)

  const { onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false)
  const address = useAddress();
  const toast = useToast()

  const { data: FeeOffer = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "feePriceOfOffer", unknown[], BigNumber>
    (contractDapMarket, 'feePriceOfOffer')

  const { data: yourBalanceNFT = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "balanceOf", (string | undefined)[], BigNumber>
    (contractDap, 'balanceOf', [address, tokenId])

  const { data: isApprovedForAll = false } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "isApprovedForAll", (string | undefined)[], boolean>
    (contractDap, 'isApprovedForAll', [address, dapMarketAddress])


  const { mutateAsync: putOnOffer } = useContractWrite(contractDapMarket, "putOnOffer");
  const { mutateAsync: setApprovalForAll } = useContractWrite(contractDap, "setApprovalForAll");


  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const maxOnSale = useMemo(
    () => (yourBalanceNFT),
    [yourBalanceNFT]
  );
  useEffect(() => {
    amountInit && setValue("amount", amountInit)
    unitPriceInit && setValue("unitPrice", unitPriceInit)
    tokenAuxIdInit && setValue("tokenAuxId", tokenAuxIdInit)
  }, [amountInit, unitPriceInit, tokenAuxIdInit])

  async function handleInputOnSale(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(ev.target.value || '1').toFixed(0);
    const newValue =
      maxOnSale.lt(value)
        ? maxOnSale.toString()
        : Number(value) > 0
          ? value
          : "0";
    ev.target.value = newValue;
    setValue("amount", Number(newValue));
  }

  const onSubmitHandler = async (data: any) => {
    try {
      setIsLoading(true)
      const amount = data.amount;
      const unitPrice = data.unitPrice;
      const tokenAuxId = data.tokenAuxId;
      const auxToken = auxContracts.find(({ idAux }) => idAux === tokenAuxId);

      if (!isApprovedForAll) {
        await setApprovalForAll({ args: [dapMarketAddress, true] })
      }

      if (auxToken) {
        const res = await putOnOffer({
          args: [
            tokenId,
            parseUnits(unitPrice, auxToken?.decimal),
            amount,
            tokenAuxId,
          ],
          overrides: {
            value: FeeOffer.mul(amount),
            gasLimit: 200000
          }
        });
        console.log(res)

        const formInfoRequest: TOnSaleNft = {
          tokenId: tokenId,
          address: address || getAddress("0"),
          amount,
          unitPrice,
          tokenAuxId,
        };

        await addOnSale(formInfoRequest);
        onUpdate && onUpdate()
        onClose();
      }
    } catch (error: any) {
      toast({
        title: 'error tx.',
        description: error.reason,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setIsLoading(false)
      reset();
    }
  };


  return (
    <>
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Text textColor="white" mb={2}> Amount NFT</Text>
        <Input
          {...register("amount")}
          bgColor={'whiteAlpha.800'}
          onChange={handleInputOnSale}
          type="number"
          _placeholder={{ textColor: '#555' }}
          placeContent="outside"
          placeholder="Enter amount NFT buy"
          textColor={'blackAlpha.800'}
        />
        {typeof errors.amount?.message == 'string' && (
          < Text color={'red.400'}>{errors.amount.message} </Text>)
        }

        <Text textColor="white" mt={4} mb={2}>Unit price</Text>
        <Input
          bgColor={'whiteAlpha.800'}
          textColor={'blackAlpha.800'}
          placeContent="outside"
          placeholder="Enter amount NFT buy"
          _placeholder={{ textColor: '#555' }}
          {...register("unitPrice")}
        />
        {typeof errors.unitPrice?.message == 'string' && (
          < Text color={'red.400'}>{errors.unitPrice.message} </Text>)
        }
        <Text textColor="white" mt={4} mb={2}> Currency Allow</Text>
        <Select
          bgColor={'whiteAlpha.800'}
          textColor={'blackAlpha.800'}
          className="w-[10rem]"
          variant="underlined"
          placeContent="outside"
          aria-label="code country"
          {...register("tokenAuxId")}
          defaultValue={'undefined'}
        >
          <option
            value={'undefined'}
            disabled
          >
            --- Select token ---
          </option>
          {auxContracts
            .filter(({ symbol }) => ['VSION'].includes(symbol))
            .map((data) => (
              <option
                aria-label={`${data.symbol} ${data.idAux}`}
                key={data.idAux}
                value={data.idAux}
              >
                {data.symbol}
              </option>
            ))}
        </Select>
        <br />

        <Button isLoading={isLoading} colorScheme='blue' type="submit">
          Put on sale
        </Button>
      </form>
    </>
  );
};

export default FormOffer;

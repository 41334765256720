import { GridItem, Stack, Grid, Image } from "@chakra-ui/react";

import XswapWidget from "../../components/Xswap";
import { TokenBalances } from "@covalenthq/web3-components";
import { useAddress } from "@thirdweb-dev/react";
import NFTComponent from "../../components/NFTComponent";
import himg from "../../assets/images/banners/banner01.jpg";
import { IframeComponent } from "../../components/IframeComponent";

export default function Xwallet() {
  const address = useAddress();
  //  "contain" | "cover" | "fill" | "none" | "scale-down";

  return (
    <>
      <div>
        <Image
          loading="lazy"
          src={himg}
          h={{ base: 90, sm: 110, md: 120, lg: 180 }}
          w="100%"
          alt="Vsion Token"
          objectFit="cover"
        />
      </div>
      <Stack alignItems="flex-start" p={2} spacing={4} mx="auto" direction={{ base: "column", xl: "row" }}>
        <Stack spacing={4} flex="1 1" width="full">
          <h3 className="titl">Info Vsion Token</h3>
          <div>
            <div id="dexscreener-embed">
              <iframe src="https://dexscreener.com/bsc/0xBADd8eEB7b4D12c8CaD00bC856BaA79874Fa814E?embed=1&theme=dark&trades=0&info=0"></iframe>
            </div>
          </div>
          <Stack spacing={4} display={{ base: "none", md: "flex" }}>
            <h3>My Assets</h3>
            <div className="TokenBalances">
              <TokenBalances nft={true} address={address} chainId="56" />
            </div>
          </Stack>
        </Stack>
        <Stack flex="1 1 0%" width="full" maxWidth={{ base: "full", xl: "sm" }} spacing={4}>
          <br />
          <div className="tpendis">
            {" "}
            <NFTComponent />
          </div>
          <div>
            {" "}
            <h3>Swap /Bridge</h3>
            <br />
            <XswapWidget variante="default" subvariante="default" />
          </div>
        </Stack>
      </Stack>
    </>
  );
}

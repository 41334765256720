export const Production = [
  {
    id: 6,
    from: "VSION",
    to: "VSION",
    start: "25147840",
    end: "30331840",
    status: "finished",
    address: "0xB02F33FDDBBBB506104A11Acb45369d2EDAE948A",
    // address: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',//local
    type: "flex",
    abi: "v1",
    maxAmount: 200000,
    tags: [
      {
        tag: "Flexible",
        css: "green"
      }
    ]
  },
  {
    id: 2,
    from: "VSION",
    to: "VSION",
    start: "23966850",
    end: "34478850",
    status: "finished",
    address: "0xE332e26EF53C30e664cf172bc5970C28fD596d18",
    // address: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',//local
    type: "block",
    reward: 57077625,
    time: 365,
    sctime: 7,
    tags: [
      {
        tag: "1 Year",
        css: "green"
      },
      {
        tag: "Locked",
        css: "blue"
      }
    ]
  },
  {
    id: 3,
    from: "VSION",
    to: "VSION",
    start: "23966850",
    end: "44990850",
    status: "live",
    address: "0x6F325cb1808Cdd9cB9Ff00Cb817cd9D63905FA81",
    // address: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',//local
    type: "block",
    reward: 71347031,
    time: 730,
    sctime: 8,
    tags: [
      {
        tag: "1 Years",
        css: "green"
      },
      {
        tag: "Locked",
        css: "blue"
      }
    ]
  },
  {
    id: 4,
    from: "VSION",
    to: "VSION",
    start: "23966850",
    end: "55502850",
    status: "live",
    address: "0xD6983c770877F7D2f36901eFcF068903D94d3051",
    // address: '0x0165878A594ca255338adfa4d48449f69242Eb8F',//local
    type: "block",
    reward: 110984271,
    time: 1095,
    sctime: 9,
    tags: [
      {
        tag: "2 Years",
        css: "green"
      },
      {
        tag: "Locked",
        css: "blue"
      }
    ]
  },
  {
    id: 5,
    from: "VSION",
    to: "VSION",
    start: "23966850",
    end: "76526850",
    status: "live",
    address: "0x5350c8675d1F6343841E11CBe66Fff3598206024",
    type: "block",
    reward: 152207001,
    time: 1825,
    sctime: 11,
    tags: [
      {
        tag: "4 Years",
        css: "green"
      },
      {
        tag: "Locked",
        css: "blue"
      }
    ]
  },
  {
    id: 1,
    from: "VSION",
    to: "VSION",
    start: "0",
    end: "0",
    status: "finished",
    address: "0xb1344458eB4335BBDE7FA70a99DFba95c7246C36",
    type: "flex",
    abi: "v1",
    tags: [
      {
        tag: "Flexible",
        css: "green"
      }
    ]
  }
];

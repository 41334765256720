import CardPrediccion from "./CardPrediccion";
import { SmartContract, ValidContractInstance, useContract, useContractRead } from "@thirdweb-dev/react";
import { BaseContract, BigNumber } from "ethers";
import PredictionAbi from "../../abi/Prediction.abi.json";
import { Box, Flex, Image } from "@chakra-ui/react";
import { useEffect } from "react";
import himg from "../../assets/images/bg/bannerOnw.png";
// const aggregatorAddr = '0x5FbDB2315678afecb367f032d93F642f64180aa3';
const predictionAddr = "0xA0a19172c266E51Ddd0fF5db1ce2d0Bb704b9dbc";
import bgLotto from "../../assets/images/bg/bgtradingb.jpg";
export default function Xpredictions() {
  const { contract: predictionContract } = useContract(predictionAddr, PredictionAbi);
  const { data: currentEpoch = BigNumber.from(0), refetch } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "currentEpoch",
    unknown[],
    BigNumber
  >(predictionContract, "currentEpoch");

  useEffect(() => {
    refetch();
    const interval = setInterval(() => {
      refetch();
    }, 10000);

    return () => clearInterval(interval);
  });
  return (
    <>
      <div>
        <Image
          loading="lazy"
          src={himg}
          h={{ base: 90, sm: 110, md: 120, lg: 180 }}
          w="100%"
          alt="Vsion Token"
          objectFit="cover"
        />
      </div>{" "}
      <Box pt={35} bgImg={bgLotto} className="bg-trade">
        <Flex alignItems={"center"} justifyContent={"center"} gap={8} width={"full"}>
          <CardPrediccion epoch={currentEpoch.sub(2).toString()} />
          <CardPrediccion epoch={currentEpoch.sub(1).toString()} />
          <CardPrediccion epoch={currentEpoch.toString()} />
        </Flex>
      </Box>
    </>
  );
}

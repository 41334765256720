import { SmartContract, ValidContractInstance, useContract, useContractRead, useSDK } from "@thirdweb-dev/react";
import { BaseContract, BigNumber } from "ethers";
import { useEffect, useState } from "react";

import AbiDapMarket from '../../../../abi/DapMarket.abi.json';
import AbiERC20 from '../../../../abi/GenericERC20.json';
import { getAddress } from "ethers/lib/utils";
export interface TAuxContracts {
  idAux: string,
  address: string,
  decimal: number,
  symbol: string,
}
const dapMarketAddress = getAddress('0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792');

export default function useTokenAux() {
  const sdk = useSDK();

  const [auxContracts, setAuxContracts] = useState<TAuxContracts[]>([])

  const { contract: contractDapMarket } = useContract(dapMarketAddress, AbiDapMarket)

  const { data: nextAuxTokensId = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "nextAuxTokensId", unknown[], BigNumber>
    (contractDapMarket, 'nextAuxTokensId')


  const setOptionAuxToken = async (nextAuxTokensId: number) => {
    let auxTokens: TAuxContracts[] = [{
      idAux: "0",
      address: "0x0",
      decimal: 18,
      symbol: "BNB"
    }]
    for (let i = 1; i < nextAuxTokensId; i++) {
      const addrAux = await contractDapMarket?.call('auxTokens', [i])

      if (sdk && typeof addrAux == 'string' && addrAux != '0x0000000000000000000000000000000000000000') {
        const contract = await sdk.getContract(addrAux, AbiERC20);

        const decimals = await contract.call(
          "decimals"
        );

        const symbol = await contract.call(
          "symbol"
        );
        const item: TAuxContracts = {
          idAux: i.toString(),
          address: addrAux,
          decimal: decimals,
          symbol
        }
        auxTokens = [...auxTokens, item]

      }
    }

    setAuxContracts(auxTokens)


  }
  useEffect(() => {
    if (nextAuxTokensId.gt(0)) {
      setOptionAuxToken(nextAuxTokensId.toNumber())
    }
  }, [nextAuxTokensId])
  return {
    auxContracts,

  }
}

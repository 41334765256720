export function Usdc(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6504 24 24 18.6504 24 12C24 5.3496 18.6504 0 12 0C5.34959 0 0 5.3496 0 12C0 18.6504 5.34959 24 12 24Z"
        fill="#2775CA"
      />
      <path
        d="M15.3009 13.9001C15.3009 12.1505 14.2509 11.5505 12.1509 11.2997C10.6509 11.0993 10.3509 10.6997 10.3509 10.0001C10.3509 9.30049 10.8513 8.85049 11.8509 8.85049C12.7509 8.85049 13.2513 9.15049 13.5009 9.90049C13.5513 10.0505 13.7013 10.1501 13.8513 10.1501H14.6517C14.8521 10.1501 15.0021 10.0001 15.0021 9.79969V9.74929C14.8017 8.64889 13.9017 7.79929 12.7521 7.69969V6.4997C12.7521 6.2993 12.6021 6.1493 12.3525 6.1001H11.6025C11.4021 6.1001 11.2521 6.2501 11.2029 6.4997V7.64929C9.70294 7.84969 8.75254 8.84929 8.75254 10.0997C8.75254 11.7497 9.75214 12.4001 11.8521 12.6497C13.2525 12.8993 13.7025 13.1993 13.7025 13.9997C13.7025 14.8001 13.0029 15.3497 12.0525 15.3497C10.7529 15.3497 10.3029 14.8001 10.1529 14.0501C10.1025 13.8497 9.95254 13.7501 9.80254 13.7501H8.95294C8.75254 13.7501 8.60254 13.9001 8.60254 14.1005V14.1509C8.80294 15.4013 9.60214 16.3013 11.2521 16.5509V17.7509C11.2521 17.9513 11.4021 18.1013 11.6517 18.1505H12.4017C12.6021 18.1505 12.7521 18.0005 12.8013 17.7509V16.5509C14.3001 16.3001 15.3009 15.2501 15.3009 13.9001Z"
        fill="white"
      />
      <path
        d="M9.45025 19.1502C5.55029 17.7498 3.54992 13.3998 5.0007 9.5502C5.75069 7.45019 7.40067 5.85058 9.45025 5.10058C9.65064 5.00098 9.75024 4.85098 9.75024 4.60018V3.90058C9.75024 3.70018 9.65064 3.55018 9.45025 3.50098C9.39985 3.50098 9.30025 3.50098 9.24985 3.55138C4.50031 5.05138 1.89994 10.101 3.39992 14.8518C4.29991 17.6514 6.45028 19.8018 9.24985 20.7018C9.45025 20.8014 9.64944 20.7018 9.69984 20.5014C9.75024 20.451 9.75024 20.4018 9.75024 20.301V19.6014C9.75024 19.4502 9.60024 19.251 9.45025 19.1502ZM14.7494 3.55018C14.549 3.45058 14.3498 3.55018 14.2994 3.75058C14.249 3.80098 14.249 3.85018 14.249 3.95098V4.65058C14.249 4.85098 14.399 5.05018 14.549 5.15098C18.4489 6.55139 20.4493 10.9014 18.9985 14.751C18.2485 16.851 16.5986 18.4506 14.549 19.2006C14.3486 19.3002 14.249 19.4502 14.249 19.701V20.4006C14.249 20.601 14.3486 20.751 14.549 20.8002C14.5994 20.8002 14.699 20.8002 14.7494 20.7498C19.4989 19.2498 22.0993 14.2002 20.5993 9.4494C19.6993 6.60059 17.4997 4.45018 14.7494 3.55018Z"
        fill="white"
      />
    </svg>
  );
}

import { ConnectWallet, darkTheme } from "@thirdweb-dev/react";

function ConnectButton() {
  return (
    <ConnectWallet
      theme={darkTheme({
        colors: {
          modalBg: "#0f091d",
          dropdownBg: "#0f091d",
          accentText: "#3abcd4",
          danger: "#e52e2e",
          accentButtonBg: "#af127d"
        }
      })}
      className="btn-mtks"
      style={{ color: "#fff", height: "50px", borderRadius: "50px", border: "1px solid #502c92" }}
      switchToActiveChain={true}
      btnTitle={"Connect Wallet"}
      modalSize={"wide"}
      welcomeScreen={{
        img: {
          src: "https://dev.app.vsion.io/static/media/vsn.0498db3414653ae5a4e9.png",
          width: 150,
          height: 150
        },
        title: "Vsion DAP Ecosystem",
        subtitle: " Decentralized Autonomous Partner"
      }}
      modalTitleIconUrl={"https://vsion.io/favicon-32x32.png"}
      termsOfServiceUrl={"https://whitepaper.vsion.io/legal/terms-of-use"}
      privacyPolicyUrl={"https://whitepaper.vsion.io/legal/privacy-policy"}
    />
  );
}
export default ConnectButton;

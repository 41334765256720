import {
  Button,
  Flex,
  Grid,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { PropsWithoutRef, useMemo } from "react";

import Question from "../Question";
import ModalRequest from "./ModalRequest";
import useStateFormRequest from "./useStateFormRequest";

import { formatUnits } from "ethers/lib/utils";
import FormTransfer from "./FormTransfer";
import { TAuxContracts } from "../useTokenAux";
import FormOffer from "./FormOffer";

interface TFormRequestNFt {
  id: string;
  nftInfo: TNFT;
  auxContracts: TAuxContracts[];
  onUpdate?: () => void;
  onRequest?: () => void;
}

interface TNFT {
  id: string;
  description: string;
  title: string;
  image: string;
  price: number;
}

const FormRequestNFT = (props: PropsWithoutRef<TFormRequestNFt>) => {
  const { id, nftInfo, onUpdate, auxContracts } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();

  const {
    supplyNFT,
    maxRequest,
    thisBalanceNFT,
    yourBalanceNFT,
    yourRequest,
    balanceVSION,
    handleInputRequest,
    handleRequestNFTClick,
    infoRequest,
    FeePriceRequest
  } = useStateFormRequest(id, onOpen, onUpdate);

  const maxOnSale = useMemo(() => yourBalanceNFT, [yourBalanceNFT]);

  const isAvailable = useMemo(() => {
    return thisBalanceNFT.gt(0);
  }, [thisBalanceNFT]);

  return (
    <>
      <Flex flexDirection={"column"} gap={8}>
        <Grid gap={4} gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}>
          <span>
            <b>Total supply : </b> {`${supplyNFT || 0} NFT`}
          </span>
          <span>
            <b>Available : </b> {isAvailable ? `${maxRequest || 0} of ${thisBalanceNFT || 0} NFT` : `sold out`}
            <Question body="se toma en cuanta la dispibilidad de los NFT que no se vendieron" />
          </span>
          <span>
            <b> Your Requests : </b> {`${yourRequest || 0} NFT`}
          </span>

          <span>
            <b>Price : </b> {nftInfo.price} VSION
          </span>
          <span>
            <b>Balance : </b> {`${formatUnits(balanceVSION, 8)} VSION`}
          </span>

          <span>
            <b>Fee X NFT : </b> {`${formatUnits(FeePriceRequest, 18)} BNB`}
          </span>
        </Grid>
        <Tabs
          w={"full"}
          size="md"
          aria-label="Tabs form"
          // classNames={{ tab: `${maxOnSale == 0 ? "hidden" : "block"}` }}
        >
          <TabList>
            {maxOnSale.gt(0) && <Tab>Put on sale NFT</Tab>}
            {maxOnSale.gt(0) && <Tab>Transfer my NFT</Tab>}
            {isAvailable && maxRequest > 0 && <Tab>Request NFT</Tab>}
          </TabList>
          <TabPanels>
            {maxOnSale.gt(0) && (
              <TabPanel key="Buy" title="">
                <FormOffer auxContracts={auxContracts} tokenId={id} onUpdate={onUpdate} />
              </TabPanel>
            )}
            {maxOnSale.gt(0) && (
              <TabPanel>
                <FormTransfer id={id} onUpdate={onUpdate} />
              </TabPanel>
            )}
            <FormTransfer id={id} onUpdate={onUpdate} />

            {maxRequest > 0 && (
              <TabPanel key="Request">
                <div className="flex flex-col gap-4">
                  <Text> Amount NFT</Text>
                  <Input
                    type="number"
                    placeContent="outside"
                    placeholder="Enter amount NFT buy"
                    defaultValue={"1"}
                    onChange={handleInputRequest}
                  />
                  <br />
                  <Button colorScheme="blue" mt={4} onClick={() => handleRequestNFTClick()}>
                    Request NFT
                  </Button>
                </div>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Flex>

      <ModalRequest isOpen={isOpen} onRequest={onUpdate} onClose={onClose} infoTx={infoRequest} />
    </>
  );
};

export default FormRequestNFT;

export const Production = [
  {
    id: 1,
    from: 'VSION',
    to: 'BNB',
    earned: '0',
    apr: '29.72%',
    liquidity: '$232,941,720',
    blocks: '19733839',
    address: '0xBADd8eEB7b4D12c8CaD00bC856BaA79874Fa814E',
    pid: 0,
    type: 'locked',
    tags: [
      {
        tag: 'New',
        color: 'green',
      },
      {
        tag: 'Blocked',
        color: 'bg-blue-200',
      },
    ],
  },
];

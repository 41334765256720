import { Card, CardBody, Box, Grid, GridItem, CardHeader, useDisclosure, Button, Text } from "@chakra-ui/react";
import CloseLotteryButton from "../../../components/Lotery/Operatator/CloseLotteryButton";
import StartLotteryButton from "../../../components/Lotery/Operatator/StartLotteryButton";
import MakeLotteryClaimableButton from "../../../components/Lotery/Operatator/MakeLotteryClaimableButton";
import { LotteryContext, LotteryProvider } from "../../../components/Lotery/contextLottery";
import FormGetTickets from "../../../components/Lotery/User/FormGetTickets";
import { useContext, useMemo, useRef } from "react";
import i18next from "i18next";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { useAddress } from "@thirdweb-dev/react";

const formatTime = (date: Date) => i18next.t(' {{val, datetime}}',
  {
    val: date,
    lng: 'en',
    formatParams: {
      val: {
        year: "numeric",
        month: "numeric",
        day: "numeric", date: "", hour: "numeric", minute: "numeric", hour12: true
      }
    }
  });

export default function Vlottery() {
  return <LotteryProvider><LotteryChildren /></LotteryProvider>
}

export function LotteryChildren() {
  const { currentLotteryId, viewLottery, operatorAddress } = useContext(LotteryContext)
  const address = useAddress()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = useRef(null)


  const startTime = useMemo(() => {
    return formatTime(new Date(viewLottery.startTime.mul(1000).toNumber()))
  }, [viewLottery?.startTime])

  const endTime = useMemo(() => {
    return formatTime(new Date(viewLottery.endTime.mul(1000).toNumber()))
  }, [viewLottery?.endTime])

  const relativeEndTime = useMemo(() => {
    return i18next.t('Finished {{val, relativetime(seconds)}}',
      {
        val: viewLottery.endTime.mul(1000).sub(Date.now()).div(1000).toNumber(),
        formatParams: {
          val: { unit: 'year' }
        }
      });
  }, [viewLottery?.endTime])

  const statusLottery = useMemo(() => {
    let status: string = "Pending";
    if (viewLottery.status == 1) {
      status = "Open"
    } else if (viewLottery.status == 2) {
      status = "Close"
    } else if (viewLottery.status == 3) {
      status = "Claimable"
    }
    return status
  }, [viewLottery.status])
  return operatorAddress == address ? (
    <>
      <Box p="5" m="8" className="text-center">
        <Grid templateColumns='repeat(2, 1fr)' gap={8}>
          <GridItem w='100%'  >

            <Card rounded={"3xl"}>

              <CardHeader fontWeight={"bold"}>Operator</CardHeader>
              <CardBody>
                <Button onClick={onOpen}>Open Modal</Button>
                <span>Last Lottery ID: {`${currentLotteryId}`}</span>
                <br />
                <span>{`Status: ${statusLottery} ${viewLottery.status}`}</span>
                <br />
                <span>{`Winner Number: ${viewLottery.finalNumber}`}</span>
                <br />
                <span>{`ID Ticket Start:${viewLottery.firstTicketId}`}</span>
                <br />
                <span>{`ID Ticket End:${viewLottery.firstTicketIdNextLottery}`}</span>
                <br />
                <span>{`Winner Number: ${viewLottery.finalNumber}`}</span>
                <br />
                <span>{`Start Time: ${startTime}`}</span>
                <br />
                <span>{`End Time: ${endTime}`}</span>
                <br />
                <span>{`${relativeEndTime}`}</span>
                <br />
                <StartLotteryButton />
                <br />
                <CloseLotteryButton currentLotteryId={currentLotteryId} />
                <br />
                <Text>AutoInject</Text>
                <MakeLotteryClaimableButton lotteryId={currentLotteryId} autoInjection />
                <br />
                <Text>Enviar a tesoreria</Text>
                <MakeLotteryClaimableButton lotteryId={currentLotteryId} />
              </CardBody>
            </Card>

          </GridItem>
          <GridItem w='100%'  >

            <Card rounded={"3xl"}>

              <CardHeader fontWeight={"bold"}>Tickets ID</CardHeader>
              <CardBody>
                <FormGetTickets />
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </Box>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
    : (
      <>
        <Text>You are not Operator</Text>
      </>
    );
}

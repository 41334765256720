import { Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const CardPrincipal: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Box border={"3px solid #fff"} w="full" rounded={"2xl"} p={"4"} bgColor={"#0f091d"}>
      <div className="center-bull">{children}</div>
    </Box>
  );
};

export default CardPrincipal;

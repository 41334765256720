import React, { useState, useEffect } from 'react';
import { Box, Button, Input, InputGroup, InputLeftElement, HStack, VStack, Text, Heading, SimpleGrid, useToast } from '@chakra-ui/react';
import { FaPiggyBank, FaClock } from 'react-icons/fa';
import StakeCakeCard from './StakeCakeCard';
import { useAddress, useContractRead, useContract, useContractWrite } from "@thirdweb-dev/react";


import ERC20_ABI from "./ERC20_ABI.json";
import ABI from "./ABI.json";
const SC = "0x0ceEd856609f2d0ffF739f1131ed5Bb45E64524c"

// old "0x8d6EB84e50Db9BFA3D8912D86CCA0Cc474747FaE"

const LockCakeCard = () => {
const address = useAddress();
  const [cakeAmount, setCakeAmount] = useState('');
  const [duracion, setDuracion] = useState('FLEXIBLE');
  const [stakeCards, setStakeCards] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [balance, setBalance] = useState(1);
  
  const toast = useToast();
  const { contract } = useContract(SC, ABI);
  const { contract: tokenContract } = useContract("0x5A0AB3c4668c7b0a5A6c86a115c2f0dd320b60Fc", ERC20_ABI);
  const { data: balanceToken } = useContractRead(tokenContract, "balanceOf",[address]);
  const balanceg = Number(balanceToken?.toString())/1e18;


 //const { data: viewT } =  useContractRead(contract, "getTokenPairDetails", [1]);
 const { data: getStakesByUser } =  useContractRead(contract, "getStakesByUser", [address]);



 const { data: allowance } =  useContractRead(tokenContract, "allowance", [address, SC]);


    const { mutateAsync: approve } = useContractWrite(tokenContract, "approve");

  const { mutateAsync: stake } = useContractWrite(contract, "stake");
 const { mutateAsync: withdrawReward } = useContractWrite(contract, "withdrawReward");
 const { mutateAsync: unstake } = useContractWrite(contract, "emergencyUnstake");


  useEffect(() => {
    if (balanceToken?.toString() !== undefined) {
const balanceEfe = Number(balanceToken?.toString())/1e18;
        setBalance(parseFloat(balanceEfe));
  
    }
  });

const toWeiNumber = (number) =>{
  const weiFactor = Math.pow(10, 18);
  return BigInt(number * weiFactor).toString();
}

 
  //setBalance(parseFloat(balance));

  const approveToken = async () => {
    try {
const cakeAmountToken = toWeiNumber(cakeAmount);

      const tx = await approve({ args: [SC, cakeAmountToken] });
      toast({
        title: "Approved",
        description: "Token approved for staking.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to approve token.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePercentageClick = (percentage) => {
    setCakeAmount((balance * percentage / 100).toFixed(2));
  };

  const handleDurationClick = (duration) => {
    setDuracion(duration);
  };

const convertLockTimeToSeconds = (days)=> {
    const secondsInADay = 24 * 60 * 60;
    return days * secondsInADay;
}

  const handleLockClick = async () => {
    try {
     

      if (parseFloat(allowance) < parseFloat(cakeAmount)) {
        await approveToken();
      }

      const cakeAmountToken = toWeiNumber(cakeAmount);

      const flx = (duracion== 'FLEXIBLE') ? 0 : convertLockTimeToSeconds(Number(duracion)); 
      console.log(flx)
    
      const tx = await stake({ args: [1, cakeAmountToken, flx] });
     
      toast({
        title: "Locked",
        description: `${cakeAmount} VSN locked for ${duracion}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });


      setStakeCards([...stakeCards, { cakeAmount, duracion }]);
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Failed to lock VSN.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEnableClick =  async(index) => {
console.log('index',index)


 try {

      const tx = await withdrawReward({ args: [1, index] });
      toast({
        title: "Approved",
        description: "Token withdraw Reward for staking.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to approve token.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

  };


const handleunstakeClick = async(index)=>{

console.log('unstake',index)


 try {

      const tx = await unstake({ args: [1, Number(index)] });
      toast({
        title: "Approved",
        description: "Unstaking",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to approve token.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
}

const handlewithdrawRewardClick = async(index)=>{

console.log('handlewithdrawRewardClick',index)


 try {

      const tx = await withdrawReward({ args: [1, Number(index)] });
      toast({
        title: "Approved",
        description: "Unstaking",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to approve token.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
}

  const handleSaveChanges = () => {
    const updatedCards = stakeCards.map((card, index) =>
      index === selectedCardIndex ? { cakeAmount, duracion } : card
    );
    setStakeCards(updatedCards);
    setSelectedCardIndex(null);
  };

  const handleCakeAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 1 && value <= balance) {
      setCakeAmount(value);
    } else if (value < 1) {
      setCakeAmount(1);
    } else if (value > balance) {
      setCakeAmount(balance);
    }
  };

  return (
    <Box bg="gray.900" p={5} borderRadius="md" color="white" w="100%" mx="auto">
      <Heading as="h3" size="md" mb={4}>Stake VSN to get VSN</Heading>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <Box>
          <Text mb={2}>ADD VSN</Text>
          <VStack spacing={2}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaPiggyBank color="gray.300" />
              </InputLeftElement>
              <Input
                type="number"
                min="1"
                max={balance}
                placeholder="1"
                size="sm"
                variant="filled"
                bg="gray.800"
                value={cakeAmount}
                onChange={handleCakeAmountChange}
              />
            </InputGroup>
            <Text fontSize="sm" color="gray.400">Balance: {Number(balanceToken?.toString())/1e18} VSN</Text>
            <HStack spacing={2}>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handlePercentageClick(25)}>25%</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handlePercentageClick(50)}>50%</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handlePercentageClick(75)}>75%</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handlePercentageClick(100)}>Max</Button>
            </HStack>
          </VStack>
        </Box>

        <Box>
          <Text mb={2}>DURATION</Text>
          <VStack spacing={2}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaClock color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="1"
                size="sm"
                variant="filled"
                bg="gray.800"
                value={duracion}
                onChange={(e) => setDuracion(e.target.value)}
              />
            </InputGroup>
            <Text fontSize="sm" color="gray.400">Weeks</Text>
            <HStack spacing={2}>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handleDurationClick('FLEXIBLE')}>FLEXIBLE</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handleDurationClick('30')}>30D</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handleDurationClick('60')}>60D</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handleDurationClick('120')}>120D</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handleDurationClick('240')}>240D</Button>
              <Button size="sm" variant="outline" borderColor="gray.600" color="teal.400" onClick={() => handleDurationClick('480')}>480D</Button>
            </HStack>
          </VStack>
        </Box>
      </SimpleGrid>
      <Button mt={4} w="100%" colorScheme="teal" onClick={handleLockClick}>
        STAKE
      </Button>
{getStakesByUser && getStakesByUser[1]?.length >0 && getStakesByUser[0]?.length>0 && <>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mt={4}>
        {getStakesByUser[1].map((card, index) => (
          <StakeCakeCard
            key={index}
            cakeAmount={card?.amount?.toString() / 1e18}
            duracion={card?.lockTime?.toString() === '0' ? 'FLEXIBLE' : card?.lockTime.toString() + 'DAYS'}
            onEnableClick={() => handleEnableClick(getStakesByUser[0][index].toString())}
  onwithdrawRewardClick={() => handlewithdrawRewardClick(getStakesByUser[0][index].toString())}
 onwitUnstakeClick={() => handleunstakeClick(getStakesByUser[0][index].toString())}

            isEditing={index === selectedCardIndex}
            onSaveChanges={handleSaveChanges}
            getStakeInfo={card}
            getPoolID={Number(getStakesByUser[0][index].toString())}
          />
        ))}
      </SimpleGrid></>}

    </Box>
  );
};

export default LockCakeCard;

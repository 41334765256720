
import { doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../client";

const nameCollection = "users"

export interface TUser {
  address: string
  email: string
  phone: string
  telegram: string
}

export const addUser = async (user: TUser) => {
  const ref = doc(db, nameCollection, user.address);
  await setDoc(ref, user);
}

export const getUser = async (address: string) => {

  const docRef = doc(db, nameCollection, address);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    return false
  }
}

export const deleteUser = async (uid: string) => {
  await deleteDoc(doc(db, nameCollection, uid));
}


import { collection, addDoc, query, where, getDocs, Timestamp, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../client";

const nameCollection = "requestNft"

export interface TRequestNft {
  tokenId: string
  amount: number
  txHash: string
  address: string
  email: string
  phone: string
  telegram: string
}


export interface TRequestNftTransaccion extends Omit<TRequestNft, 'email' | 'telegram' | 'phone'> { }


export const addRequest = async (request: TRequestNftTransaccion) => {
  const q = query(
    collection(db, nameCollection),
    where("address", "==", request.address),
    where("tokenId", "==", request.tokenId)
  );

  const querySnapshot = await getDocs(q)
  if (!querySnapshot.empty) {
    querySnapshot.forEach((dc) => {
      const data = dc.data()
      updateDoc(doc(db, nameCollection, dc.id), {
        amount: request.amount + data.amount,
        updateAt: Timestamp.fromDate(new Date())
      });
    });
  } else {
    addDoc(collection(db, nameCollection), {
      ...request,
      user: doc(db, "users", request.address),
      updateAt: Timestamp.fromDate(new Date())
    });
  }


}

export const getGroupRequestById = async (tokenId: string) => {

  const q = query(
    collection(db, nameCollection),
    where("tokenId", "==", tokenId)
  );

  const querySnapshot = await getDocs(q);
  let res: any[] = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    res.push({ id: doc.id, ...doc.data() })
  });
  return res
}

export const getAllRequest = async () => {

  const q = query(
    collection(db, nameCollection)
  );

  const querySnapshot = await getDocs(q);
  let res: any[] = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    res.push({ id: doc.id, ...doc.data() })
  });
  return res
}

export const deleteRequest = async (uid: string) => {
  await deleteDoc(doc(db, nameCollection, uid));
}

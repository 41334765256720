import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import AbiLottery from "../../../abi/Lottery.abi.json";
import { LotteryContext } from "../contextLottery";
import { Box } from "@chakra-ui/react";
import { Web3Button, useAddress, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { getAllTicketByAddressAndLotteryId } from "../../../firebase/services/ticketLottery";
import { numberFinalToArr } from "../../../utils/lottery";

interface TPropsClaimPrizes {
  lotteryWinnerId: number;
  winnerNumber: number;
}

export const ClaimPrizes: React.FC<PropsWithChildren<TPropsClaimPrizes>> = (props) => {
  const { lotteryWinnerId, winnerNumber, children } = props;
  const { lotteryContract, lotteryAddress, sucessToast, errorToast } = useContext(LotteryContext);
  const address = useAddress();

  const [arrId, setArrId] = useState([0]);
  const [active, setActive] = useState(false);
  const { data: ticketsArr } = useContractRead(lotteryContract, "viewNumbersAndStatusesForTicketIds", [arrId]);

  const { mutateAsync: claimTickets } = useContractWrite(lotteryContract, "claimTickets");
  const completeArr = (startId: number, amount: number) => {
    const arr = new Array(amount);
    return arr.fill("").map((_, idx) => startId + idx);
  };

  const getWinnerTicketIdAndBrackets = (winnerNumber: number, ids: number[], listTickets: number[]) => {
    const arrWinner = numberFinalToArr(winnerNumber);

    const listTicketsWinners = [];
    const listBracketsWinners = [];
    for (let i = 0; i < ids.length; i++) {
      const arrTicket = numberFinalToArr(listTickets[i]);

      const bracket = arrWinner.findIndex((winner, idx) => {
        return winner !== arrTicket[idx];
      });
      if (bracket != 0) {
        listTicketsWinners.push(ids[i]);
        listBracketsWinners.push(bracket == -1 ? 5 : bracket - 1);
      }
    }
    return [listTicketsWinners, listBracketsWinners];
  };
  const handleClaimPrize = async () => {
    const data = await getAllTicketByAddressAndLotteryId(address || "0", lotteryWinnerId);
    let result: number[] = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      const arrComplete = completeArr(el.firstTikectsId, el.numberTickets);
      result = [...result, ...arrComplete];
    }
    setArrId(result);
    setActive(true);
  };

  useEffect(() => {
    if (active && ticketsArr) {
      const [ticketIds, brackets] = getWinnerTicketIdAndBrackets(winnerNumber, arrId, ticketsArr[0]);
      setActive(false);

      if (ticketIds.length > 0) {
        claimTickets({
          args: [lotteryWinnerId, ticketIds, brackets]
        })
          .then((res) => {
            sucessToast(`Tx: ${res.receipt.transactionHash}\n Tikets Id ${ticketIds.join(" - ")}`, " is a winner");
          })
          .catch((err: any) => {
            errorToast(err.reason || "error");
          });
      } else {
        errorToast("You are not the winner", "Sorry");
      }
    }
  }, [active, ticketsArr]);

  return (
    <div>
      <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
        <Web3Button
          contractAddress={lotteryAddress}
          contractAbi={AbiLottery}
          style={{ minWidth: "50px", backgroundColor: "#A539FB", color: "#fff", border: "1px solid white" }}
          action={() => handleClaimPrize()}
          className="btn-buy-lotto"
        >
          {children || "Claim Prize"}
        </Web3Button>
      </Box>
    </div>
  );
};

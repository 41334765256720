
import { TLotteryContext } from "./type";
import { BigNumber } from "ethers";



import React, { createContext, ReactNode } from 'react';
import { initLottery, initToken, useLoteryCurrenly, useLotteryContract } from './useLottery';


const initLotteryContract: TLotteryContext = {
  operatorAddress: '',
  lotteryAddress: "",
  lotteryOldAddress: "",
  lotteryContract: undefined,
  lotteryOldContract: undefined,
  vsnAddress: "",
  VsnContract: undefined,
  usdtAddress: "",
  UsdtContract: undefined,
  wbnbAddress: "",
  wbnbContract: undefined,
  currentLotteryId: BigNumber.from(0),
  lotteryWinnerId: BigNumber.from(0),
  viewLottery: initLottery,
  viewLotteryWinner: initLottery,
  tokenStorageVSN: initToken,
  tokenStorageUSDT: initToken,
  tokenStorageWBNB: initToken,
  sucessToast: () => { },
  errorToast: () => { },
}

export const LotteryContext = createContext<TLotteryContext>(initLotteryContract);

interface LotteryProviderProps {
  children: ReactNode;
}

export const LotteryProvider: React.FC<LotteryProviderProps> = ({ children }) => {
  const lotteryContract = useLotteryContract()

  const lotteryCurrenty = useLoteryCurrenly()

  return <LotteryContext.Provider value={{ ...lotteryContract, ...lotteryCurrenty }}> {children}</LotteryContext.Provider>;
};








import { collection, addDoc, query, where, getDocs, Timestamp, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../client";

const nameCollection = "onSaleDap"

export interface TOnSaleNft {
  address: string,
  tokenId: string,
  amount: number,
  unitPrice: string,
  tokenAuxId: string
}


export const addOnSale = async (request: TOnSaleNft) => {
  const q = query(
    collection(db, nameCollection),
    where("address", "==", request.address),
    where("tokenId", "==", request.tokenId)
  );

  const querySnapshot = await getDocs(q)
  if (!querySnapshot.empty) {
    querySnapshot.forEach((dc) => {
      updateDoc(doc(db, nameCollection, dc.id), {
        amount: request.amount,
        unitPrice: request.unitPrice,
        tokenAuxId: request.tokenAuxId,
        updateAt: Timestamp.fromDate(new Date())
      });
    });
  } else {
    addDoc(collection(db, nameCollection), {
      ...request,
      updateAt: Timestamp.fromDate(new Date())
    });
  }


}

export const getAllOnSale = async (tokenId: string) => {

  const q = query(
    collection(db, nameCollection),
    where("tokenId", "==", tokenId),
  );

  const querySnapshot = await getDocs(q);
  let res: any[] = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    res.push({ id: doc.id, ...doc.data() })
  });
  return res.filter((({ amount }) => amount > 0))
}

export const deleteOnSale = async (uid: string) => {
  await deleteDoc(doc(db, nameCollection, uid));
}

import { Box, useRadio } from "@chakra-ui/react";

const RadioCard = (props: any) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        textTransform="uppercase"
        _checked={{
          color: "white",
          borderColor: "#af0f7d",
          backgroundColor: "#af0f7d",
          backgroundImage: "linear-gradient(25deg, #af0f7d, #58086c) !important"
        }}
        _focus={{
          boxShadow: "outline",
          borderColor: "#af0f7d"
        }}
        _active={{
          borderColor: "#af0f7d"
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;

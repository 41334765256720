import { Box, Grid, Text } from "@chakra-ui/react";

interface ITableHeader {
  headers: any[];
  templateColumns: any;
  gridColumn?: any;
  gap?: any;
  final?: boolean;
}

const TableHeader = ({ headers, templateColumns, gridColumn, final, gap = 6 }: ITableHeader) => {
  return (
    <Grid templateColumns={templateColumns} alignItems="center" gap={gap} rounded="lg" bg="white">
      {headers.map((header, index) => (
        <Box
          key={index}
          px={{ base: 4, sm: 8 }}
          py={6}
          gridColumn={gridColumn}
          display={{
            base: [headers.length - 1, headers.length - 2].includes(index) && !final ? "none" : "block",
            lg: "block"
          }}
        >
          {typeof header === "string" ? (
            <Text fontSize="sm" letterSpacing={0.8} color="GrayText" >
              {header}
            </Text>
          ) : (
            header
          )}
        </Box>
      ))}
    </Grid>
  );
};

export default TableHeader;

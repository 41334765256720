import { Box, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

// interface TColor {
//   mobileColor: string;
//   desktopColor: string;
// }
interface TBallWinner {
  value: string | number;
  color?: string;
  rotate?: number;
}

const BallWinner: React.FC<TBallWinner> = (props) => {
  const { value, color, rotate } = props;
  const rotateBall = useMemo(() => (rotate ? rotate : Math.trunc(Math.random() * 30) - 10), []);
  return (
    <>
      <Box
        w={{ base: "16", sm: "16", md: "24" }}
        h={{ base: "16", sm: "16", md: "24" }}
        bg={color || "pink.400"}
        className=""
        rounded="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="semibold"
        color="white"
        // background={'radial-gradient(circle at 65% 15%, #ffffffd6 1px, #ffffffa1 3%, #00008b40 60%, aqua 100%);'}
        boxShadow={"inset -25px -15px 40px rgba(43, 43, 43, 0.755)"}
        backgroundImage={"linear-gradient(-45deg, rgba(255, 255, 220, 0.471) 0%, transparent 100%)"}
      >
        <Text transform={`rotate(${rotateBall}deg)`} textDecoration={value == 6 || value == 9 ? "underline " : ""}>
          {value}
        </Text>
      </Box>
    </>
  );
};

export default BallWinner;

import { FormControl, FormLabel, Switch } from "@chakra-ui/react";

const StackedSwitch = () => {
  return (
    <FormControl gap={{ base: 2, sm: 3 }} display="flex" alignItems="center" maxWidth={171} width="100%">
      <Switch size="md" id="stakedOnly" />
      <FormLabel htmlFor="stakedOnly" fontSize="sm" mb="0">
        STAKED ONLY
      </FormLabel>
    </FormControl>
  );
};

export default StackedSwitch;

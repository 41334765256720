import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { GiBearFace, GiBull } from "react-icons/gi";

interface TPropsBet {
  isUpper?: boolean;
  isActive?: boolean;
  mutiplicator: (number | string);
}

const Bet: React.FC<TPropsBet> = (props) => {
  const { isUpper = false, mutiplicator, isActive = false } = props;
  return (
    <Flex
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      border={isUpper ? "3px solid #006a01" : " 3px solid #d41c00"}
      bgColor={isActive ? isUpper ? "#006a01" : "#d41c00" : isUpper ? "#42bb44" : "#cb5b4a"}
      w={"60%"}
      roundedTop={isUpper ? "full" : "md"}
      roundedBottom={isUpper ? "md" : "full"}
    >
      <Text fontSize={"2xl"}>{isUpper ? "UP" : "DOWN"}</Text>
      <Text>{mutiplicator} x Pay</Text>
      <Text fontSize={"4xl"} color={isUpper ? "#fff" : "#fff"}>
        {isUpper ? <GiBull /> : <GiBearFace />}
      </Text>
    </Flex>
  );
};

export default Bet;

import {
  Box,
  Flex,
  Text,
  Button,
  Spacer,
  Option,
  Image,
  Grid,
  GridItem,
  Link,
  Mark,
  Divider,
  CardFooter,
  ButtonGroup,
  Card,
  Stack,
  Heading,
  CardBody,
  SimpleGrid
} from "@chakra-ui/react";
import utilStyles from "./utils.module.css";
import useNFT from "../../../hooks/useNFT";
import ButtonRewardDapIndividual from "../ButtonRewardDapIndividual";
import PostWithLike from "./cardNFT";
const nftID = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const NFTView = ({ id }) => {
  const { nftData, isLoading } = useNFT({ id });
  if (isLoading)
    return (
      <GridItem w="100%" position="relative" borderRadius="20px" display="flex" justifyContent="center">
        {" "}
        <Link color="teal.500" href="https://opensea.io/collection/vsion-dap-protocol" target="_blank">
          <Flex
            display="flex"
            flexDirection="column"
            paddingTop="15px"
            paddingLeft="10px"
            paddingRight="10px"
            justifyContent="space-between"
            className={utilStyles.conteiner}
          >
            {" "}
          </Flex>
        </Link>
      </GridItem>
    );

  if (nftData == null) {
    return <></>;
  }

  return (
    <PostWithLike nftData={nftData} earnButton={<ButtonRewardDapIndividual idNft={nftData?.identifier} />} />

    /*<GridItem
      w="100%"
      h="auto"
      backdropFilter="blur(10px);"
      bg="rgba(255, 255, 255, 0.1)"
      position="relative"
      borderRadius="20px"
      display="flex"
      justifyContent="center"
    >
      <Flex
        display="flex"
        flexDirection="column"
        paddingTop="15px"
        paddingLeft="10px"
        paddingRight="10px"
        justifyContent="space-between"
        className={utilStyles.conteiner}
      >
        <Box className={utilStyles.img}>
          <Link color="teal.500" href={"https://app.vsion.io/dashboard/dap/" + nftData?.identifier}>
            <Image src={nftData?.image} alt="name" w="100%" h="auto" className={utilStyles.imgNFt} objectFit="cover" />
          </Link>
        </Box>
        <Flex justifyContent="space-around" alignItems="center" paddingBottom="20px">
          <Box display="flex" flexDirection="column" className={utilStyles.cont}>
            <Box display="flex" w="100%" flexDirection="row" justifyContent="space-around">
              <Box display="flex" flexDirection="column" justifyContent="start">
                <Text fontSize="24" textColor="white" fontWeight="600">
                  DAP NFT
                </Text>
              </Box>
              <Box>
                <Link color="teal.500" href={"https://app.vsion.io/dashboard/dap/" + nftData?.identifier}>
                  <Text textColor="yellow" fontSize="14px" mt={2} lineHeight="20px" fontWeight="700">
                    View Open sea
                  </Text>{" "}
                </Link>
              </Box>
            </Box>

            <Box alignItems="flex-start">
              {nftData?.have ? (
                <>
                  <ButtonRewardDapIndividual idNft={nftData?.identifier} />
                </>
              ) : (
                <>
                  {" "}
                  <Link color="teal.500" href={"https://app.vsion.io/dashboard/dap/" + nftData?.identifier}>
                    <Button
                      borderRadius="12px"
                      textColor="white"
                      w="100%"
                      h="40px"
                      background="linear-gradient(25deg, #af0f7d, #58086c)"
                      className={utilStyles.btn}
                      _hover={{
                        background: "linear-gradient(329deg, #00bcd4 12%, rgba(66, 153, 225, 0.6) 100%)",
                        textColor: "white",
                        border: "none",
                        display: "none",
                        fontSize: "20px"
                      }}
                    >
                      Buy
                    </Button>
                  </Link>
                </>
              )}
              <Link color="teal.500" href={"https://app.vsion.io/dashboard/dap/" + nftData?.identifier}>
                <Button
                  borderRadius="12px"
                  textColor="white"
                  w="100%"
                  h="40px"
                  display="none"
                  background="linear-gradient(25deg, #af0f7d, #58086c)"
                  className={utilStyles.btn}
                  _hover={{
                    background: "linear-gradient(329deg, #00bcd4 12%, rgba(66, 153, 225, 0.6) 100%)",
                    textColor: "white",
                    border: "none",
                    fontSize: "20px"
                  }}
                >
                  View
                </Button>
              </Link>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </GridItem> */
  );
};

const MarketPlaceDap = () => {
  return (
    <Box>
      <SimpleGrid minChildWidth="270px" spacing="10px">
        {nftID?.map((item, key) => (
          <NFTView id={item} key={key} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
export default MarketPlaceDap;

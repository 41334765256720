import React, { PropsWithChildren } from "react";

import { Card, CardHeader, CardBody, Divider, Flex, Text } from "@chakra-ui/react";

interface TCardNFT {
  title: string | React.ReactNode
  subTitle?: string
}

export default function CardNFT(props: PropsWithChildren<TCardNFT>) {
  const { title, subTitle, children } = props
  return (
    <Card bgColor={'gray.850'} rounded={'md'}>
      <CardHeader display={'flex'} gap={3} >

        <Flex flexDirection={'column'}>
          <Text fontSize={'xl'}>
            <b >{title}</b>
          </Text>
          <Text fontSize={'sm'}>
            <p className="text-small text-default-500">{subTitle}</p>
          </Text>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody>
        {children}
      </CardBody>
    </Card>
  );
}
import { Select } from "@chakra-ui/react";

const sort = [
  { id: 1, name: "Hot" },
  { id: 2, name: "APR" },
  { id: 3, name: "Earned" },
  { id: 4, name: "Total staked" },
  { id: 5, name: "Latest" }
];

const SortList = () => {
  return (
    <Select placeholder="Select option">
      {sort.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Select>
  );
};

export default SortList;

import React, { PropsWithChildren } from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react';

interface TPropsModalContainer {
  childrenButton: React.ReactNode
  tooltipLabel: React.ReactNode
  titleModal: string
}

const ModalCustom: React.FC<PropsWithChildren<TPropsModalContainer>> = (props) => {
  const { children, childrenButton, titleModal, tooltipLabel } = props
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (<>
    <Tooltip color="primary" label={tooltipLabel}>
      <Button
        onClick={onOpen}
        className="text-2xl cursor-pointer active:opacity-50"
      >
        {childrenButton}
      </Button>
    </Tooltip>
    <Modal isOpen={isOpen} onClose={onClose} colorScheme="gray">
      <ModalOverlay />
      <ModalContent bgColor={'gray.850'}>
        <ModalHeader textColor="white">{titleModal}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal >
  </>
  );
};

export default ModalCustom;
import { Box, Collapse, Grid, Stack, useDisclosure } from "@chakra-ui/react";
import TableHeader from "../Header/Table";
import { PropsWithRef } from "react";

interface TPropsPoolCard {
  typePool: "stake" | "farm";
  components: JSX.Element[];
  extraCollapse?: JSX.Element[];
}

const PoolCard: React.FC<PropsWithRef<TPropsPoolCard>> = (props) => {
  const { typePool, components, extraCollapse } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box rounded="lg" bg="white" mb={3} overflow="hidden" transition="all" _last={{ mb: 0 }} _hover={{ shadow: "lg" }}>
      <Stack onClick={onToggle} cursor="pointer" py={{ base: 4, sm: 0 }}>
        <TableHeader
          templateColumns={{
            base: `repeat(${components.length - 2}, minmax(0,1fr))`,
            lg: `repeat(${components.length}, minmax(0,1fr))`
          }}
          gridColumn={{ base: `span ${typePool === "stake" ? 2 : 2}/span ${typePool === "stake" ? 2 : 1}`, sm: "auto" }}
          headers={components}
        />
      </Stack>
      <Collapse in={isOpen} animateOpacity>
        <Box px={{ base: 4, sm: 8 }} py={{ base: 4, sm: 6 }} borderTop="1px dashed rgb(229 231 235)">
          <Grid
            mb={2}
            gap={{ base: 3, sm: 6 }}
            templateColumns={{
              base: "repeat(1,minmax(0,1fr))",
              sm: "repeat(2,minmax(0,1fr))"
            }}
          >
            <>{extraCollapse && extraCollapse.map((extra) => extra)}</>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};

export default PoolCard;

import { useContext, useState } from 'react';
import { useContractRead } from '@thirdweb-dev/react';
import { Button, Grid, Input, Text, useToast } from '@chakra-ui/react';
import { LotteryContext } from '../contextLottery';
import { numberFinalToArr } from '../../../utils/lottery';

const FormGetTickets = () => {
  const [minId, setMinId] = useState(0)
  const [maxId, setMaxId] = useState(0)
  const [arrId, setArrId] = useState([0])
  const toast = useToast()

  const { lotteryContract } = useContext(LotteryContext)
  const { data: viewNumbersAndStatusesForTicketIds } = useContractRead(
    lotteryContract,
    "viewNumbersAndStatusesForTicketIds",
    [arrId]
  );
  const handleClickViewAndStatus = async () => {
    try {
      let min: number
      let max: number
      if (maxId > minId) {
        min = minId
        max = maxId
      } else {
        min = maxId
        max = minId
      }

      let dif = (max - min + 1)

      const arr = new Array(dif)
      const arrID = arr.fill(0, 0, dif).map((_, idx) => min + idx)

      setArrId(arrID)
    } catch (err: any) {
      toast({
        title: 'Tx Error.',
        description: err?.shortMessage ? err.shortMessage : err?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } finally {
      // setLoading(false)
    }
  }
  return (
    <div>
      <Text mb='8px'>Min ID:</Text>
      <Input type='number' onChange={(ev) => setMinId(parseInt(ev.target.value))} />
      <Text mb='8px'>Max ID:</Text>
      <Input type='number' onChange={(ev) => setMaxId(parseInt(ev.target.value))} />

      <Button onClick={() => handleClickViewAndStatus()} >View</Button>
      <br />
      {Array.isArray(viewNumbersAndStatusesForTicketIds) && (
        viewNumbersAndStatusesForTicketIds[0]
          .map((loteria: any, idx: number) => (<p key={idx}>
            {minId + idx} - <Grid templateColumns={{ base: 'repeat(3, 1fr)', sm: 'repeat(6, 1fr)', }}>
              {numberFinalToArr(loteria)
                .map((num) => (
                  <Grid rounded={'full'} border={'2px solid #000'} w='3rem' h='3rem' justifyContent={'center'} alignItems={'center'}>
                    <p>{num}</p>
                  </Grid>
                ))
              }
            </Grid>
          </p>))
      )}
    </div>
  );
};

export default FormGetTickets;
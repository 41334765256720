import { PropsWithRef, useEffect, useState } from 'react';

const CountDown: React.FC<PropsWithRef<{ time: number }>> = (props) => {
  const { time } = props
  const [countDown, setCountDown] = useState('loading');

  function GetTime() {
    var currentTime = Math.trunc(new Date().getTime() / 1000);
    var futureTime = time;
    var timeRemaining = futureTime - currentTime;
    var minute = 60;
    var hour = 60 * 60;
    var day = 60 * 60 * 24;
    var dayFloor = Math.floor(timeRemaining / day);
    var hourFloor = Math.floor((timeRemaining - dayFloor * day) / hour);
    var minuteFloor = Math.floor(
      (timeRemaining - dayFloor * day - hourFloor * hour) / minute
    );
    var secondFloor = Math.floor(
      timeRemaining - dayFloor * day - hourFloor * hour - minuteFloor * minute
    );

    if (secondFloor <= 0 && minuteFloor <= 0) {
      setCountDown('Completed');
    } else {
      if (futureTime > currentTime) {
        setCountDown(
          (dayFloor > 0 ? dayFloor + ' Days ' : '')
          +
          (hourFloor > 0 ? hourFloor + ' Hours ' : '')
          +
          minuteFloor +
          ' Minutes ' +
          secondFloor +
          ' Seconds '
        );
      }
    }
  }
  useEffect(() => {
    GetTime();
    const interval = setInterval(() => {
      GetTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return (<>
    {countDown}
  </>)
};

export default CountDown;

import { Box, Collapse, Heading, Image, Stack, Text, useDisclosure } from "@chakra-ui/react";

const SyllabusCard = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      rounded="lg"
      border={isOpen ? "2px solid #af117d" : "2px solid #d2d2d2"}
      _hover={{ border: "2px solid #af117d", shadow: "lg" }}
      bg="white"
      mb={3}
      overflow="hidden"
      transition="all"
      _last={{ mb: 0 }}
    >
      <Stack
        flexDirection="row"
        bg="white"
        gap="28px"
        alignItems="center"
        onClick={onToggle}
        cursor="pointer"
        px={3}
        py={4}
      >
        <Box
          rounded="full"
          w={108}
          p={2}
          bgColor={isOpen ? "#aa2b68" : "#f3f3f3"}
          _hover={{ background: "#aa2b68" }}
          textAlign="center"
        >
          <Text
            fontSize="16px"
            color={isOpen ? "white" : "#aa2b68"}
            fontWeight={isOpen ? "bold" : "initial"}
            _hover={{ color: "white" }}
          >
            Week 1
          </Text>
        </Box>
        <Heading fontSize="18px" color={isOpen ? "#aa2b68" : "black"} _hover={{ color: "#aa2b68" }}>
          Lorem ipsum dolor sit amet
        </Heading>
      </Stack>
      <Collapse in={isOpen} animateOpacity>
        <Box px={3} py={4} bg="white">
          <Stack flexDirection="row" gap={4} mb="20px">
            <Box alignSelf="flex-start">
              <Image
                rounded="lg"
                maxWidth="100%"
                height="auto"
                src="https://html.vecurosoft.com/educino/demo/assets/img/course/course-syllabus-1.jpg"
              />
            </Box>
            <Stack gap="10px" flex={1}>
              <Heading as="h5" color="#000" fontSize="16px" position="relative">
                Introduction
              </Heading>
              <Text color="#444444" fontSize="14px" mb="20px">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
                laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem.
              </Text>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={4} mb="20px">
            <Box alignSelf="flex-start">
              <Image
                maxWidth="100%"
                rounded="lg"
                height="auto"
                src="https://html.vecurosoft.com/educino/demo/assets/img/course/course-syllabus-1.jpg"
              />
            </Box>
            <Stack gap="10px" flex={1}>
              <Heading as="h5" color="#000" fontSize="16px" position="relative">
                What do you understand
              </Heading>
              <Text color="#444444" fontSize="14px" mb="20px">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
                laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem.
              </Text>
            </Stack>
          </Stack>
          <Heading as="h5" color="#000" fontSize="16px" mb="10px" position="relative">
            What do you understand
          </Heading>
          <Text color="#444444" mb="20px">
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
            laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem.
          </Text>
          <Heading as="h5" color="#000" fontSize="16px" mb="10px" position="relative">
            What do you understand
          </Heading>
          <Text color="#444444" mb="20px">
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam error voluptatem reiciendis. Assumenda
            laudantium, veritatis hic velit deleniti perferendis in earum suscipit quidem.
          </Text>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SyllabusCard;
